(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ModeleContratDetailController', ModeleContratDetailController);

    ModeleContratDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'ModeleContrat', 'Agence',  'ServiceVendu'];

    function ModeleContratDetailController($scope, $rootScope, $stateParams, entity, ModeleContrat, Agence,  ServiceVendu) {
        var vm = this;
        vm.modeleContrat = entity;
        vm.load = function (id) {
            ModeleContrat.get({id: id}, function(result) {
                vm.modeleContrat = result;
            });
        };
        var unsubscribe = $rootScope.$on('wamasysApp:modeleContratUpdate', function(event, result) {
            vm.modeleContrat = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
