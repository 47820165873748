(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ModelMeterDialogController', ModelMeterDialogController);

    ModelMeterDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'ModelMeter'];

    function ModelMeterDialogController ($scope, $stateParams, $uibModalInstance, entity, ModelMeter) {
        var vm = this;
        vm.modelMeter = entity;
        vm.load = function(id) {
            ModelMeter.get({id : id}, function(result) {
                vm.modelMeter = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:modelMeterUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.modelMeter.id !== null) {
                ModelMeter.update(vm.modelMeter, onSaveSuccess, onSaveError);
            } else {
                ModelMeter.save(vm.modelMeter, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
