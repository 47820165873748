(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ReleveCompteurDetailController', ReleveCompteurDetailController);

    ReleveCompteurDetailController.$inject = ['$scope','$filter', '$rootScope', '$stateParams',  'ReleveCompteur', 'Principal'];

    function ReleveCompteurDetailController($scope,$filter, $rootScope, $stateParams,  ReleveCompteur, Principal) {
        var vm = this;

		vm.erreurReleve =false;
		vm.disableBtnSaveCancel =false;
		vm.disableBtnEdition =false;
        vm.load = function (id) {
            ReleveCompteur.get({id: id}, function(result) {
                vm.releveCompteur = result;
                $rootScope.$broadcast('updateLigneReleveCompteur', result);
            });
        };
        

		var user = {} ;
        Principal.identity().then(function(account) {
        	if(account){
              user =account ;
        	}
        });
     
        if($stateParams.id){
            vm.load($stateParams.id);
        }
        	
        var unsubscribe = $rootScope.$on('wamasysApp:releveCompteurUpdate', function(event, result) {
            vm.releveCompteur = result;
        });
        
        $scope.$on('$destroy', unsubscribe);

        //boite de dialog pour lecture de compteur
    	  
		var $dialog = $(
				'<div class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true" style="padding-top:15%; overflow-y:visible;">' +
				'<div class="modal-dialog modal-m">' +
				'<div class="modal-content">' +
					'<div class="modal-header"><h3 style="margin:0;"></h3></div>' +
					'<div class="modal-body">' +
						'<div class="progress progress-striped active" style="margin-bottom:0;"><div class="progress-bar" style="width: 100%"></div></div>' +
					'</div>' +
				'</div></div></div>');

		var options = {};
		var message = 'Please wait ! Action in progress.';
		var settings = $.extend({
			dialogSize: 'm',
			progressType: '',
			onHide: null // This callback runs after the dialog was hidden
		}, options);
		$dialog.find('.modal-dialog').attr('class', 'modal-dialog').addClass('modal-' + settings.dialogSize);
		$dialog.find('.progress-bar').attr('class', 'progress-bar');
		$dialog.find('h3').text(message);
		

        vm.valider= function() {

        	if(! checkerDecimalAquatech()){
				return ; 
			}


			$dialog.modal();
			
        	ReleveCompteur.valider({id:vm.releveCompteur.id},  function(result) {
	            $scope.$emit('wamasysApp:releveCompteurUpdate', result);
	            vm.isSaving = false;
				$dialog.modal('hide');
			},function(error) {
				$dialog.modal('hide');
			}
        	);
        }

        vm.startEdit = function() {
			
			vm.disableBtnEdition =true;
			vm.disableBtnSaveCancel =false;
			jQuery("#btnSaveRows,#btnCancelRows").show();
		
			var grid = $("table[id^=jqgrid-table]");
			var ids = grid.jqGrid('getDataIDs');

			for (var i = 0; i < ids.length; i++) {
				grid.jqGrid('editRow', ids[i]);
			}
			
		};
		
		function hasDecimals(number) {
		    return number % 1 !== 0;
		}
		
		function checkerDecimalAquatech(){
			var erreur = false;//fixme mettre false
			var messageErreur = '';
			
		        
			if(! user.organisationName.includes('Aquatech') && !  user.organisationName.includes('aquatech')
					&& user.organisationId !== 26529364 && user.organisationId !== 25683601){
				return true;
			}
			
			if (vm.releveCompteur && vm.releveCompteur.clusterReleveCompteurDTO) {
				for (var i = 0; i < vm.releveCompteur.clusterReleveCompteurDTO.length; i++) {
					if (vm.releveCompteur.clusterReleveCompteurDTO[i].ligneReleveCompteur) {
						for (var x = 0, lenLigne =  vm.releveCompteur.clusterReleveCompteurDTO[i].ligneReleveCompteur.length; x < lenLigne; x++)  {
							if(	vm.releveCompteur.clusterReleveCompteurDTO[i].ligneReleveCompteur[x].valeurReleve && hasDecimals(	vm.releveCompteur.clusterReleveCompteurDTO[i].ligneReleveCompteur[x].valeurReleve)){
								erreur = true;
								messageErreur = messageErreur+ 'Erreur relevé avec décimale sur '+	vm.releveCompteur.clusterReleveCompteurDTO[i].ligneReleveCompteur[x].individualMeter.numeroSerie +'\n';
							}
						}
					}
				}
			}
			
			
			if (vm.releveCompteur && vm.releveCompteur.listLigneReleveCompteurBulkMeter) {
				for (var i = 0; i < vm.releveCompteur.listLigneReleveCompteurBulkMeter.length; i++) {
					if (vm.releveCompteur.listLigneReleveCompteurBulkMeter[i].bulkMeter) {
						if(	vm.releveCompteur.listLigneReleveCompteurBulkMeter[i].valeurReleve && hasDecimals(	vm.releveCompteur.listLigneReleveCompteurBulkMeter[i].valeurReleve)){
							erreur = true;
							messageErreur = messageErreur+ 'Erreur relevé avec décimale sur '+	vm.releveCompteur.listLigneReleveCompteurBulkMeter[i].bulkMeter.numeroSerie +'\n';
						}
					}
				}
			}
			
			if(erreur){
				alert('Vous avez des erreurs bloquantes pour la validation du relevé de compteur : \n'+messageErreur);
				return false;
			}
			
			return true;
		}
		
		
		vm.print = function() {
			 // var printContents = document.getElementById('full-screen-widget').innerHTML;
			  
			  var content = '<strong>'+ $filter('translate')('wamasysApp.releveCompteur.titreImprimerDetail')+' '+ $filter('date')(vm.releveCompteur.dateReleve, "mediumDate") 
			  +'</strong> '+ $filter('translate')('wamasysApp.releveCompteur.collecteur')+' :  '+vm.releveCompteur.collecteur.firstName + ' ' + vm.releveCompteur.collecteur.lastName+'<br>';
			


			              
				content += '<table style=" ">';
					content += '<tr><td>'+$filter('translate')('wamasysApp.individualMeter.type')+'</td><td>'+ $filter('translate')('wamasysApp.individualMeter.nomContrat')+'</td><td>'+$filter('translate')('wamasysApp.individualMeter.cluster')+'</td><td>'+ $filter('translate')('wamasysApp.individualMeter.runningOrder')
					+'</td><td>'+ $filter('translate')('wamasysApp.individualMeter.numeroSerie')+'</td><td>'+ $filter('translate')('wamasysApp.individualMeter.datePrecedenteLecture')+'</td><td>'+ $filter('translate')('wamasysApp.individualMeter.valeurPrecedenteLecture')+'</td>'
						+'<td style="width:60px;"></td><td style="width:60px;"></td><td style="width:60px;"></td><td style="width:60px;"></td><td style="width:60px;"></td></tr>';

				if (vm.releveCompteur && vm.releveCompteur.clusterReleveCompteurDTO) {
					for (var i = 0; i < vm.releveCompteur.clusterReleveCompteurDTO.length; i++) {

						if (vm.releveCompteur.clusterReleveCompteurDTO[i].ligneReleveCompteur) {
		
							for (var x = 0, lenLigne =  vm.releveCompteur.clusterReleveCompteurDTO[i].ligneReleveCompteur.length; x < lenLigne; x++)  {
		
							
		
							content += '<tr><td>'+ $filter('translate')('wamasysApp.releveCompteur.typeIndividualMeter')+'</td>'
								+'<td >'+vm.releveCompteur.clusterReleveCompteurDTO[i].ligneReleveCompteur[x].nomContrat+'</td><td>'+vm.releveCompteur.clusterReleveCompteurDTO[i].cluster.numeroSerie
								+'</td><td align="center">'+vm.releveCompteur.clusterReleveCompteurDTO[i].ligneReleveCompteur[x].individualMeter.runningOrder
							
								+'</td><td>'+ vm.releveCompteur.clusterReleveCompteurDTO[i].ligneReleveCompteur[x].individualMeter.numeroSerie+'</td><td>'
								+$filter('date')(vm.releveCompteur.clusterReleveCompteurDTO[i].ligneReleveCompteur[x].datePrecedentReleveCompteur , "medium")+'</td>'
								+'<td>' + vm.releveCompteur.clusterReleveCompteurDTO[i].ligneReleveCompteur[x].valeurPrecedentReleve+'</td>'
								+'<td> </td><td> </td><td> </td><td> </td><td> </td></tr>';
							
							}
						}
					}
				}
				
				
				if (vm.releveCompteur && vm.releveCompteur.listLigneReleveCompteurBulkMeter) {
					for (var i = 0; i < vm.releveCompteur.listLigneReleveCompteurBulkMeter.length; i++) {
						if (vm.releveCompteur.listLigneReleveCompteurBulkMeter[i].bulkMeter) {

							content += '<tr><td>'+ $filter('translate')('wamasysApp.releveCompteur.typeBulkMeter')+'</td>'
								+'<td colspan="4">'+ vm.releveCompteur.listLigneReleveCompteurBulkMeter[i].bulkMeter.numeroSerie+'</td><td>'
								+$filter('date')(vm.releveCompteur.listLigneReleveCompteurBulkMeter[i].datePrecedentReleveCompteur, "medium")+'</td><td>'+ vm.releveCompteur.listLigneReleveCompteurBulkMeter[i].valeurPrecedentReleve+'</td>'
								+'<td> </td><td> </td><td> </td><td> </td><td> </td></tr>';
							
						}
					}
				}
				

				
				content += "</table>";
				var printContents= content;
			  
			  
			  var popupWin = window.open('', '_blank');
			  popupWin.document.open();
			  
			
			 

			  popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="style.css" />'
					  +'<style type="text/css" media="print">@page { size: landscape; }    </style>'
					  +'<style type="text/css" > table {border-spacing: 0.5rem;border-collapse: collapse;} table, table td, table th { border: 1px solid black; }   </style>'
					  +'</head><body onload="window.print()">' + printContents + '</body></html>');
			  popupWin.document.close();
		} 
    
       var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:releveCompteurUpdate', result);
            vm.isSaving = false;
			$dialog.modal('hide');
        };

        var onSaveError = function () {
            vm.isSaving = false;
			$dialog.modal('hide');
        };
	        
	        
	        
	        
		vm.saveRows = function(forceSave) {
			//On désactive les boutons.
			vm.disableBtnSaveCancel =true;
			

			var grid = $("table[id^=jqgrid-table]");

			var ids = grid.jqGrid('getDataIDs');

			vm.erreurReleve =false;
			
	
			/** On test que les valeurs sont bien renseignées*/
			var erreur = false;
			for (var i = 0; i < ids.length; i++) {
                $('#' + $.jgrid.jqID(ids[i])).css( { background:'none'});
                
				var success =  grid.jqGrid('saveRow', ids[i]); //Permet de savoir si l'enregistrement de la ligne est bon. Si on a un carctères ça retourne false
				
				var maxDepasse = false;
				if( success && ( ! forceSave)){ //On ne fait pas le test si l'user clique sur ForceSave
					var nvReleveStr = grid.jqGrid('getRowData', ids[i]).nouveauReleve;
					if(nvReleveStr !== "" ){
						var nvReleve = parseFloat(nvReleveStr);
						var relevePrecedent = parseFloat(grid.jqGrid('getRowData', ids[i]).relevePrecedent);
						var maxAccepte = parseFloat(grid.jqGrid('getRowData', ids[i]).valeurMaximumAccepte);
						if(relevePrecedent!=NaN 
								&& (
										nvReleve == NaN
										|| maxAccepte == NaN
										|| nvReleve > maxAccepte
										|| nvReleve < relevePrecedent
								)){
							maxDepasse = true;
						}	
					}

				}
				
				erreur = erreur ||(! success) || maxDepasse;

				if(maxDepasse ){
	                $('#' + $.jgrid.jqID(ids[i])).css( { background:'orange'});           
				}
				if(! success ){
	                $('#' + $.jgrid.jqID(ids[i])).css( { background:'red'});           
				}
				
			}

            
			var grid = $("table[id^=jqgrid-table]");
			var dataInGrid = grid.jqGrid('getRowData') ;
		
			for (var i = 0; i < dataInGrid.length; i++) { 
 
              	var id = dataInGrid[i].id ;
              		
          		var listTrouveBulkMeter =  jQuery.grep(vm.releveCompteur.listLigneReleveCompteurBulkMeter, function(e){ return e.idLigneReleveCompteur == id; });
          			
          		if(listTrouveBulkMeter && listTrouveBulkMeter.length > 0 ){
	            	listTrouveBulkMeter[0].valeurReleve = dataInGrid[i].nouveauReleve;

              		if(dataInGrid[i].casNonReleve){
              			listTrouveBulkMeter[0].casNonReleve = {id:dataInGrid[i].idCasNonReleve, libelle:dataInGrid[i].casNonReleve};
              		}else{
              			listTrouveBulkMeter[0].casNonReleve = null;
              		}
	            }
         

              	var ligneIndividual =  chercherLigneReleveCompteur(vm.releveCompteur.clusterReleveCompteurDTO,id);
              		
              	if(ligneIndividual){
              		ligneIndividual.valeurReleve = dataInGrid[i].nouveauReleve;
              		if(dataInGrid[i].casNonReleve){
              			ligneIndividual.casNonReleve = {id:dataInGrid[i].idCasNonReleve, libelle:dataInGrid[i].casNonReleve};
              		}else{
              			ligneIndividual.casNonReleve = null;
              		}
	            }
              		
            }

            $rootScope.$broadcast('updateLigneReleveCompteur', vm.releveCompteur);
			  
			if(erreur){
				vm.erreurReleve =true;
			//	alert( $filter('translate')('wamasysApp.releveCompteur.erreurSaisie') );
				for (var i = 0; i < ids.length; i++) {
					grid.jqGrid('editRow', ids[i]);
				}
				/** On affiche le bouton de sauvegarde forcée*/
				jQuery("#btnSaveRows,#btnCancelRows,#btnSaveRowsForce").show();
				vm.disableBtnSaveCancel =false;
				return ;
			}
			
			
	
			//Ensuite si pas d'erreur on enregistre en appelant le WebService.
			

		


			$dialog.modal();
            ReleveCompteur.update(vm.releveCompteur, onSaveSuccess, onSaveError);
			jQuery("#btnSaveRows,#btnCancelRows,#btnSaveRowsForce").hide();
			vm.disableBtnSaveCancel =true;
			
			vm.disableBtnEdition =false;

		}
		
		var chercherLigneReleveCompteur = function(listeCluster,idToLookFor) {
			
			for (var i = 0, len = listeCluster.length; i < len; i++)  {
				
				for (var x = 0, lenLigne = listeCluster[i].ligneReleveCompteur.length; x < lenLigne; x++)  {

				    if (listeCluster[i].ligneReleveCompteur[x].idLigneReleveCompteur == idToLookFor) {
				        return listeCluster[i].ligneReleveCompteur[x]; // Return as soon as the object is found
				    }
				}
			}
		}

		vm.cancelEditRows = function() {

			jQuery("#btnSaveRows,#btnCancelRows,#btnSaveRowsForce").hide();

			var grid = $("table[id^=jqgrid-table]");

			var ids = grid.jqGrid('getDataIDs');

			for (var i = 0; i < ids.length; i++) {
				grid.jqGrid('restoreRow', ids[i]);
			}
			vm.disableBtnEdition =false;

		}
        
 
        
    }
})();
