(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ContratController', ContratController);

    ContratController.$inject = ['$q','$scope', '$state', '$filter', 'Contrat', 'ParseLinks','$timeout', 'AlertService', 'pagingParams', 'paginationConstants','PortefeuilleContrat','ContratSearch','ContractType','ContractProfil','ServiceVendu'];

    function ContratController ($q,$scope, $state, $filter, Contrat, ParseLinks,$timeout, AlertService, pagingParams, paginationConstants,PortefeuilleContrat,ContratSearch,ContractType,ContractProfil,ServiceVendu) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.etat = $state.params.etat;

        vm.portefeuilleContrats = PortefeuilleContrat.query({ offset: 0,size: 1000,sort: 'libelle,asc'});        
        vm.contractTypes = ContractType.query({ offset: 0,size: 1000,sort: 'label,asc'});
        vm.contractProfils = ContractProfil.query({ offset: 0,size: 1000,sort: 'libelle,asc'});
      //  vm.serviceVendus = ServiceVendu.query({ offset: 0,size: 1000,sort: 'libelle,asc'});
        vm.serviceVendus = ServiceVendu.queryInclDeleted({ offset: 0,size: 1000,sort: 'libelle,asc'});


        if($state.params.portefeuilleContrat){
//        	vm.portefeuilleContrat = {
//        			id:$state.params.portefeuilleContrat
//        	}
        	vm.portefeuilleContrat = [];
        	$state.params.portefeuilleContrat.split(",").map(
    			function(elem){ 
    				vm.portefeuilleContrat.push({	id:elem});
    		});
        			
	    	if(vm.portefeuilleContrats && vm.portefeuilleContrats.$promise){
	    		vm.portefeuilleContrats.$promise.then(function(value) {
        	    	vm.portefeuilleContratNotPromise = [];
		        	$state.params.portefeuilleContrat.split(",").map(
		        			function (item) { 
		        				vm.portefeuilleContratNotPromise.push(
			    						value.find(function(obj) {
			    							return obj.id == item
			    						})
			    				);
			    			}
		        		);
			    	});	
		          }
        }

        if($state.params.contractType){
        	vm.contractType = [];
        	$state.params.contractType.split(",").map(
    			function(elem){ 
    				vm.contractType.push({	id:elem});
    		});
        			
	    	if(vm.contractTypes && vm.contractTypes.$promise){
	    		vm.contractTypes.$promise.then(function(value) {
        	    	vm.contractTypeNotPromise = [];
		        	$state.params.contractType.split(",").map(
		        			function (item) { 
		        				vm.contractTypeNotPromise.push(
			    						value.find(function(obj) {
			    							return obj.id == item
			    						})
			    				);
			    			}
		        		);
			    	});	
		          }
        }
        if($state.params.contractProfil){
        	vm.contractProfil = [];
        	$state.params.contractProfil.split(",").map(
    			function(elem){ 
    				vm.contractProfil.push({	id:elem});
    		});
        			
	    	if(vm.contractProfils && vm.contractProfils.$promise){
	    		vm.contractProfils.$promise.then(function(value) {
        	    	vm.contractProfilNotPromise = [];
		        	$state.params.contractProfil.split(",").map(
		        			function (item) { 
		        				vm.contractProfilNotPromise.push(
			    						value.find(function(obj) {
			    							return obj.id == item
			    						})
			    				);
			    			}
		        		);
			    	});	
		          }        	
        }
        if($state.params.serviceVendu){
        	vm.serviceVendu = [];
        	$state.params.serviceVendu.split(",").map(
    			function(elem){ 
    				vm.serviceVendu.push({	id:elem});
    		});
        			
	    	if(vm.serviceVendus && vm.serviceVendus.$promise){
	    		vm.serviceVendus.$promise.then(function(value) {
        	    	vm.serviceVenduNotPromise = [];
		        	$state.params.serviceVendu.split(",").map(
		        			function (item) { 
		        				vm.serviceVenduNotPromise.push(
			    						value.find(function(obj) {
			    							return obj.id == item
			    						})
			    				);
			    			}
		        		);
			    	});	
		          }

        		
        	
        }


        vm.onlinePaymentType = 	$state.params.onlinePaymentType;
        vm.onlinePaymentQuery = $state.params.onlinePaymentQuery;
        vm.onlinePaymentActive =$state.params.onlinePaymentActive;
		
        vm.searchQuery = $state.params.searchQuery;
        vm.compteurFerme = $state.params.compteurFerme;
    	 vm.date = {
                 startDate: $state.params.startDate?new Date($state.params.startDate):null,
                 endDate: $state.params.endDate?new Date($state.params.endDate):null

         }
		
        vm.loadAll();
        
    	vm.download = function(){
    		Contrat.downloadcsv( {
        		nom: vm.searchQuery,
        		compteurFerme: vm.compteurFerme,
        		portefeuilleContrat:vm.portefeuilleContrat?vm.portefeuilleContrat.map(function(elem){ return elem.id;}).join(","):null,	
                //contractType:vm.contractType?vm.contractType.id:null,
        		contractType:vm.contractType?vm.contractType.map(function(elem){ return elem.id;}).join(","):null,		
                contractProfil:vm.contractProfil?vm.contractProfil.map(function(elem){ return elem.id;}).join(","):null,	
                serviceVendu:vm.serviceVendu?vm.serviceVendu.map(function(elem){ return elem.id;}).join(","):null,
        		etat:vm.etat,
        		fromDate:$filter('date')(vm.date.startDate, "yyyy-MM-dd"),
        		toDate:$filter('date')(vm.date.endDate, "yyyy-MM-dd"),
        		onlinePaymentType:vm.onlinePaymentType,
        		onlinePaymentQuery:vm.onlinePaymentQuery,
        		onlinePaymentActive:vm.onlinePaymentActive}
    			,function (content){
    		      var url = URL.createObjectURL(content.response);
    		      var a = document.createElement('a');
    		      a.href = url;
    		      a.download = content.fileName;
    		      a.target = '_blank';
    		      a.click();
    		}
    				
    		);
    	}

       	vm.downloadService = function(){
    		Contrat.downloadServicecsv( {}
    			,function (content){
    		      var url = URL.createObjectURL(content.response);
    		      var a = document.createElement('a');
    		      a.href = url;
    		      a.download = content.fileName;
    		      a.target = '_blank';
    		      a.click();
    		}
    				
    		);
    	}

       	vm.downloadDetailServiceNotClosedcsv = function(){
    		Contrat.downloadDetailServiceNotClosedcsv( {}
    			,function (content){
    		      var url = URL.createObjectURL(content.response);
    		      var a = document.createElement('a');
    		      a.href = url;
    		      a.download = content.fileName;
    		      a.target = '_blank';
    		      a.click();
    		}
    				
    		);
    	}
    
       	

    
        
        function loadAll () {
            if (vm.searchQuery || vm.portefeuilleContrat  || vm.contractType    || vm.contractProfil  ||  vm.serviceVendu || vm.etat || vm.date.startDate || vm.date.endDate 
            		|| vm.compteurFerme	|| vm.onlinePaymentType	|| vm.onlinePaymentQuery	|| vm.onlinePaymentActive) {
            	
            	
                vm.currentSearch=true;
            	ContratSearch.query({
            		nom: vm.searchQuery,
            		compteurFerme: vm.compteurFerme,
            		portefeuilleContrat:vm.portefeuilleContrat?vm.portefeuilleContrat.map(function(elem){ return elem.id;}).join(","):null,
            //		contractType:vm.contractType?vm.contractType.id:null,
            		contractType:vm.contractType?vm.contractType.map(function(elem){ return elem.id;}).join(","):null,
            		contractProfil:vm.contractProfil?vm.contractProfil.map(function(elem){ return elem.id;}).join(","):null,
                    serviceVendu:vm.serviceVendu?vm.serviceVendu.map(function(elem){ return elem.id;}).join(","):null,
            		etat:vm.etat,
            		fromDate:$filter('date')(vm.date.startDate, "yyyy-MM-dd"),
            		toDate:$filter('date')(vm.date.endDate, "yyyy-MM-dd"),
            		onlinePaymentType:vm.onlinePaymentType,
            		onlinePaymentQuery:vm.onlinePaymentQuery,
            		onlinePaymentActive:vm.onlinePaymentActive,
                    page: pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                vm.currentSearch=false;
	            Contrat.query({
	                page: pagingParams.page - 1,
	                size: paginationConstants.itemsPerPage,
	                sort: sort()
	            }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
              
//                if (vm.predicate === 'id') {
//                    result.push('id,desc');
//                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.contrats = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                searchQuery: vm.searchQuery,
        		compteurFerme: vm.compteurFerme,
                startDate: vm.date.startDate,
                endDate: vm.date.endDate,
        		//contractType:vm.contractType?vm.contractType.id:null,
                contractType:vm.contractTypeNotPromise?vm.contractTypeNotPromise.map(function(elem){ return elem.id;}).join(","):null,
        		contractProfil:vm.contractProfilNotPromise?vm.contractProfilNotPromise.map(function(elem){ return elem.id;}).join(","):null,
        		serviceVendu:vm.serviceVenduNotPromise?vm.serviceVenduNotPromise.map(function(elem){ return elem.id;}).join(","):null,
                portefeuilleContrat: vm.portefeuilleContratNotPromise?vm.portefeuilleContratNotPromise.map(function(elem){ return elem.id;}).join(","):null,
                etat: vm.etat,
                currentSearch : vm.currentSearch,
        		onlinePaymentType:vm.onlinePaymentType,
        		onlinePaymentQuery:vm.onlinePaymentQuery,
        		onlinePaymentActive:vm.onlinePaymentActive,
            });
        }
        
        

        function search () {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '';
            vm.reverse = false;
            vm.currentSearch=true;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true; 
            vm.searchQuery= null;
    		vm.compteurFerme = null;
            vm.date.startDate= null;
            vm.date.endDate= null;
            vm.portefeuilleContrat= null;
            vm.portefeuilleContratNotPromise= null;
            vm.contractType= null;
            vm.contractTypeNotPromise= null;
            vm.contractProfil= null;
            vm.contractProfilNotPromise= null;
            vm.serviceVendu= null;
            vm.serviceVenduNotPromise =null;
     		vm.onlinePaymentType =null;
    		vm.onlinePaymentQuery= null;
    		vm.onlinePaymentActive= null;
             vm.etat= null;
            currentSearch : vm.currentSearch= null;
            vm.transition();
        }
    }
})();
