(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .factory('CollectionListeSearch', CollectionListeSearch);

    CollectionListeSearch.$inject = ['$resource'];

    function CollectionListeSearch($resource) {
        var resourceUrl =  'api/collection-listes/search/';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
