(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('WaterNetworkDeleteController',WaterNetworkDeleteController);

    WaterNetworkDeleteController.$inject = ['$uibModalInstance', 'entity', 'WaterNetwork'];

    function WaterNetworkDeleteController($uibModalInstance, entity, WaterNetwork) {
        var vm = this;
        vm.waterNetwork = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            WaterNetwork.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
