(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('PaiementInitialDialogController', PaiementInitialDialogController);

    PaiementInitialDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity',  'LigneContrat', 'UserSearch'];

    function PaiementInitialDialogController ($scope, $stateParams, $uibModalInstance, entity, LigneContrat, UserSearch) {
        var vm = this;
        vm.ligneContrat = entity;
        vm.users = UserSearch.searchCollecteur();
        
     /*   vm.load = function(id) {
           LigneContrat.get({id : id}, function(result) {
                vm.paiementInitial.ligneContrat = result;
            });
        };
        
        vm.load($stateParams.idLigneContrat);*/
        
        
        
        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:paiementInitialUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            
            LigneContrat.updatePaiementInitial(vm.ligneContrat, onSaveSuccess, onSaveError);
        
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.datePaiementInitial = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
