(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('ReportingTaxe', ReportingTaxe);

    ReportingTaxe.$inject = ['$resource'];

    function ReportingTaxe ($resource) {
        var resourceUrl =  'api/reporting-taxe/search';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
        
    }
})();
