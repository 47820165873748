(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('TransferBalanceDialogController', TransferBalanceDialogController);

    TransferBalanceDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Billing',  'LigneContrat'];

    function TransferBalanceDialogController ($scope, $stateParams, $uibModalInstance, entity, Billing,  LigneContrat) {
        var vm = this;
        vm.balanceTransfer  = entity;
        vm.lignecontrats = LigneContrat.findActifByContrat({idContrat:$stateParams.id});
                
        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:impactPenalitRemiseRegulUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            Billing.saveBalanceTransfer(vm.balanceTransfer , onSaveSuccess, onSaveError);
            
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
