(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ImportationController', ImportationController);

    ImportationController.$inject = ['$scope', '$state', '$filter', 'ParseLinks', 'AlertService', 'Importation'];

    function ImportationController ($scope, $state, $filter,  ParseLinks, AlertService, Importation) {
        var vm = this;
     
        
        

        vm.importBalanceTransfer = function(){
        	vm.businessAlert = null;
            var file = $scope.fileToImportBalanceTransfer;
                       
            var fd = new FormData();
            fd.append('file', file);
            Importation.importBalanceTransfer({},fd, onSaveSuccess, onSaveError);
            
         };
         

         vm.importCreditNote = function(){
         	vm.businessAlert = null;
             var file = $scope.fileToImportCreditNote;
                        
             var fd = new FormData();
             fd.append('file', file);
             Importation.importCreditNote({},fd, onSaveSuccess, onSaveError);
             
          };
         
         
        vm.uploadFileUpdateContract = function(){
        	vm.businessAlert = null;
            var file = $scope.fileToUploadGPSCoordonnees;
                       
            var fd = new FormData();
            fd.append('file', file);
            Importation.uploadFileUpdateContract({},fd, onSaveSuccess, onSaveError);
            
//           angular.copy($scope.fileToUploadGPSCoordonnees );
//            = null;
         };
         
        vm.uploadFile = function(){
        	vm.businessAlert = null;
            var file = $scope.fileToUpload;
                        
            var fd = new FormData();
            fd.append('file', file);
            Importation.uploadFile({},fd, onSaveSuccess, onSaveError);
            
         };
         
         var onSaveSuccess = function (result) {
             $scope.$emit('wamasysApp:importUpdate', result);
         };

         var onSaveError = function (httpResponse) {
             var errorHeader = httpResponse.headers('X-wamasysApp-error');
//             var entityKey = httpResponse.headers('X-wamasysApp-params');
             if(errorHeader){
            	 if(errorHeader.search("error.business.")>-1){
                     vm.businessAlert =  $filter('translate')(errorHeader);
            	 }else{
                     vm.businessAlert = errorHeader.replace("error.", "");
            	 }
             }
             
             angular.copy( $scope.fileToUploadGPSCoordonnees);
             angular.element("input[type='file']").val(null);
             
             vm.isSaving = false;
         };
         
        /* 

         var fichier = document.getElementById('fichier').files[0];
         var lecture = new FileReader();
         lecture.onloadend = function(evenement){
	         var donnees = evenement.target.result;
	         //Traitez ici vos données binaires. Vous pouvez par exemple les envoyer à un autre niveau du framework avec $http ou $ressource
	       }
         lecture.readAsBinaryString(fichier);
         }
*/
    }
})();
