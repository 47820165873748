(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('CollectionListeController', CollectionListeController);

    CollectionListeController.$inject = ['$scope', '$state', '$filter', 'CollectionListeSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'Collecteur'];

    function CollectionListeController ($scope, $state, $filter, CollectionListeSearch, ParseLinks, AlertService, pagingParams, paginationConstants, Collecteur ) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.search = search;
        vm.clear = clear;
        vm.collecteurs = Collecteur.query({sort: 'login,asc'});
        if($state.params.etat){
        	vm.etat = $state.params.etat;
        }else{
        //	vm.etat = "VALIDE";
        }
        if($state.params.collecteur){
        	vm.collecteur = {
        			id:$state.params.collecteur
        	}
        }
	   	vm.date = {
	            startDate: $state.params.startDate?new Date($state.params.startDate):null,
	            endDate: $state.params.endDate?new Date($state.params.endDate):null
	    }
        vm.loadAll();


        function loadAll () {
        	CollectionListeSearch.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                etat:vm.etat,
                collecteur:$state.params.collecteur,
        		fromDate:$filter('date')(vm.date.startDate, "yyyy-MM-dd"),
        		toDate:$filter('date')(vm.date.endDate, "yyyy-MM-dd"),
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.collectionListes = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        
        function search () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = '';
            vm.reverse = false;
            vm.transition();
        }


        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }
        
        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.transition();
        }


        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                etat:vm.etat,
                collecteur:vm.collecteur?vm.collecteur.id:null,
                startDate: vm.date.startDate,
                endDate: vm.date.endDate
            });
        }

    }
})();
