(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ReportingAccountingController', ReportingAccountingController);

    ReportingAccountingController.$inject = ['$scope', '$state', '$filter', 'ParseLinks', 'AlertService', 'ReportingAccounting','pagingParams','paginationConstants'];

    function ReportingAccountingController ($scope, $state, $filter,  ParseLinks, AlertService, ReportingAccounting,pagingParams,paginationConstants) {

    	  var vm = this;
          vm.loadAll = loadAll;
          vm.loadPage = loadPage;
          vm.predicate = pagingParams.predicate;
          vm.reverse = pagingParams.ascending;
          vm.transition = transition;
          vm.loadAll();

          function loadAll () {
              ReportingAccounting.query({
                  page: pagingParams.page - 1,
                  size: paginationConstants.itemsPerPage,
                  sort: sort()
              }, onSuccess, onError);
              function sort() {
                  var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                  if (vm.predicate !== 'id') {
                      result.push('id, asc');
                  }
                  return result;
              }
              function onSuccess(data, headers) {
                  vm.links = ParseLinks.parse(headers('link'));
                  vm.totalItems = headers('X-Total-Count');
                  vm.queryCount = vm.totalItems;
                  vm.reportingAccountings = data;
                  vm.page = pagingParams.page;
              }
              function onError(error) {
                  AlertService.error(error.data.message);
              }
          }

          function loadPage (page) {
              vm.page = page;
              vm.transition();
          }

          function transition () {
              $state.transitionTo($state.$current, {
                  page: vm.page,
                  sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                  search: vm.currentSearch
              });
          }
          
          
          $scope.downloadAccounting = function(idAgence,accountingName) {
        	  ReportingAccounting.download({
        		 idAgence : idAgence,
        		 accountingName: accountingName
  			}, function(result) {
  		          
  	                var file = result.response;

  	               var url = window.URL || window.webkitURL;

                    var downloadLink = angular.element('<a></a>');
                    downloadLink.attr('href', url.createObjectURL(file));
                    downloadLink.attr('target','_blank');
                    downloadLink.attr('download', accountingName);
                    downloadLink[0].click();
                    downloadLink.remove();
	                    
//  	                var isChrome = !!window.chrome && !!window.chrome.webstore;
//  	                var isIE = /*@cc_on!@*/false || !!document.documentMode;
//  	                var isEdge = !isIE && !!window.StyleMedia; 
//  	                if (isChrome){
//  	                    var url = window.URL || window.webkitURL;
//
//  	                    var downloadLink = angular.element('<a></a>');
//  	                    downloadLink.attr('href', url.createObjectURL(file));
//  	                    downloadLink.attr('target','_blank');
//  	                    downloadLink.attr('download', accountingName);
//  	                    downloadLink[0].click();
//  	                    downloadLink.remove();
//  	                }
//  	                else if(isEdge || isIE){
//  	                    window.navigator.msSaveOrOpenBlob(file,'file.csv');
//  	                }
//  	                else {
//  	                    var fileURL = URL.createObjectURL(file);
//  	                    window.open(fileURL);
//  	              
//  	                }

  			});
  			
  		}

    }
})();
