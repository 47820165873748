(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('TypeAvoirDialogController', TypeAvoirDialogController);

    TypeAvoirDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'TypeAvoir'];

    function TypeAvoirDialogController ($scope, $stateParams, $uibModalInstance, entity, TypeAvoir) {
        var vm = this;
        vm.typeAvoir = entity;
        vm.load = function(id) {
            TypeAvoir.get({id : id}, function(result) {
                vm.typeAvoir = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:typeAvoirUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.typeAvoir.id !== null) {
                TypeAvoir.update(vm.typeAvoir, onSaveSuccess, onSaveError);
            } else {
                TypeAvoir.save(vm.typeAvoir, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
