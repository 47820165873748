(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('WaterNetworkDialogController', WaterNetworkDialogController);

    WaterNetworkDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'WaterNetwork'];

    function WaterNetworkDialogController ($scope, $stateParams, $uibModalInstance, entity, WaterNetwork) {
        var vm = this;
        vm.waterNetwork = entity;
        vm.load = function(id) {
            WaterNetwork.get({id : id}, function(result) {
                vm.waterNetwork = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:waterNetworkUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.waterNetwork.id !== null) {
                WaterNetwork.update(vm.waterNetwork, onSaveSuccess, onSaveError);
            } else {
                WaterNetwork.save(vm.waterNetwork, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
