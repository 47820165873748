(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
       /* .state('bulk-meter', {
            parent: 'entity',
            url: '/bulk-meter?page&sort&search',
            data: {
                authenticate: true,
                pageTitle: 'wamasysApp.bulkMeter.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/business/bulk-meter/bulk-meters.html',
                    controller: 'BulkMeterController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('bulkMeter');
                    $translatePartialLoader.addPart('etatMeter');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })*/
        .state('bulk-meter-detail', {
            parent: 'entity',
            url: '/bulk-meter/{id}?page&sort&consoPage',
            data: {
                authenticate: true,
                pageTitle: 'wamasysApp.bulkMeter.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/business/bulk-meter/bulk-meter-detail.html',
                    controller: 'BulkMeterDetailController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                consoPage: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        consoPage: PaginationUtil.parsePage($stateParams.consoPage),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('bulkMeter');
                    $translatePartialLoader.addPart('etatMeter');
                    $translatePartialLoader.addPart('collectionListe');
                    $translatePartialLoader.addPart('etatCollectionList');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'BulkMeter', function($stateParams, BulkMeter) {
                    return BulkMeter.get({id : $stateParams.id});
                }]
            }
        })
        .state('compteur.bulk-meter.new', {
            url: '/new',
            data: {
                authorities: ['ROLE_AREA_MANAGEMENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/bulk-meter/bulk-meter-dialog.html',
                    controller: 'BulkMeterDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                numeroSerie: null,
                                dateCreation: null,
                                valeurInitiale: null,
                                etat: null,
                                dateSuppression: null,
                                longitude: null,
                                latitude: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('compteur.bulk-meter', null, { reload: true });
                }, function() {
                    $state.go('compteur.bulk-meter');
                });
            }]
        })
        .state('compteur.bulk-meter.edit', {
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_AREA_MANAGEMENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/bulk-meter/bulk-meter-dialog.html',
                    controller: 'BulkMeterDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['BulkMeter', function(BulkMeter) {
                            return BulkMeter.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('compteur.bulk-meter', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('compteur.bulk-meter.delete', {
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_AREA_MANAGEMENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/bulk-meter/bulk-meter-delete-dialog.html',
                    controller: 'BulkMeterDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['BulkMeter', function(BulkMeter) {
                            return BulkMeter.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('compteur.bulk-meter', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('bulk-meter-detail.addcorrection', {
            url: '/addcorrection',
            data: {
                authorities: ['ROLE_AREA_MANAGEMENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/bulk-meter/bulk-meter-addcorrection-dialog.html',
                    controller: 'BulkMeterAddCorrectionController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['BulkMeter', function(BulkMeter) {
                            return BulkMeter.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('bulk-meter-detail', {id : $stateParams.id}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('bulk-meter-detail.addreading', {
            url: '/addreading',
            data: {
                authorities: ['ROLE_AREA_MANAGEMENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/bulk-meter/bulk-meter-addreading-dialog.html',
                    controller: 'BulkMeterAddReadingController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['BulkMeter', function(BulkMeter) {
                            return BulkMeter.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('bulk-meter-detail', {id : $stateParams.id}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        
        
        
        ;
    }

})();
