(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ContratDeleteController',ContratDeleteController);

    ContratDeleteController.$inject = ['$uibModalInstance', 'entity', 'Contrat', 'ClosingContractReason'];

    function ContratDeleteController($uibModalInstance, entity, Contrat,ClosingContractReason) {
        var vm = this;
        vm.contrat = entity;
        vm.closingContractReasons = ClosingContractReason.query({ offset: 0,size: 1000});
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            Contrat.delete({id: id,closingContractReasonId:vm.contrat.closingContractReason.id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
