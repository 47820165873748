'use strict';

angular.module('wamasysApp').directive('jqGridReleveCompteur', function ($compile,$filter,$state) {
    var jqGridCounter = 0;

    return {
        replace: true,
        restrict: 'E',
        scope: {
            gridData: '=',
            releveCompteur: '='
        },
        template: '<div>' +
            '<table></table>' +
            '<div class="jqgrid-pagination" ></div>' +
            '</div>',

        link: function (scope, element) {
            var gridNumber = jqGridCounter++;
            var wrapperId = 'jqgrid-' + gridNumber;
            element.attr('id', wrapperId);

            var tableId = 'jqgrid-table-' + gridNumber;
            var table = element.find('table');
            table.attr('id', tableId);

             var gridData = {
                     data: [],
                     colNames: ['id', $filter('translate')('wamasysApp.individualMeter.type'), 
                                $filter('translate')('wamasysApp.individualMeter.numeroSerie') ,
                                $filter('translate')('wamasysApp.individualMeter.contrat') ,
                                $filter('translate')('wamasysApp.individualMeter.cluster') ,
                                $filter('translate')('wamasysApp.individualMeter.runningOrder') ,
                                $filter('translate')('wamasysApp.individualMeter.datePrecedenteLecture') ,
                                $filter('translate')('wamasysApp.individualMeter.valeurPrecedenteLecture') ,
                                $filter('translate')('wamasysApp.individualMeter.precedenteConsommation') ,
                                $filter('translate')('wamasysApp.individualMeter.newReadingDate') ,
                                'idCasNonReleve',
                                $filter('translate')('wamasysApp.individualMeter.casNonReleve') ,
                                $filter('translate')('wamasysApp.individualMeter.newConsumption') ,
                                $filter('translate')('wamasysApp.releveCompteur.detail.nvReleve'),'valeurMaximumAccepte','casNonReleve' ],
                   
                     
                     colModel: [
                        {
                            name: 'id',
                            index: 'id',
                            editable: false,
                            hidden:true
                        },
                        {
                            name: 'type',
                            index: 'type',
                            editable: false,
                            width:'5%'
                        },
                        {
                            name:'referenceCompteur',
                            index: 'referenceCompteur',
                            align: "right",
                            editable: false,
                            width:'10%',
                            formatter: function (cellValue, options, rowObject) {
                            	if(rowObject.idCompteur){
                            		return '<a target="_blank"  href="#/individual-meter/' + rowObject.idCompteur +'">'+rowObject.referenceCompteur+'</a>';
                            	}if(rowObject.idBulkMeter ){
                            		return '<a target="_blank"  href="#/bulk-meter/' + rowObject.idBulkMeter +'">'+rowObject.referenceCompteur+'</a>';
                            		
                            	
                            	}else{
                            		return '';
                            	}
                            	
                           
                            }
                        },
                        {
                            name: 'nomContrat',
                            index: 'nomContrat',
                            editable: false,
                            width:'15%',
                            formatter: function (cellValue, options, rowObject) {
                            	if(rowObject.nomContrat){
                            		return '<a target="_blank"  href="#/contrat/' + rowObject.idContrat + '/dataanalyse">'+rowObject.nomContrat+'</a>';
                            	}else{
                            		return '';
                            	}
                            	
                           
                            }
                        },
                         {
                             name: 'cluster',
                             index: 'cluster',
                             editable: false,
                             width:'9%'
                         },
                         {
                         	name: 'runningOrder',
                             index: 'runningOrder',
                             align: "center",
                             editable: false,
                             width:'6%'
                         },
                         {
                             name: 'datePrecedentReleveCompteur',
                             index: 'datePrecedentReleveCompteur',
                             align: "right",
                             editable: false,
                             width:'10%'
                         },
                         {
                             name: 'relevePrecedent',
                             index: 'relevePrecedent',
                             align: "right",
                             editable: false,
                             width:'10%'
                         },
                         {
                             name: 'precedenteConsommation',
                             index: 'precedenteConsommation',
                             align: "right",
                             editable: false,
                             width:'8%'
                         },
                         {
                             name: 'readingDate',
                             index: 'readingDate',
                             align: "right",
                             editable: false,
                             width:'10%'
                         },
                         {
                             name: 'idCasNonReleve',
                             index: 'idCasNonReleve',  
                             hidden: true, 
                             editable: false 
                         },
                         {
                             name: 'casNonReleve',
                             index: 'casNonReleve',
                             align: "right",
                             editable: true,
                             width:'10%', 
                             edittype: 'select',
//                             editDropdownValueLabel: 'value', 
//                             formatter: function (cellvalue, options, rowObject) {
//                                 var id = rowObject.idCasNonReleve; 
//                                 var label = '';
//                                 angular.forEach(scope.listCasNonReleve, function (option) {
//                                     if (option.id == id) {
//                                         label = option.label;
//                                     }
//                                 });
//                                 return label;
//                             },
                             editoptions:{   
                            	   value: function () {
                                       var options = '';
                                       angular.forEach(scope.listCasNonReleve, function (option) {
                                 
                                           options += option.id + ":" + option.libelle +";" ;
                                       });
                                       options += ":"
                                       return options;
                                   },
                                   dataEvents: [{
                                       type: 'change', // Type d'événement
                                       fn: function(e) {
                                           var rowId = $(this).closest('tr.jqgrow').attr('id'); // Obtenez l'ID de la ligne
                                           var cellValue = $(this).val(); // Obtenez la nouvelle valeur de la cellule
                                           $(this).closest('table').jqGrid('setCell', rowId, 'idCasNonReleve', cellValue);
                                           
                                           
                                           // Faites quelque chose avec la nouvelle valeur, par exemple, déclenchez un événement AngularJS
                                      
                                       }
                                   }]
                             }
                         },
                         {
                             name: 'consumption',
                             index: 'consumption',
                             align: "right",
                             editable: false,
                             width:'8%'
                         },
                         {
                             name: 'nouveauReleve',
                             index: 'nouveauReleve',
                             align: "right",
                         //    formatter:'number',
                             formatter: function (cellvalue, options, rowObject) {
                                 if (cellvalue === null || cellvalue === undefined || cellvalue === "") {
                                     return ""; // Retourne une chaîne vide si la valeur est null ou undefined
                                 } else {
                                     return $.fn.fmatter('number', cellvalue, options, rowObject);
                                 }
                             },
                             formatoptions: { decimalPlaces: 3, thousandsSeparator: "" ,decimalSeparator:"."},
                             editrules:{required: false, number:true,minValue: 0},
                             editable: true,
                             width:'10%'
                         },
                         {
                             name: 'valeurMaximumAccepte',
                             index: 'valeurMaximumAccepte',
                             editable: false,
                             hidden:true
                         },
                         {
                             name: 'casNonReleve',
                             index: 'casNonReleve',
                             editable: false,
                             hidden:true
                         },
                         
                         
                     ]
                 }

             scope.navigateToDetails = function(id) {
                 $state.go('contrat-detail.information', { id: id });
             }; 
             
             scope.getListCasNonReleve = function(){
                 var options = '';
                 // Supposons que $scope.autreVariable contienne vos options
                 angular.forEach($scope.listCasNonReleve, function (option) {
                     options += option.id + ":" + option.label + ";";
                 });
                 return options;
             
              }
            
            /* */
            table.jqGrid({
                data : gridData.data,
                datatype : "local",
                
                pgbuttons : false,
                viewrecords : false,
                pgtext : "",
                pginput : false,
                rowNum : 10000,
                
                editurl : "clientArray",
                height : 'auto',
                colNames : gridData.colNames || [],
                colModel : gridData.colModel || [],
                cmTemplate : {sortable: false },
             //   sortname : 'id',
           //     sortorder : "asc",

                autowidth : true,

                /*code permettant de mettre en orange les lignes ayant une erreur de donnée */
                loadComplete: function() {
        			var grid = $("table[id^=jqgrid-table]");

        			var ids = grid.jqGrid('getDataIDs');


        			for (var i = 0; i < ids.length; i++) {

            			var nvReleve = parseFloat(grid.jqGrid('getRowData', ids[i]).nouveauReleve);
    					var relevePrecedent = parseFloat(grid.jqGrid('getRowData', ids[i]).relevePrecedent);
    					var maxAccepte = parseFloat(grid.jqGrid('getRowData', ids[i]).valeurMaximumAccepte);
    					if(relevePrecedent!=NaN 
    							&& (
    									nvReleve > maxAccepte
    									|| nvReleve < relevePrecedent
    							)
    							){
    		              //  $('#' + $.jgrid.jqID(ids[i])).find('td').eq(12).css( { background:'orange', color:'white'}); 
    		                $('#' + $.jgrid.jqID(ids[i])).css( { background:'#E7C800', color:'white'}); //orange FireBrick
    		                $('#' + $.jgrid.jqID(ids[i])).find('a').css( {  color:'white',  'font-weight':'bold', 'text-decoration':'underline'});
    					}else if(!isNaN(nvReleve) && nvReleve!==relevePrecedent){// 
    		                $('#' + $.jgrid.jqID(ids[i])).css( { background:'lightseagreen', color:'white'}); //lightseagreen  SeaGreen
    		                $('#' + $.jgrid.jqID(ids[i])).find('a').css( { color:'white', 'font-weight':'bold', 'text-decoration':'underline' });
    					}else if(!isNaN(nvReleve)){// && nvReleve!==relevePrecedent
    		                $('#' + $.jgrid.jqID(ids[i])).css( { background:'SeaGreen', color:'white'}); //forestgreen  DarkOliveGreen
    		                $('#' + $.jgrid.jqID(ids[i])).find('a').css( { color:'white', 'font-weight':'bold', 'text-decoration':'underline' });
    					} else if(isNaN(nvReleve )){ 
    		                $('#' + $.jgrid.jqID(ids[i])).css( { background:'lightgray'}); //yellow 
    		                $('#' + $.jgrid.jqID(ids[i])).find('a').css( { 'font-weight':'bold', 'text-decoration':'underline' });  
    					}
    					var casNonReleve = grid.jqGrid('getRowData', ids[i]).casNonReleve;
    					if(casNonReleve && casNonReleve.length>0){
    		                $('#' + $.jgrid.jqID(ids[i])).css( { background:'LightCoral', color:'white'}); // LightCoral indianred  DarkSalmon
    		                $('#' + $.jgrid.jqID(ids[i])).find('a').css({ color:'white', 'font-weight':'bold', 'text-decoration':'underline' });
    					}
        			}
					
                }
            });



         	scope.$on( 'updateLigneReleveCompteur', 	function(event, data) {
         		
					var dataArray = [];

					if (data) {
						scope.listCasNonReleve = data.casNonReleve;
						if (data.clusterReleveCompteurDTO) {

							for (var x = 0; x < data.clusterReleveCompteurDTO.length; x++) {
								if (data.clusterReleveCompteurDTO[x].ligneReleveCompteur) {
								
								
									for (var i = 0; i < data.clusterReleveCompteurDTO[x].ligneReleveCompteur.length; i++) {
										if (data.clusterReleveCompteurDTO[x].ligneReleveCompteur[i].individualMeter) {
	
											var varConsumption =0;
											if(data.clusterReleveCompteurDTO[x].ligneReleveCompteur[i].valeurReleve && !isNaN( data.clusterReleveCompteurDTO[x].ligneReleveCompteur[i].valeurPrecedentReleve)){
												 varConsumption = parseFloat(data.clusterReleveCompteurDTO[x].ligneReleveCompteur[i].valeurReleve -  data.clusterReleveCompteurDTO[x].ligneReleveCompteur[i].valeurPrecedentReleve).toFixed(3);
											}
																				
											var maxConsumption = 0;
											if(data.clusterReleveCompteurDTO[x].ligneReleveCompteur[i].valeurMaximumAccepte && !isNaN(data.clusterReleveCompteurDTO[x].ligneReleveCompteur[i].valeurPrecedentReleve)){
												maxConsumption=parseFloat(data.clusterReleveCompteurDTO[x].ligneReleveCompteur[i].valeurMaximumAccepte-data.clusterReleveCompteurDTO[x].ligneReleveCompteur[i].valeurPrecedentReleve).toFixed(3);
											}
											varConsumption = '<span title=" Max consumption: '+maxConsumption+'">'+varConsumption + '</span>';
											if(data.clusterReleveCompteurDTO[x].ligneReleveCompteur[i].individualMeter.disableCheckMaxValue){
												   varConsumption = varConsumption + ' <span title=" Warning the control of the maximum value is disable on this meter"> <i class="fa fa-lg text-danger fa-exclamation-circle "></i>   </span> ';
											}
										
											var dataReleve = {
												id : data.clusterReleveCompteurDTO[x].ligneReleveCompteur[i].idLigneReleveCompteur,
												refContrat : data.clusterReleveCompteurDTO[x].ligneReleveCompteur[i].referenceContrat  ,
												idContrat : data.clusterReleveCompteurDTO[x].ligneReleveCompteur[i].idContrat  ,
												idCompteur : data.clusterReleveCompteurDTO[x].ligneReleveCompteur[i].individualMeter.id  ,
												nomContrat  :data.clusterReleveCompteurDTO[x].ligneReleveCompteur[i].nomContrat  ,
												type : 'Individual',
												cluster : data.clusterReleveCompteurDTO[x].cluster.numeroSerie,
												runningOrder : data.clusterReleveCompteurDTO[x].ligneReleveCompteur[i].individualMeter.runningOrder,
												referenceCompteur : data.clusterReleveCompteurDTO[x].ligneReleveCompteur[i].individualMeter.numeroSerie,
												datePrecedentReleveCompteur : $filter('date')(data.clusterReleveCompteurDTO[x].ligneReleveCompteur[i].datePrecedentReleveCompteur, "medium"),
												relevePrecedent : data.clusterReleveCompteurDTO[x].ligneReleveCompteur[i].valeurPrecedentReleve,
												readingDate :  $filter('date')(data.clusterReleveCompteurDTO[x].ligneReleveCompteur[i].readingDate, "medium"),
												nouveauReleve :data.clusterReleveCompteurDTO[x].ligneReleveCompteur[i].valeurReleve,
												consumption : varConsumption,
												casNonReleve:  "",
												idCasNonReleve:  0,
												valeurMaximumAccepte : data.clusterReleveCompteurDTO[x].ligneReleveCompteur[i].valeurMaximumAccepte,
												precedenteConsommation : data.clusterReleveCompteurDTO[x].ligneReleveCompteur[i].individualMeter.valeurPrecedenteConsommation
											}
											
											if(data.clusterReleveCompteurDTO[x].ligneReleveCompteur[i].casNonReleve){
												dataReleve.casNonReleve=  data.clusterReleveCompteurDTO[x].ligneReleveCompteur[i].casNonReleve.libelle;
												dataReleve.idCasNonReleve=  data.clusterReleveCompteurDTO[x].ligneReleveCompteur[i].casNonReleve.id;
											}
											
											if( data.clusterReleveCompteurDTO[x].ligneReleveCompteur[i].commentaire){
												if(dataReleve.readingDate && dataReleve.readingDate.length>0){
													dataReleve.readingDate = dataReleve.readingDate+ "<br/> Comment: " + data.clusterReleveCompteurDTO[x].ligneReleveCompteur[i].commentaire;
												}else{
													dataReleve.readingDate = "Comment: "+ data.clusterReleveCompteurDTO[x].ligneReleveCompteur[i].commentaire;
												}
												
											}
											
											
											dataArray.push(dataReleve);
										}
									}
								}
							}
						}

						if (data.listLigneReleveCompteurBulkMeter) {

							for (var i = 0; i < data.listLigneReleveCompteurBulkMeter.length; i++) {

								var varConsumption =0;
								if(data.listLigneReleveCompteurBulkMeter[i].valeurReleve && !isNaN(data.listLigneReleveCompteurBulkMeter[i].valeurPrecedentReleve)){
									 varConsumption = parseFloat(data.listLigneReleveCompteurBulkMeter[i].valeurReleve -  data.listLigneReleveCompteurBulkMeter[i].valeurPrecedentReleve).toFixed(3);
								}
								var maxConsumption = 0;
								if(data.listLigneReleveCompteurBulkMeter[i].valeurMaximumAccepte && !isNaN(data.listLigneReleveCompteurBulkMeter[i].valeurPrecedentReleve)){
									maxConsumption=parseFloat(data.listLigneReleveCompteurBulkMeter[i].valeurMaximumAccepte-data.listLigneReleveCompteurBulkMeter[i].valeurPrecedentReleve).toFixed(3);
								}
								varConsumption = '<span title=" Max consumption: '+maxConsumption+'">'+varConsumption + '</span>';
								if(data.listLigneReleveCompteurBulkMeter[i].bulkMeter.disableCheckMaxValue){
									   varConsumption = varConsumption + ' <span title=" Warning the control of the maximum value is disable on this meter"> <i class="fa fa-lg text-danger fa-exclamation-circle "></i>   </span> ';
								}
								
								var dataReleve = {
									id : data.listLigneReleveCompteurBulkMeter[i].idLigneReleveCompteur,
									type : 'Bulk Meter',
									cluster : null,
									runningOrder : null,
									refContrat : null,
									idContrat : null  ,
									nomContrat : null,
									idBulkMeter : data.listLigneReleveCompteurBulkMeter[i].bulkMeter.id  ,
									referenceCompteur : data.listLigneReleveCompteurBulkMeter[i].bulkMeter.numeroSerie,
									datePrecedentReleveCompteur :  $filter('date')(data.listLigneReleveCompteurBulkMeter[i].datePrecedentReleveCompteur, "medium"),
									relevePrecedent : data.listLigneReleveCompteurBulkMeter[i].valeurPrecedentReleve,
									readingDate : $filter('date')(data.listLigneReleveCompteurBulkMeter[i].readingDate, "medium"),
									nouveauReleve : data.listLigneReleveCompteurBulkMeter[i].valeurReleve,
									consumption : varConsumption,
									casNonReleve:  "",
									idCasNonReleve:  0,
									valeurMaximumAccepte : data.listLigneReleveCompteurBulkMeter[i].valeurMaximumAccepte,
									precedenteConsommation : data.listLigneReleveCompteurBulkMeter[i].valeurPrecedenteConsommation
								}
								
								if(data.listLigneReleveCompteurBulkMeter[i].casNonReleve){
									dataReleve.casNonReleve =data.listLigneReleveCompteurBulkMeter[i].casNonReleve.libelle;
									dataReleve.idCasNonReleve=  data.listLigneReleveCompteurBulkMeter[i].casNonReleve.id;
								}
								
								if( data.listLigneReleveCompteurBulkMeter[i].commentaire){
									if(dataReleve.readingDate && dataReleve.readingDate.length>0){
										dataReleve.readingDate = dataReleve.readingDate+ "<br/> Comment: " + data.listLigneReleveCompteurBulkMeter[i].commentaire;
									}else{
										dataReleve.readingDate =  "Comment: "+data.listLigneReleveCompteurBulkMeter[i].commentaire;
									}
						
								}
								
								
								dataArray
										.push(dataReleve);
							}
						}

					}
					table.jqGrid('setGridParam', {
						data : dataArray
					}).trigger('reloadGrid');
				});



            element.find(".ui-jqgrid").removeClass("ui-widget ui-widget-content");
            element.find(".ui-jqgrid-view").children().removeClass("ui-widget-header ui-state-default");
            element.find(".ui-jqgrid-labels, .ui-search-toolbar").children().removeClass("ui-state-default ui-th-column ui-th-ltr");
            element.find(".ui-jqgrid-pager").removeClass("ui-state-default");
            element.find(".ui-jqgrid").removeClass("ui-widget-content");

            // add classes
            element.find(".ui-jqgrid-htable").addClass("table table-bordered table-hover");
            element.find(".ui-jqgrid-btable").addClass("table table-bordered table-striped");

            element.find(".ui-pg-div").removeClass().addClass("btn btn-sm btn-primary");
            element.find(".ui-icon.ui-icon-plus").removeClass().addClass("fa fa-plus");
            element.find(".ui-icon.ui-icon-pencil").removeClass().addClass("fa fa-pencil");
            element.find(".ui-icon.ui-icon-trash").removeClass().addClass("fa fa-trash-o");
            element.find(".ui-icon.ui-icon-search").removeClass().addClass("fa fa-search");
            element.find(".ui-icon.ui-icon-refresh").removeClass().addClass("fa fa-refresh");
            element.find(".ui-icon.ui-icon-disk").removeClass().addClass("fa fa-save").parent(".btn-primary").removeClass("btn-primary").addClass("btn-success");
            element.find(".ui-icon.ui-icon-cancel").removeClass().addClass("fa fa-times").parent(".btn-primary").removeClass("btn-primary").addClass("btn-danger");

            element.find(".ui-icon.ui-icon-seek-prev").wrap("<div class='btn btn-sm btn-default'></div>");
            element.find(".ui-icon.ui-icon-seek-prev").removeClass().addClass("fa fa-backward");

            element.find(".ui-icon.ui-icon-seek-first").wrap("<div class='btn btn-sm btn-default'></div>");
            element.find(".ui-icon.ui-icon-seek-first").removeClass().addClass("fa fa-fast-backward");

            element.find(".ui-icon.ui-icon-seek-next").wrap("<div class='btn btn-sm btn-default'></div>");
            element.find(".ui-icon.ui-icon-seek-next").removeClass().addClass("fa fa-forward");

            element.find(".ui-icon.ui-icon-seek-end").wrap("<div class='btn btn-sm btn-default'></div>");
            element.find(".ui-icon.ui-icon-seek-end").removeClass().addClass("fa fa-fast-forward");
         	
            $(window).on('resize.jqGrid', function() {
               table.jqGrid('setGridWidth', $("#content").width());
            });


            $compile(element.contents())(scope);
        }
    }
});