(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('PaiementsContratController', PaiementsContratController);

    PaiementsContratController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Contrat','CollectionListe'];

    function PaiementsContratController($scope, $rootScope, $stateParams, entity, Contrat,CollectionListe) {
        var vm = this;
        vm.contrat = entity;
        vm.load = function (id) {
//            Contrat.get({id: id}, function(result) {
//                vm.contrat = result;
//            });

            CollectionListe.collectionListeEnCoursByContrat({id : id}, function(result) {
            	vm.collectionListeEnCours =  result;
            });
        };
        vm.load($stateParams.id);

  

        vm.activeTab=1;
        vm.setActiveTab = function(index){
        	vm.activeTab = index;
        }
        
        vm.hasActiveLine = function(){

        	var active = false;
        	
       	 	angular.forEach(vm.contrat.ligneContrats, function(itm){
       	 		if( itm.closed ===false &&  itm.derniereLignePaiement ){
           	 		active = true;
       	 		}
            });
        	
        	return active;
        }
        vm.calculTotalToBePaid = function(){

        	var totalToBePaid = 0;

       	 	angular.forEach(vm.contrat.ligneContrats, function(itm){
       	 		if(itm.closed === false && itm.derniereLignePaiement  != null   ){
       	 		totalToBePaid = totalToBePaid - itm.derniereLignePaiement.moneyboxApresPaiement  ;
       	 		}
            });
        	
       	 	return totalToBePaid;
        }
        
        vm.calculPayment = function(listPayment){
        	var payment = 0;
        	 angular.forEach(listPayment, function(itm){
        		 payment += itm.paidInclTax;
             });
        	 return payment;
        }


        vm.calculRemainingBalance = function(ligneContrat){
        	var result = 0;
        	if(ligneContrat.derniereLignePaiement && ligneContrat.derniereLignePaiement  != null ){
        		result = - ligneContrat.derniereLignePaiement.moneyboxApresPaiement;
            		
        	}
//        	if(ligneContrat.listPaymentReceived){
//	        	 angular.forEach(ligneContrat.listPaymentReceived, function(itm){
//	        		 result -= itm.paidInclTax;
//	             });
//        	}
        	 return result;
        }
        
    }
})();
