(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('BulkMeter', BulkMeter);

    BulkMeter.$inject = ['$resource', 'DateUtils'];

    function BulkMeter ($resource, DateUtils) {
        var resourceUrl =  'api/bulk-meters/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.dateMiseEnService = DateUtils.convertLocalDateFromServer(data.dateMiseEnService);
                    data.datePrecedenteLecture= DateUtils.convertDateTimeFromServer(data.datePrecedenteLecture);
                    data.dateSuppression = DateUtils.convertDateTimeFromServer(data.dateSuppression);
                    return data;
                }
            },
            'update': { method:'PUT',
            	transformRequest: function (data) {
                    var clonedData = Object.assign({}, data);
                	clonedData.dateMiseEnService = DateUtils.convertLocalDateToServer(data.dateMiseEnService);
                    return angular.toJson(clonedData);
                }
            } ,
            'save': { method:'POST',
            	transformRequest: function (data) {
            		var clonedData = Object.assign({}, data);
                	clonedData.dateMiseEnService = DateUtils.convertLocalDateToServer(data.dateMiseEnService);
                    return angular.toJson(clonedData);
                    
                }
            },
            'bulkMeterByCollecteur' :{
             	 method:'GET', 
             	isArray: true,
             	 url :'api/bulk-meters-by-collector/:idCollector'
             } ,
             'createCorrection': { 
            	 method:'POST',
             	 url :'api/bulk-meters/create-correction',
             	 transformRequest: function (data) {
             		var clonedData = Object.assign({}, data);
             		
                 	clonedData.lastModifiedDate = DateUtils.convertDateTimeFromServer(data.lastModifiedDate);
                 	clonedData.dateDebut = DateUtils.convertDateTimeFromServer(data.dateDebut);
                 	clonedData.dateFin = DateUtils.convertDateTimeFromServer(data.dateFin);
                    return angular.toJson(clonedData);
                     
                 }
             },
             'addReading': { 
            	 method:'POST',
             	 url :'api/bulk-meters/reading',
             	 transformRequest: function (data) {
             		var clonedData = Object.assign({}, data);
             		
                 	//clonedData.lastModifiedDate = DateUtils.convertDateTimeFromServer(data.lastModifiedDate);
                 	clonedData.readingDate = DateUtils.convertDateTimeFromServer(data.readingDate);
                    return angular.toJson(clonedData);
                     
                 }
             }
            
            
        });
    }
})();
