(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('BulkMeterAddReadingController', BulkMeterAddReadingController);

    BulkMeterAddReadingController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'BulkMeter','ReleveCompteur'];

    function BulkMeterAddReadingController ($scope, $stateParams, $uibModalInstance, entity, BulkMeter,ReleveCompteur) {
        var vm = this;
        vm.bulkMeter = entity;
        vm.readingBulkMeter = {bulkMeter: vm.bulkMeter,releveCompteur:{id:-1},valeurPrecedentReleve: vm.bulkMeter.valeurPrecedenteLecture };
        vm.load = function(id) {
            BulkMeter.get({id : id}, function(result) {
                vm.bulkMeter = result;
                vm.readingBulkMeter.valeurPrecedentReleve = vm.bulkMeter.valeurPrecedenteLecture;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:bulkMeterUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            vm.readingBulkMeter.datePrecedentReleveCompteur= vm.bulkMeter.datePrecedenteLecture; 
            vm.readingBulkMeter.valeurPrecedentReleve = vm.bulkMeter.valeurPrecedenteLecture;
            ReleveCompteur.addReading(vm.readingBulkMeter, onSaveSuccess, onSaveError);
          
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.readingDate = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
