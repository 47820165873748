(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('TypeServiceDeleteController',TypeServiceDeleteController);

    TypeServiceDeleteController.$inject = ['$uibModalInstance', 'entity', 'TypeService'];

    function TypeServiceDeleteController($uibModalInstance, entity, TypeService) {
        var vm = this;
        vm.typeService = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            TypeService.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
