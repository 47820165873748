(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('CoutAchatEauDeleteController',CoutAchatEauDeleteController);

    CoutAchatEauDeleteController.$inject = ['$uibModalInstance', 'entity', 'CoutAchatEau'];

    function CoutAchatEauDeleteController($uibModalInstance, entity, CoutAchatEau) {
        var vm = this;
        vm.coutAchatEau = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            CoutAchatEau.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
