(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('TypeRemisecommercialeDeleteController',TypeRemisecommercialeDeleteController);

    TypeRemisecommercialeDeleteController.$inject = ['$uibModalInstance', 'entity', 'TypeRemisecommerciale'];

    function TypeRemisecommercialeDeleteController($uibModalInstance, entity, TypeRemisecommerciale) {
        var vm = this;
        vm.typeRemisecommerciale = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            TypeRemisecommerciale.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
