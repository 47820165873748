(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('DataAnalyseContratController', DataAnalyseContratController);

    DataAnalyseContratController.$inject = ['$scope', '$state', '$filter','entity', 'ParseLinks', 'AlertService','$sce'];

    function DataAnalyseContratController ($scope, $state, $filter, entity, ParseLinks, AlertService, $sce) {
        var vm = this;
        vm.dataLoaded = false;
     //   vm.referenceContrat = entity.referenceContrat;

        entity.$promise.then(function(data) {
            vm.referenceContrat = data.referenceContrat;
        	vm.dataLoaded = true; // Mettre à jour la variable pour indiquer que les données sont chargées
		});

    }
})();
