(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('NumReferenceContratDeleteController',NumReferenceContratDeleteController);

    NumReferenceContratDeleteController.$inject = ['$uibModalInstance', 'entity', 'NumReferenceContrat'];

    function NumReferenceContratDeleteController($uibModalInstance, entity, NumReferenceContrat) {
        var vm = this;
        vm.numReferenceContrat = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            NumReferenceContrat.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
