(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ReportingAgenceController', ReportingAgenceController);

    ReportingAgenceController.$inject = ['$scope', '$state', '$filter', 'ParseLinks', 'AlertService', 'ReportingAgence','Factures'];

    function ReportingAgenceController ($scope, $state, $filter,  ParseLinks, AlertService, ReportingAgence,Factures) {
        var vm = this;
        vm.search=search;
        vm.newSearch=newSearch;
        vm.date = {
                startDate: $state.params.startDate?new Date($state.params.startDate):null,
                endDate: $state.params.endDate?new Date($state.params.endDate):null

        };


        function onSuccess(data, headers) {
            vm.reporting = data;
        }
        function onError(error) {
            AlertService.error(error.data.message);
        }
        
        
        function newSearch () {
            if ( vm.date.startDate && vm.date.endDate) {
            	vm.reporting = null;
	        	ReportingAgence.summary({
	        		fromDate:$filter('date')(new Date(vm.date.startDate), "yyyy-MM-dd"),
	        		toDate:$filter('date')(new Date(vm.date.endDate), "yyyy-MM-dd")
	            }, onSuccess, onError);
            }

        }
        
        function search () {
            if ( vm.date.startDate && vm.date.endDate && vm.reporting.listeContractProfil ==null) {
//            	vm.reporting = null;
	        	ReportingAgence.query({
	        		fromDate:$filter('date')(new Date(vm.date.startDate), "yyyy-MM-dd"),
	        		toDate:$filter('date')(new Date(vm.date.endDate), "yyyy-MM-dd")
	            }, onSuccess, onError);
            }

        }

	      
        $scope.openRangeDateTimePicker = function() {
				$('#daterange1').data('daterangepicker').toggle();
		}

        
		$scope.getTotalConsommationEauTotale = function() {
			var total = 0;
			if (vm.reporting != undefined && vm.reporting.listeCluster!= undefined) {
				for (var i = 0; i < vm.reporting.listeCluster.length; i++) {
					var cluster = vm.reporting.listeCluster[i];
					total += cluster.consommationEauTotale ;
				}

			}
			return total;
		}
		$scope.getTotalIndividualMeterActifs = function() {
			var total = 0;
			if (vm.reporting != undefined && vm.reporting.listeCluster!= undefined) {
				for (var i = 0; i < vm.reporting.listeCluster.length; i++) {
					var cluster = vm.reporting.listeCluster[i];
					total += cluster.nbrIndividualMeterActifs ;
				}

			}
			return total;
		}
		$scope.getTotalFamilles = function() {
			var total = 0;
			if (vm.reporting != undefined && vm.reporting.listeCluster!= undefined) {
				for (var i = 0; i < vm.reporting.listeCluster.length; i++) {
					var cluster = vm.reporting.listeCluster[i];
					total += cluster.nbrFamilles ;
				}
			}
			return total;
		}
		$scope.getTotalIndividus = function() {
			var total = 0;
			if (vm.reporting != undefined && vm.reporting.listeCluster!= undefined) {
				for (var i = 0; i < vm.reporting.listeCluster.length; i++) {
					var cluster = vm.reporting.listeCluster[i];
					total += cluster.nbrIndividus ;
				}
			}
			return total;
		}
		
		$scope.getTotalConsommationEauContrat = function() {
			var total = 0;
			if (vm.reporting != undefined && vm.reporting.listeContractProfil!= undefined ) {
				for (var i = 0; i < vm.reporting.listeContractProfil.length; i++) {
					var contractProfil = vm.reporting.listeContractProfil[i];
					total += contractProfil.consommationEauTotale ;
				}
			}
			return total;
		}

		$scope.getTotalnbrIndividualMeterActifs = function() {
			var total = 0;
			if (vm.reporting != undefined && vm.reporting.listeContractProfil!= undefined) {
				for (var i = 0; i < vm.reporting.listeContractProfil.length; i++) {
					var contractProfil = vm.reporting.listeContractProfil[i];
					total += contractProfil.nbrIndividualMeterActifs ;
				}
			}
			return total;
		}
		
		$scope.getTotalnbrFamilles = function() {
			var total = 0;
			if (vm.reporting != undefined && vm.reporting.listeContractProfil!= undefined) {
				for (var i = 0; i < vm.reporting.listeContractProfil.length; i++) {
					var contractProfil = vm.reporting.listeContractProfil[i];
					total += contractProfil.nbrFamilles ;
				}
			}
			return total;
		}
		
		$scope.getTotalnbrIndividus = function() {
			var total = 0;
			if (vm.reporting != undefined && vm.reporting.listeContractProfil!= undefined) {
				for (var i = 0; i < vm.reporting.listeContractProfil.length; i++) {
					var contractProfil = vm.reporting.listeContractProfil[i];
					total += contractProfil.nbrIndividus ;
				}
			}
			return total;
		}
		
		
		$scope.getTotalConsommationEauBulk = function() {
			var total = 0;
			if (vm.reporting != undefined && vm.reporting.listeBulkMeter!= undefined) {
				for (var i = 0; i < vm.reporting.listeBulkMeter.length; i++) {
					var bulk = vm.reporting.listeBulkMeter[i];
					total += bulk.consoEauTotale ;
				}
			}
			return total;
		}
		

		
		$scope.getTotalAcompteFacture = function() {
			var total = 0;
			if (vm.reporting != undefined && vm.reporting.listeTypeService!= undefined) {
				for (var i = 0; i < vm.reporting.listeTypeService.length; i++) {
					var typeService = vm.reporting.listeTypeService[i];
					total += typeService.totalAcompteFacture ;
				}
			}
			return total;
		}
		$scope.getTotalFactureViaCL = function() {
			var total = 0;
			if (vm.reporting != undefined && vm.reporting.listeTypeService!= undefined) {
				for (var i = 0; i < vm.reporting.listeTypeService.length; i++) {
					var typeService = vm.reporting.listeTypeService[i];
					total += typeService.totalFactureViaCL ;
				}
			}
			return total;
		}
		$scope.getTotalAPayer = function() {
			var total = 0;
			if (vm.reporting != undefined && vm.reporting.listeTypeService!= undefined) {
				for (var i = 0; i < vm.reporting.listeTypeService.length; i++) {
					var typeService = vm.reporting.listeTypeService[i];
					total += typeService.totalAPayer +  typeService.cumulDiscount  ;
				}
			}
			return total;
		}
		$scope.getTotalCumulPenaliteRemise = function() {
			var total = 0;
			if (vm.reporting != undefined && vm.reporting.listeTypeService!= undefined) {
				for (var i = 0; i < vm.reporting.listeTypeService.length; i++) {
					var typeService = vm.reporting.listeTypeService[i];
					total += typeService.cumulPenaliteRemise ;
				}
			}
			return total;
		}
		$scope.getTotalCumulRegul = function() {
			var total = 0;
			if (vm.reporting != undefined && vm.reporting.listeTypeService!= undefined) {
				for (var i = 0; i < vm.reporting.listeTypeService.length; i++) {
					var typeService = vm.reporting.listeTypeService[i];
					total += typeService.cumulRegul ;
				}
			}
			return total;
		}

		$scope.getTotalAvoir = function() {
			var total = 0;
			if (vm.reporting != undefined && vm.reporting.listeTypeService!= undefined) {
				for (var i = 0; i < vm.reporting.listeTypeService.length; i++) {
					var typeService = vm.reporting.listeTypeService[i];
					total += typeService.cumulCreditNote ;
				}
			}
			return total;
		}
		
		$scope.getTotalDiscount = function() {
			var total = 0;
			if (vm.reporting != undefined && vm.reporting.listeTypeService!= undefined) {
				for (var i = 0; i < vm.reporting.listeTypeService.length; i++) {
					var typeService = vm.reporting.listeTypeService[i];
					total += typeService.cumulDiscount;
				}
			}
			return total;
		}
		
		$scope.getTotalFacture = function() {
			var total = 0;
			if (vm.reporting != undefined) {
				total =  $scope.getTotalAPayer() - $scope.getTotalDiscount() - $scope.getTotalAvoir();
			} 
			return total;
		}
		
		$scope.getTotalCollecte = function() {
			var total = 0;
			if (vm.reporting != undefined && vm.reporting.listeTypeService!= undefined) {
				for (var i = 0; i < vm.reporting.listeTypeService.length; i++) {
					var typeService = vm.reporting.listeTypeService[i];
					total += typeService.totalCollecte;
				}
			}
			return total;
		}

		$scope.getTotalRefund = function() {
			var total = 0;
			if (vm.reporting != undefined && vm.reporting.listeTypeService!= undefined) {
				for (var i = 0; i < vm.reporting.listeTypeService.length; i++) {
					var typeService = vm.reporting.listeTypeService[i];
					total += typeService.cumulRefund;
				}
			}
			return total;
		}

		
		$scope.getTotalPaymentBalanceTransfer = function() {
			var total = 0;
			if (vm.reporting != undefined && vm.reporting.listeTypeService!= undefined) {
				for (var i = 0; i < vm.reporting.listeTypeService.length; i++) {
					var typeService = vm.reporting.listeTypeService[i];
					total += typeService.cumulPaymentBalanceTransfer;
				}
			}
			return total;
		}
		
		$scope.getTotalRefundBalanceTransfer = function() {
			var total = 0;
			if (vm.reporting != undefined && vm.reporting.listeTypeService!= undefined) {
				for (var i = 0; i < vm.reporting.listeTypeService.length; i++) {
					var typeService = vm.reporting.listeTypeService[i];
					total += typeService.cumulRefundBalanceTransfer;
				}
			}
			return total;
		}

		
		$scope.getTotalCorrectionPayment = function() {
			var total = 0;
			if (vm.reporting != undefined && vm.reporting.listeTypeService!= undefined) {
				for (var i = 0; i < vm.reporting.listeTypeService.length; i++) {
					var typeService = vm.reporting.listeTypeService[i];
					total += typeService.cumulRefundPaymentCorrection;
				}
			}
			return total;
		}
		$scope.getTotalPaye = function() {
			var total = 0;
			if (vm.reporting != undefined) {
				total =  $scope.getTotalCollecte() + $scope.getTotalPaymentBalanceTransfer() - $scope.getTotalRefundBalanceTransfer() - $scope.getTotalRefund()+$scope.getTotalCorrectionPayment() ;
			} 
			return total;
		}

    	vm.download = function(){
    		ReportingAgence.download( {
        		fromDate:$filter('date')(new Date(vm.date.startDate), "yyyy-MM-dd"),
        		toDate:$filter('date')(new Date(vm.date.endDate), "yyyy-MM-dd")}
    			,function (content){
    		      var url = URL.createObjectURL(content.response);
    		      var a = document.createElement('a');
    		      a.href = url;
    		      a.download = content.fileName;
    		      a.target = '_blank';
    		      a.click();
    		}
    				
    		);
    	}
    	
    	
    	vm.downloadBilling = function(){
    		ReportingAgence.downloadCsvBilling( {
        		fromDate:$filter('date')(new Date(vm.date.startDate), "yyyy-MM-dd"),
        		toDate:$filter('date')(new Date(vm.date.endDate), "yyyy-MM-dd")}
    			,function (content){
    		      var url = URL.createObjectURL(content.response);
    		      var a = document.createElement('a');
    		      a.href = url;
    		      a.download = content.fileName;
    		      a.target = '_blank';
    		      a.click();
    		}
    				
    		);
    	}

    	vm.downloadFactureZip = function(){
   		 //Boite de dialog d'attente.
    		var $dialog = $(
    				'<div class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true" style="padding-top:15%; overflow-y:visible;">' +
    				'<div class="modal-dialog modal-m">' +
    				'<div class="modal-content">' +
    					'<div class="modal-header"><h3 style="margin:0;"></h3></div>' +
    					'<div class="modal-body">' +
    						'<div class="progress progress-striped active" style="margin-bottom:0;"><div class="progress-bar" style="width: 100%"></div></div>' +
    					'</div>' +
    				'</div></div></div>');

    		var options = {};
    		var message = 'Please wait ! Action in progress.';
    		var settings = $.extend({
    			dialogSize: 'm',
    			progressType: '',
    			onHide: null // This callback runs after the dialog was hidden
    		}, options);
    		$dialog.find('.modal-dialog').attr('class', 'modal-dialog').addClass('modal-' + settings.dialogSize);
    		$dialog.find('.progress-bar').attr('class', 'progress-bar');
    		$dialog.find('h3').text(message);
			$dialog.modal();
			
    		Factures.downloadzip( {
        		fromDate:$filter('date')(new Date(vm.date.startDate), "yyyy-MM-dd"),
        		toDate:$filter('date')(new Date(vm.date.endDate), "yyyy-MM-dd")}
    			,function (content){
    		      var url = URL.createObjectURL(content.response);
    		      var a = document.createElement('a');
    		      a.href = url;
    		      a.download = content.fileName;
    		      a.target = '_blank';
    		      a.click();
  				$dialog.modal('hide');
    		},
    		function(error) {
				$dialog.modal('hide');
			}
    				
    		);
    	}

    	vm.downloadFinancialAdjustement = function(){
    		ReportingAgence.downloadCsvFinancialAdjustement( {
        		fromDate:$filter('date')(new Date(vm.date.startDate), "yyyy-MM-dd"),
        		toDate:$filter('date')(new Date(vm.date.endDate), "yyyy-MM-dd")}
    			,function (content){
    		      var url = URL.createObjectURL(content.response);
    		      var a = document.createElement('a');
    		      a.href = url;
    		      a.download = content.fileName;
    		      a.target = '_blank';
    		      a.click();
    		}
    				
    		);
    	}

	}
})();
