(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ClusterDetailController', ClusterDetailController);

    ClusterDetailController.$inject = ['$scope', '$state', '$rootScope', '$stateParams', 'entity', 'Cluster', 'Agence', 'User', 'IndividualMeterSearch', 'pagingParams', 'paginationConstants', 'ParseLinks', 'AlertService', ];

    function ClusterDetailController($scope, $state, $rootScope, $stateParams, entity, Cluster, Agence, User, IndividualMeterSearch, pagingParams, paginationConstants, ParseLinks, AlertService) {
        var vm = this;
        vm.cluster = entity;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.load = function () {
        	IndividualMeterSearch.query({
        		cluster: $stateParams.id,
         	   page: pagingParams.page - 1,
               size: paginationConstants.itemsPerPage
        	}, function(result, headers) {
                vm.individualMeterList = result;
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.page = pagingParams.page;
            },  function (error) {
                AlertService.error(error.data.message);
            });
        };
        vm.load();
        var unsubscribe = $rootScope.$on('wamasysApp:clusterUpdate', function(event, result) {
            vm.cluster = result;
        });
        $scope.$on('$destroy', unsubscribe);

        vm.transition = function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                id:$stateParams.id,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
            });
        };

    }
})();
