(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('FacturesContratController', FacturesContratController);

    FacturesContratController.$inject = ['$scope', '$state','$filter', '$rootScope', '$stateParams', 'entity', 'Factures', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function FacturesContratController($scope, $state,$filter, $rootScope, $stateParams, entity, Factures, ParseLinks, AlertService, pagingParams, paginationConstants) {
        var vm = this;
        vm.contrat = entity;
        vm.idContrat=$stateParams.id;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;

        vm.activeTab=1;
        vm.setActiveTab = function(index){
        	vm.activeTab = index;
        }
        
        vm.load = function () {
  
            vm.currentSearch=false;
            Factures.query(
            	{id : $stateParams.id,
	            page: pagingParams.page - 1,
	            size: paginationConstants.itemsPerPage,
	            sort: sort()
            }, onSuccess, onError);
            


            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(result, headers) {
    			vm.factures =  result;
    			vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.page = pagingParams.page;
                
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }


        };
        
        vm.load();

        vm.transition = function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                id:$stateParams.id,
                currentSearch : vm.currentSearch
            });
        };

		  
        $scope.downloadFacture = function(idAgence,idContrat,idFacture) {
			Factures.download({
				idAgence : idAgence,
				idContrat:idContrat,
				idFacture:idFacture,
			}, function(result) {
		          
                var file = result.response;

                var isChrome = !!window.chrome && !!window.chrome.webstore;
                var isIE = /*@cc_on!@*/false || !!document.documentMode;
                var isEdge = !isIE && !!window.StyleMedia;


                if (isChrome){
                    var url = window.URL || window.webkitURL;

                    var downloadLink = angular.element('<a></a>');
                    downloadLink.attr('href',url.createObjectURL(file));
                    downloadLink.attr('target','_self');
                    downloadLink.attr('download', 'image.jpg');
                    downloadLink[0].click();
                }
                else if(isEdge || isIE){
                    window.navigator.msSaveOrOpenBlob(file,'image.jpg');

                }
                else {
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }

			});
		}
        

    


    }
})();
