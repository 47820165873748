(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ClosingContractReasonDetailController', ClosingContractReasonDetailController);

    ClosingContractReasonDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'ClosingContractReason'];

    function ClosingContractReasonDetailController($scope, $rootScope, $stateParams, entity, ClosingContractReason) {
        var vm = this;
        vm.closingContractReason = entity;
        vm.load = function (id) {
            ClosingContractReason.get({id: id}, function(result) {
                vm.closingContractReason = result;
            });
        };
        var unsubscribe = $rootScope.$on('wamasysApp:closingContractReasonUpdate', function(event, result) {
            vm.closingContractReason = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
