(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('ReportingDashboard', ReportingDashboard);

    ReportingDashboard.$inject = ['$resource'];

    function ReportingDashboard ($resource) {
        var resourceUrl =  'api/elastic/dashboard';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false}
        });
        
    }
    
})();
