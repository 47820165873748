(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('CollectionListeDeleteController',CollectionListeDeleteController);

    CollectionListeDeleteController.$inject = ['$uibModalInstance', 'entity', 'CollectionListe', '$rootScope'];

    function CollectionListeDeleteController($uibModalInstance, entity, CollectionListe,$rootScope) {
        var vm = this;
        vm.collectionListe = entity;
        vm.confirmReferenceCollectionList = null;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
        	if(! vm.confirmReferenceCollectionList || vm.confirmReferenceCollectionList != "yes"){
       		 $rootScope.$emit('wamasysApp.httpError', "Incorrect ! Write yes if you want to delete.");
        	}else{
        		
        		
        		  
    			var $dialog = $(
    					'<div class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true" style="padding-top:15%; overflow-y:visible;">' +
    					'<div class="modal-dialog modal-m">' +
    					'<div class="modal-content">' +
    						'<div class="modal-header"><h3 style="margin:0;"></h3></div>' +
    						'<div class="modal-body">' +
    							'<div class="progress progress-striped active" style="margin-bottom:0;"><div class="progress-bar" style="width: 100%"></div></div>' +
    						'</div>' +
    					'</div></div></div>');

    			var options = {};
    			var message = 'Please wait ! Action in progress.';
    			var settings = $.extend({
    				dialogSize: 'm',
    				progressType: '',
    				onHide: null // This callback runs after the dialog was hidden
    			}, options);
    			$dialog.find('.modal-dialog').attr('class', 'modal-dialog').addClass('modal-' + settings.dialogSize);
    			$dialog.find('.progress-bar').attr('class', 'progress-bar');
    			$dialog.find('h3').text(message);
    			$dialog.modal();
    			
        		
                CollectionListe.delete({id: id},
                    function () {
    					$dialog.modal('hide');
                        $uibModalInstance.close(true);
                    },
                    function () {
    					$dialog.modal('hide');
                    });
        	}
        };
    }
})();
