(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('GlobalExpenseTypeDeleteController',GlobalExpenseTypeDeleteController);

    GlobalExpenseTypeDeleteController.$inject = ['$uibModalInstance', 'entity', 'GlobalExpenseType'];

    function GlobalExpenseTypeDeleteController($uibModalInstance, entity, GlobalExpenseType) {
        var vm = this;
        vm.globalExpenseType = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            GlobalExpenseType.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
