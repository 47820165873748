(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ReportingCasNonReleveController', ReportingCasNonReleveController);

    ReportingCasNonReleveController.$inject = ['$q','$scope', '$state', '$filter', 'Principal', 'ParseLinks','$timeout', 'AlertService', 'pagingParams', 'paginationConstants','Cluster','CasNonReleve','Collecteur'];

    function ReportingCasNonReleveController ($q,$scope, $state, $filter, Principal, ParseLinks,$timeout, AlertService, pagingParams, paginationConstants,Cluster,CasNonReleve,Collecteur) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        
        vm.clusters = Cluster.query({ offset: 0,size: 1000,sort: 'numeroSerie,asc'});
        vm.casNonReleves = CasNonReleve.query({ offset: 0,size: 1000,sort: 'libelle,asc'});
        vm.collecteurs = Collecteur.query({ offset: 0,size: 1000,sort: 'login,asc'});
 

        if($state.params.cluster){
//        	vm.cluster = {
//        			id:$state.params.cluster
//        	}
        	vm.cluster = [];
        	$state.params.cluster.split(",").map(
    			function(elem){ 
    				vm.cluster.push({	id:elem});
    		});
        			
	    	if(vm.clusters && vm.clusters.$promise){
	    		vm.clusters.$promise.then(function(value) {
        	    	vm.clusterNotPromise = [];
		        	$state.params.cluster.split(",").map(
		        			function (item) { 
		        				vm.clusterNotPromise.push(
			    						value.find(function(obj) {
			    							return obj.id == item
			    						})
			    				);
			    			}
		        		);
			    	});	
		          }
        }
        if($state.params.casNonReleve){
        	vm.casNonReleve = [];
        	$state.params.casNonReleve.split(",").map(
    			function(elem){ 
    				vm.casNonReleve.push({	id:elem});
    		});
        			
	    	if(vm.casNonReleves && vm.casNonReleves.$promise){
	    		vm.casNonReleves.$promise.then(function(value) {
        	    	vm.casNonReleveNotPromise = [];
		        	$state.params.casNonReleve.split(",").map(
		        			function (item) { 
		        				vm.casNonReleveNotPromise.push(
			    						value.find(function(obj) {
			    							return obj.id == item
			    						})
			    				);
			    			}
		        		);
			    	});	
		          }
        }
        if($state.params.collecteur){
        	vm.collecteur = [];
        	$state.params.collecteur.split(",").map(
    			function(elem){ 
    				vm.collecteur.push({	id:elem});
    		});
        			
	    	if(vm.collecteurs && vm.collecteurs.$promise){
	    		vm.collecteurs.$promise.then(function(value) {
        	    	vm.collecteurNotPromise = [];
		        	$state.params.collecteur.split(",").map(
		        			function (item) { 
		        				vm.collecteurNotPromise.push(
			    						value.find(function(obj) {
			    							return obj.id == item
			    						})
			    				);
			    			}
		        		);
			    	});	
		          }        	
        }
 
        

        if($state.params.agenceId){
        	vm.agenceId = [];
        	$state.params.agenceId.split(",").map(
        		function(elem){ 
        			vm.agenceId.push({	id:elem});
        	});
        }
        
        Principal.identity().then(function(account) {
        	if(account.agencesRattachement){
        		vm.agencesRattachement = account.agencesRattachement;

        		vm.agenceIdNotPromise = [];
        		if(vm.agenceId && vm.agenceId.length >0){
        			
		        	$state.params.agenceId.split(",").map(
	        			function (item) { 
	        				vm.agenceIdNotPromise.push(
	        						account.agencesRattachement.find(function(obj) {
		    							return obj.id == item
		    						})
		    				);
		    			}
	        		);
        		}
        	}
        	
        	

			  
        });


        vm.searchQuery = $state.params.searchQuery;
    	vm.date = {
                 startDate: $state.params.startDate?new Date($state.params.startDate):null,
                 endDate: $state.params.endDate?new Date($state.params.endDate):null

         }
    	
    	vm.contrats = [];
        vm.totalItems = 0;
        vm.queryCount = 0;
        vm.page = 1;
        
        vm.loadAll();
        
    	vm.download = function(){
    		CasNonReleve.downloadcsv( {
    			agenceId:vm.agenceId?vm.agenceId.map(function(elem){ return elem.id;}).join(","):null,	 
        		nom: vm.searchQuery,
        		clusterId:vm.cluster?vm.cluster.map(function(elem){ return elem.id;}).join(","):null,	
        		casNonReleveId:vm.casNonReleve?vm.casNonReleve.map(function(elem){ return elem.id;}).join(","):null,		
                collecteurId:vm.collecteur?vm.collecteur.map(function(elem){ return elem.id;}).join(","):null,	
      
        		fromDate:$filter('date')(vm.date.startDate, "yyyy-MM-dd"),
        		toDate:$filter('date')(vm.date.endDate, "yyyy-MM-dd")

        		}
    			,function (content){
    		      var url = URL.createObjectURL(content.response);
    		      var a = document.createElement('a');
    		      a.href = url;
    		      a.download = content.fileName;
    		      a.target = '_blank';
    		      a.click();
    		}
    				
    		);
    	}



    


    
        
        function loadAll () {
            if (vm.searchQuery || vm.agenceId || vm.cluster  || vm.casNonReleve    || vm.collecteur   ||  vm.date.startDate || vm.date.endDate ) {


                vm.wait=true;
                vm.currentSearch=true;
            	CasNonReleve.search({
            		agenceId: vm.agenceId?vm.agenceId.map(function(elem){ return elem.id;}).join(","):null, 
            		nom: vm.searchQuery,
            		clusterId:vm.cluster?vm.cluster.map(function(elem){ return elem.id;}).join(","):null,
            		casNonReleveId:vm.casNonReleve?vm.casNonReleve.map(function(elem){ return elem.id;}).join(","):null,
            		collecteurId:vm.collecteur?vm.collecteur.map(function(elem){ return elem.id;}).join(","):null,
                    fromDate:$filter('date')(vm.date.startDate, "yyyy-MM-dd"),
            		toDate:$filter('date')(vm.date.endDate, "yyyy-MM-dd"),
                    page: pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
            	// Obtenez la date actuelle
            	var currentDate = new Date();
            	currentDate.setMonth(currentDate.getMonth() - 3);

            	vm.date.startDate = currentDate;
            	vm.date.endDate  = new Date();
            	
                vm.currentSearch=false;
                CasNonReleve.search({
	                page: pagingParams.page - 1,
	                size: paginationConstants.itemsPerPage,
	                sort: sort()
	            }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
              
//                if (vm.predicate === 'id') {
//                    result.push('id,desc');
//                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.wait=false;
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.contrats = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                vm.wait=false;
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                searchQuery: vm.searchQuery,
                startDate: vm.date.startDate,
                endDate: vm.date.endDate,
        		agenceId:vm.agenceIdNotPromise?vm.agenceIdNotPromise.map(function(elem){ return elem.id;}).join(","):null,
                casNonReleve:vm.casNonReleveNotPromise?vm.casNonReleveNotPromise.map(function(elem){ return elem.id;}).join(","):null,
        		collecteur:vm.collecteurNotPromise?vm.collecteurNotPromise.map(function(elem){ return elem.id;}).join(","):null,
                cluster: vm.clusterNotPromise?vm.clusterNotPromise.map(function(elem){ return elem.id;}).join(","):null,
                currentSearch : vm.currentSearch
            });
        }
        
        

        function search () {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '';
            vm.reverse = false;
            vm.currentSearch=true;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true; 
            vm.agenceId = null;
            vm.agenceIdNotPromise = null;
            vm.searchQuery= null;
             vm.date.startDate= null;
             vm.date.endDate= null;
             vm.cluster= null;
             vm.clusterNotPromise= null;
             vm.casNonReleve= null;
             vm.casNonReleveNotPromise= null;
             vm.collecteur= null;
             vm.collecteurNotPromise= null;
             vm.serviceVendu= null;
             vm.serviceVenduNotPromise =null;
            currentSearch : vm.currentSearch= null;
            vm.transition();
        }
        

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.toDatePayment = false;


        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
        
        
        

        
        
        
    }
})();
