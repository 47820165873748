(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ReportingPaiementController', ReportingPaiementController);

    ReportingPaiementController.$inject = ['$scope', '$state', '$filter', 'ParseLinks', 'AlertService', 'ReportingPaiement'];

    function ReportingPaiementController ($scope, $state, $filter,  ParseLinks, AlertService, ReportingPaiement) {
        var vm = this;
        vm.search=search;
        vm.date = {
                startDate: $state.params.startDate?new Date($state.params.startDate):null,
                endDate: $state.params.endDate?new Date($state.params.endDate):null

        };


        function onSuccess(data, headers) {
            vm.reporting = data;
        }
        function onError(error) {
            AlertService.error(error.data.message);
        }
        
        function search () {
            if ( vm.date.startDate && vm.date.endDate) {
            	vm.reporting = null;
	        	ReportingPaiement.query({
	        		fromDate:$filter('date')(new Date(vm.date.startDate), "yyyy-MM-dd"),
	        		toDate:$filter('date')(new Date(vm.date.endDate), "yyyy-MM-dd")
	            }, onSuccess, onError);
            }

        }

	      
        $scope.openRangeDateTimePicker = function() {
				$('#daterange1').data('daterangepicker').toggle();
		}

        
		$scope.getTotalPaye = function() {
			var total = 0;
			if (vm.reporting != undefined && vm.reporting.listePayment!= undefined) {
				for (var i = 0; i < vm.reporting.listePayment.length; i++) {
					var payment = vm.reporting.listePayment[i];
					total += payment.totalPaye ;
				}

			}
			return total;
		}
		

    	vm.download = function(){
    		ReportingPaiement.downloadcsv( {
        		fromDate:$filter('date')(new Date(vm.date.startDate), "yyyy-MM-dd"),
        		toDate:$filter('date')(new Date(vm.date.endDate), "yyyy-MM-dd")}
    			,function (content){
    		      var url = URL.createObjectURL(content.response);
    		      var a = document.createElement('a');
    		      a.href = url;
    		      a.download = content.fileName;
    		      a.target = '_blank';
    		      a.click();
    		}
    				
    		);
    	}
    	
    	
    	vm.downloadReceipts = function(){
    		ReportingPaiement.downloadCsvReceipts( {
        		fromDate:$filter('date')(new Date(vm.date.startDate), "yyyy-MM-dd"),
        		toDate:$filter('date')(new Date(vm.date.endDate), "yyyy-MM-dd")}
    			,function (content){
    		      var url = URL.createObjectURL(content.response);
    		      var a = document.createElement('a');
    		      a.href = url;
    		      a.download = content.fileName;
    		      a.target = '_blank';
    		      a.click();
    		}
    				
    		);
    	}

    	vm.downloadPayments = function(){
    		ReportingPaiement.downloadCsvPayments( {
        		fromDate:$filter('date')(new Date(vm.date.startDate), "yyyy-MM-dd"),
        		toDate:$filter('date')(new Date(vm.date.endDate), "yyyy-MM-dd")}
    			,function (content){
    		      var url = URL.createObjectURL(content.response);
    		      var a = document.createElement('a');
    		      a.href = url;
    		      a.download = content.fileName;
    		      a.target = '_blank';
    		      a.click();
    		}
    				
    		);
    	}

	}
})();
