(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('GlobalExpenseTypeDetailController', GlobalExpenseTypeDetailController);

    GlobalExpenseTypeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'GlobalExpenseType'];

    function GlobalExpenseTypeDetailController($scope, $rootScope, $stateParams, entity, GlobalExpenseType) {
        var vm = this;
        vm.globalExpenseType = entity;
        vm.load = function (id) {
            GlobalExpenseType.get({id: id}, function(result) {
                vm.globalExpenseType = result;
            });
        };
        var unsubscribe = $rootScope.$on('wamasysApp:globalExpenseTypeUpdate', function(event, result) {
            vm.globalExpenseType = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
