(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('ReportingAccounting', ReportingAccounting);

    ReportingAccounting.$inject = ['$resource'];

    function ReportingAccounting ($resource) {
        var resourceUrl =  'api/accounting-export/';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
	       , 'download' :{
	       	 method:'GET', 
	       	 responseType: 'arraybuffer',
	           headers: {
	           	'Content-Type': undefined
	           },
	           cache: false,
	           transformResponse: function (data) {
	               return {
	                 response: new Blob([data], {type: 'text/csv'})
	               };
	           },
	       	 url :'api/accounting-export/download/:idAgence/:accountingName'
	       }
        });
        
    }
    
})();
