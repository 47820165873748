(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('CollecteurDialogController',CollecteurDialogController);

    CollecteurDialogController.$inject = ['$stateParams', '$uibModalInstance', 'entity', 'Collecteur', 'JhiLanguageService'];

    function CollecteurDialogController ($stateParams, $uibModalInstance, entity, Collecteur, JhiLanguageService) {
        var vm = this;

        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN','ROLE_AREA_CONFIGURATION','ROLE_AREA_MANAGEMENT','ROLE_DATA_OPERATOR','ROLE_COLLECTOR','ROLE_READ_ONLY'];
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.user = entity;


        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });


        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function save () {
            vm.isSaving = true;
            if (vm.user.id !== null) {
            	Collecteur.update(vm.user, onSaveSuccess, onSaveError);
            } else {
            	Collecteur.save(vm.user, onSaveSuccess, onSaveError);
            }
        }
    }
})();
