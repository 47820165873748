(function() {
    'use strict';

    angular
        .module('wamasysApp', [
            'ngStorage', 
            'tmh.dynamicLocale',
            'pascalprecht.translate', 
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            
            //TFE ajout module select
            'ui.select', 
            
            //TFE : jaout module pour Leaflet 
           'nemLogging',
            'ui-leaflet',
            
            //TFE : ajout module pour chart.js 
            'chart.js',
            
            //TFE : Ajout de module du template SmartAdmin
            'ngSanitize',
            'ngAnimate',
            'restangular',
            'daterangepicker',
            // Smartadmin Angular Common Module
            'SmartAdmin',
            'SmartAdmin.Layout',
            'SmartAdmin.Forms',
            'datatables', 
            'datatables.bootstrap'
            // App
          //  'app.auth',
           // 'app.layout',
            //'app.chat',
            //'app.dashboard',
            //'app.calendar',
            //'app.inbox',
            //'app.graphs',
            //'app.tables',
            //'app.forms',
            //'app.ui',
            //'app.widgets',
            //'app.maps',
            //'app.appViews',
            //'app.misc',
            //'app.smartAdmin',
            //'app.eCommerce'
          
            //'app.home'
        ])
        .run(run);

    run.$inject = ['stateHandler', 'translationHandler'];

    function run(stateHandler, translationHandler) {
        stateHandler.initialize();
        translationHandler.initialize();
    }
})();
