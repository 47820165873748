(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ClosingContractReasonDeleteController',ClosingContractReasonDeleteController);

    ClosingContractReasonDeleteController.$inject = ['$uibModalInstance', 'entity', 'ClosingContractReason'];

    function ClosingContractReasonDeleteController($uibModalInstance, entity, ClosingContractReason) {
        var vm = this;
        vm.closingContractReason = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            ClosingContractReason.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
