(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('CasNonReleve', CasNonReleve);

    CasNonReleve.$inject = ['$resource'];

    function CasNonReleve ($resource) {
        var resourceUrl =  'api/cas-non-releve/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'queryInclDeleted': {
                method: 'GET',
                isArray: true,
                params: {inclDeleted:true}
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'search': { method: 'GET', 
              	url :'/api/cas-non-releve/search',
              	isArray: true
            },
            'downloadcsv': {
                method: 'GET',
                headers: {
                    accept: 'text/csv'
                },
                responseType: 'arraybuffer',
              	url :'/api/cas-non-releve/downloadcsv',
                transformResponse: function (data,headers) {
                	  var filename = headers('FileName');
                	  var contentType = headers('ContentType');
                    var csv;
                    if (data) {
                        csv = new Blob([data], {
                            type: contentType
                        });
                    }
                    return {
                        response: csv,
                        fileName : filename,
                        contentType : contentType
                    };
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
