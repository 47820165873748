(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('CollectionListe', CollectionListe);

    CollectionListe.$inject = ['$resource', 'DateUtils'];

    function CollectionListe ($resource, DateUtils) {
        var resourceUrl =  'api/collection-listes/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.dateFinPeriode = DateUtils.convertLocalDateFromServer(data.dateFinPeriode);
                    return data;
                }
            },
            'getPaymentReceived' :{
           	 	method:'GET', 
           	 	url :'api/payment-received/:id'
           },
           'paymentCorrection': {
               method: 'POST',
          	    url :'api/payment-received/correction'
 
           },
           
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                	var clonedData = Object.assign({}, data);
                	clonedData.dateFinPeriode = DateUtils.convertLocalDateToServer(data.dateFinPeriode);
                    return angular.toJson(clonedData);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                	var clonedData = Object.assign({}, data);
                	clonedData.dateFinPeriode = DateUtils.convertLocalDateToServer(data.dateFinPeriode);
                    return angular.toJson(clonedData);
                }

            },
             'valider' :{
            	 method:'GET', 
            	 url :'api/collection-listes/valider/:id'
            },
            'collectionListeValideByContrat' :{
           	 method:'GET', 
           	isArray: true,
           	 url :'api/contrat/:id/collection-listes-valide'
           },
           'collectionListeEnCoursByContrat' :{
             	 method:'GET', 
             	isArray: true,
             	 url :'api/contrat/:id/collection-liste-en-cours'
             },
           'reportCollectionListeByCollecteur' :{
             	 method:'GET', 
             	 isArray: true,
             	 url :'api/collection-listes/par-collecteur'
             },
           'downloadSignature' :{
             	 method:'GET', 
             	 //isArray: true,
             	 responseType: 'arraybuffer',
                 headers: {
                 	'Content-Type': undefined
                 },
                 cache: false,
                 transformResponse: function (data) {
                     return {
                       response: new Blob([data], {type: 'image/jpeg'})
                     };
                 },
             	 url :'api/collection-listes/:idAgence/signature/:idCl/:idContratCl'
             }
            
        });
    }
})();
