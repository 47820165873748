(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ImpactPenalitRemiseRegulDeleteController',ImpactPenalitRemiseRegulDeleteController);

    ImpactPenalitRemiseRegulDeleteController.$inject = ['$uibModalInstance','entity', 'ImpactPenalitRemiseRegul'];

    function ImpactPenalitRemiseRegulDeleteController($uibModalInstance, entity, ImpactPenalitRemiseRegul) {
        var vm = this;
        vm.impactPenalitRemiseRegul = entity;

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        
        

        vm.confirmDelete = function (id) {
        	
        	if(vm.impactPenalitRemiseRegul.lignePaiementServiceId >0){
                ImpactPenalitRemiseRegul.createACancellationForThisAdjustement({id: id, commentaire: vm.commentaire},
                        function () {
                            $uibModalInstance.close(true);
                        });
        	}else{
                ImpactPenalitRemiseRegul.delete({id: id},
                    function () {
                        $uibModalInstance.close(true);
                    });
        	}
  
        };
        

    }
})();
