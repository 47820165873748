(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('Expense', Expense);

    Expense.$inject = ['$resource','DateUtils'];

    function Expense ($resource,DateUtils) {
        var resourceUrl =  'api/expense/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'queryInclDeleted': {
                method: 'GET',
                isArray: true,
                params: {inclDeleted:true}
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.date = DateUtils.convertLocalDateFromServer(data.date);
                    return data;
                }
            },
            'update': {
            	method:'PUT',
                transformRequest: function (data) {
                	var clonedData = Object.assign({}, data);
                	clonedData.date = DateUtils.convertLocalDateToServer(data.date);
                    return angular.toJson(clonedData);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                	var clonedData = Object.assign({}, data);
                	clonedData.date = DateUtils.convertLocalDateToServer(data.date);
                    return angular.toJson(clonedData);
                }
            },
            'downloadcsv': {
                method: 'GET',
                headers: {
                    accept: 'text/csv'
                },
                responseType: 'arraybuffer',
              	url :'/api/expense/downloadcsv',
                transformResponse: function (data,headers) {
                	  var filename = headers('FileName');
                	  var contentType = headers('ContentType');
                    var csv;
                    if (data) {
                        csv = new Blob([data], {
                            type: contentType
                        });
                    }
                    return {
                        response: csv,
                        fileName : filename,
                        contentType : contentType
                    };
                }
            }
        });
    }
})();
