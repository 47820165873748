(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('UserAdminDialogController',UserAdminDialogController);

    UserAdminDialogController.$inject = ['$stateParams', '$uibModalInstance', 'entity', 'UserAdmin', 'JhiLanguageService', 'Organisation'];

    function UserAdminDialogController ($stateParams, $uibModalInstance, entity, UserAdmin, JhiLanguageService, Organisation) {
        var vm = this;

        vm.authorities = ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN','ROLE_AREA_CONFIGURATION','ROLE_AREA_MANAGEMENT','ROLE_DATA_OPERATOR','ROLE_COLLECTOR','ROLE_SPECIFIC_PRICE','ROLE_READ_ONLY','ROLE_EVT_EDIT','ROLE_EVT_READ_ONLY','ROLE_BUDGET_READ_ONLY','ROLE_BUDGET_EDIT'];
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.user = entity;


        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        vm.organisations = Organisation.query();
        vm.agences = UserAdmin.getAllAgency();

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function save () {
            vm.isSaving = true;
            if (vm.user.id !== null) {
                UserAdmin.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                UserAdmin.save(vm.user, onSaveSuccess, onSaveError);
            }
        }
    }
})();
