(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('HistoriqueCompteurContrat', HistoriqueCompteurContrat);

    HistoriqueCompteurContrat.$inject = ['$resource', 'DateUtils'];

    function HistoriqueCompteurContrat ($resource, DateUtils) {
        var resourceUrl =  'api/historique-compteur-contrats/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.dateAjoutCompteur = DateUtils.convertDateTimeFromServer(data.dateAjoutCompteur);
                    return data;
                }
            },
            'getHistoriqueByContrat':  {
            	url :'api/historique-compteur-contrats/contrat/:id',
            	method: 'GET', 
            	isArray: true}
        });
    }
})();
