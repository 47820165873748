(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('CollectionListeDialogController', CollectionListeDialogController);

    CollectionListeDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'CollectionListe', 'UserSearch','PortefeuilleContrat','Principal','$timeout'];

    function CollectionListeDialogController ($scope, $stateParams, $uibModalInstance, entity, CollectionListe, UserSearch,PortefeuilleContrat,Principal,$timeout) {
        var vm = this;
        vm.collectionListe = entity;
        vm.collectionListe.genererFacturation = false;
        vm.users = UserSearch.searchCollecteur();
        vm.listPortefeuilleContrat = [];
        
//        vm.isEauEtVie = false;
//        
//	    Principal.identity().then(function(account) {
//	    	if(account.organisationName == "Eau et Vie"){
//	    		 vm.isEauEtVie = true;	
//	    		 vm.collectionListe.genererFacturation = true;
//	    	}
//	    });
        
        vm.load = function(id) {
            CollectionListe.get({id : id}, function(result) {
                vm.collectionListe = result;
            });
        };
        
        vm.updateListePortefeuille = function() {
            vm.listPortefeuilleContrat = [];
            vm.collectionListe.listPortefeuilleContratDTO= null;
        	PortefeuilleContrat.portefeuilleContratByCollecteur(
    				{idCollector : vm.collectionListe.collecteur.id}, 
    				function(result) {
    						vm.listPortefeuilleContrat =  result;

    						
    						
    		});
        };
        
        
        
        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:collectionListeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            
            CollectionListe.save(vm.collectionListe, onSaveSuccess, onSaveError);
            
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateFinPeriode = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
