(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ServicesContratController', ServicesContratController);

    ServicesContratController.$inject = ['$scope', '$window','$rootScope', '$stateParams', 'entity', 'Contrat','ImpactPenalitRemiseRegul','DateUtils'];

    function ServicesContratController($scope, $window,$rootScope, $stateParams, entity, Contrat,ImpactPenalitRemiseRegul,DateUtils) {
        var vm = this;
        vm.contrat = entity;
        vm.countService = 0;
        vm.countAboJournalier= 0;
        vm.countCredit= 0;
        vm.countVenteUnitaire= 0;
        vm.countServiceEau = 0;
        vm.today = new Date();
        vm.load = function (id) {
            Contrat.get({id: id}, function(result) {
                vm.contrat = result;

                
                angular.forEach(vm.contrat.ligneContrats, function(itm){
                	if (itm.serviceVendu.typePaiement === 'A_PAYER_EN_UNE_FOIS') {
        				vm.countVenteUnitaire++;
        			} else if (itm.serviceVendu.typePaiement === 'A_PAYER_PAR_JOUR') {
        				vm.countAboJournalier++;
        			} else if (itm.serviceVendu.typePaiement === 'A_PAYER_AVEC_ECHELONNEMENT') {
        				vm.countCredit++;
        			} else if (itm.serviceVendu.typePaiement === 'A_PAYER_SUR_CONSO_EAU') {
        				vm.countServiceEau++;
        			}
                	
                	if( itm.closed === false){
                		vm.countService++;
                	}
                	
                });
                
            });

            
            ImpactPenalitRemiseRegul.findByContrat({id : id}, function(result) {
            	vm.impactPenalitRemiseRegul =  result;
            });
        };

        vm.load($stateParams.id);

        vm.getDateFromString = function(dateString) {
        	return DateUtils.convertLocalDateFromServer(dateString);
//            var dateParts = dateString.split("-");
//            var year = parseInt(dateParts[0]);
//            var month = parseInt(dateParts[1]); // Les mois dans JavaScript sont indexés à partir de 0 (janvier = 0, février = 1, etc.)
//            var day = parseInt(dateParts[2]);
//            return new Date(year, month, day);
        };
        
        
        vm.activeTab=1;
        vm.setActiveTab = function(index){
        	vm.activeTab = index;
        }

        $('.table-responsive').on('show.bs.dropdown', function () {
			var clientWidth = document.documentElement.clientWidth;
			if (clientWidth > 992) {
				$('.table-responsive').css( "overflow", "inherit" );
			}else{
				$('.table-responsive .dropdown-menu').css( "position", "static !important" );
				
			}
		
       });

       $('.table-responsive').on('hide.bs.dropdown', function () {
			$('.table-responsive').css( "overflow", "auto" );
       })
       
       

       vm.addTabsLeft =  function () {

			var clientWidth = document.documentElement.clientWidth;

			var classtoadd = angular.element('#tabsParent');
			if (clientWidth > 992) {//768
				classtoadd.addClass('tabs-left')
			} else {
				classtoadd.removeClass('tabs-left')
			}
		}
       vm.addTabsLeft();

//       angular.element($window).on('resize',  vm.addTabsLeft());
		angular.element($window).bind('resize', function() {
			vm.addTabsLeft();
		});
		   
    }
    
})();
