(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('ImpactPenalitRemiseRegul', ImpactPenalitRemiseRegul);

    ImpactPenalitRemiseRegul.$inject = ['$resource'];

    function ImpactPenalitRemiseRegul ($resource) {
        var resourceUrl =  'api/impact-penalit-remise-reguls/:id';

        return $resource(resourceUrl, {}, {
            'findByContrat':{ method: 'GET', isArray: true, url :'api/impact-penalit-remise-reguls/search-by-contrat/:id'},
            'createACancellationForThisAdjustement':{ method: 'POST', url :'api/impact-penalit-remise-reguls/createACancellationForThisAdjustement'}
        });
    }
})();
