(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ContratServicesDialogController', ContratServicesDialogController);

    ContratServicesDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity','Contrat', 'ServiceVendu','LigneContrat'];

    function ContratServicesDialogController ($scope, $stateParams, $uibModalInstance, entity,  Contrat, ServiceVendu,LigneContrat) {
        var vm = this;
        vm.showservice = null;
        vm.serviceVendus = ServiceVendu.query({ offset: 0,size: 1000});
      
        vm.ligneContrat =  entity ;

        vm.hasBilling = false;
        if(entity && entity.$promise){
            entity.$promise.then(function(value) {
                vm.hasBilling = ( value.derniereLignePaiement !== null && (typeof  value.derniereLignePaiement != "undefined") );
            });	
        }

        
        vm.selectService = function() {
            vm.ligneContrat.libelle=vm.ligneContrat.serviceVendu.clientLabel;
            vm.ligneContrat.unitPrice=vm.ligneContrat.serviceVendu.unitPrice;
            vm.ligneContrat.amountPerInstallment=vm.ligneContrat.serviceVendu.amountPerInstallment;
            vm.ligneContrat.totalCredit=vm.ligneContrat.serviceVendu.totalCredit;
        };

        
        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:contratServiceUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
           
            LigneContrat.save(vm.ligneContrat, onSaveSuccess, onSaveError);
            
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateCloture = false;
        vm.datePickerOpenStatus.dateActivation = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
