(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ImpactPenalitRemiseRegulDialogController', ImpactPenalitRemiseRegulDialogController);

    ImpactPenalitRemiseRegulDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'ImpactPenalitRemiseRegul', 'TypeAvoir', 'TypeRemisecommerciale', 'LigneContrat'];

    function ImpactPenalitRemiseRegulDialogController ($scope, $stateParams, $uibModalInstance, entity, ImpactPenalitRemiseRegul, TypeAvoir, TypeRemisecommerciale, LigneContrat) {
        var vm = this;
        vm.impactPenalitRemiseRegul = entity;
        vm.typeAvoirs = TypeAvoir.query();
        vm.typeremisecommerciales = TypeRemisecommerciale.query();
//        vm.lignecontrats = LigneContrat.findActifByContrat({idContrat:$stateParams.id});
                
        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:impactPenalitRemiseRegulUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
             ImpactPenalitRemiseRegul.save(vm.impactPenalitRemiseRegul, onSaveSuccess, onSaveError);
            
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
