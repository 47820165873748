(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('EventFormDetailController', EventFormDetailController);

    EventFormDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'EventForm'];

    function EventFormDetailController($scope, $rootScope, $stateParams, entity, EventForm) {
        var vm = this;
        vm.eventForm = entity;
        vm.load = function (id) {
            EventForm.get({id: id}, function(result) {
                vm.eventForm = result;
            	
       			 if(vm.eventForm.metadata){
            		 try {
            			 cat.eventForm.metadata = JSON.parse(vm.eventForm.metadata);
             	    } catch (e) {
             	    	console.log(e);
             	    } 
    			 }
            	
            });
        };
        
        vm.eventForm.$promise.then(function (result){
        	vm.eventForm = result;
	   	 if(vm.eventForm.metadata){
			 try {
				var metadata = JSON.parse(vm.eventForm.metadata);
				 vm.eventForm.metadata =  [];
				for (var i in metadata){
					 vm.eventForm.metadata.push({"label":i, "value":metadata[i]});
				}
				
	 	    } catch (e) {
	 	    	console.log(e);
	 	    } 
		 }
    });
        
        var unsubscribe = $rootScope.$on('wamasysApp:eventFormUpdate', function(event, result) {
            vm.eventForm = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
