(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ExpenseTypeDialogController', ExpenseTypeDialogController);

    ExpenseTypeDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'ExpenseType','GlobalExpenseType'];

    function ExpenseTypeDialogController ($scope, $stateParams, $uibModalInstance, entity, ExpenseType,GlobalExpenseType) {
        var vm = this;
        vm.expenseType = entity;
        vm.load = function(id) {
            ExpenseType.get({id : id}, function(result) {
                vm.expenseType = result;
            });
        };

        GlobalExpenseType.query({size:100}, function(result) {
        	vm.globalExpenseTypes = result;
        	entity.$promise.then(function(value) {
	        	if( vm.expenseType.globalExpenseType){
	        		var indexElt = -1;
	        		var  fnctIdEqual = function(element,i) {
	        			if(element.id === vm.expenseType.globalExpenseType.id){
	        				indexElt=i;
	        			}
	        			return element.id === vm.expenseType.globalExpenseType.id;
	        		};
	        		
	        		var index = vm.globalExpenseTypes.findIndex(fnctIdEqual);
	        		if(index === -1 ){
	        			vm.globalExpenseTypes.push(value.globalExpenseType);
	        		}
	        	}
        	});
        });
        
        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:expenseTypeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.expenseType.id !== null) {
                ExpenseType.update(vm.expenseType, onSaveSuccess, onSaveError);
            } else {
                ExpenseType.save(vm.expenseType, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
