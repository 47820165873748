(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('ReleveCompteur', ReleveCompteur);

    ReleveCompteur.$inject = ['$resource', 'DateUtils'];

    function ReleveCompteur ($resource, DateUtils) {
        var resourceUrl =  'api/releve-compteurs/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.dateReleve = DateUtils.convertLocalDateFromServer(data.dateReleve);
                    return data;
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                	var clonedData = Object.assign({}, data);
                	clonedData.dateReleve = DateUtils.convertLocalDateToServer(data.dateReleve);
                    return angular.toJson(clonedData);
                }

            },
            'update': { method:'PUT' ,
                transformRequest: function (data) {
                	var clonedData = Object.assign({}, data);
                	clonedData.dateReleve = DateUtils.convertLocalDateToServer(data.dateReleve);
                    return angular.toJson(clonedData);
                }},
            'valider' :{method:'GET', url :'api/releve-compteurs/valider/:id'},
            'byIndividualMeter' :{method:'GET', url :'api/releve-compteurs/individual-meter/:id', isArray: true},
            'byBulkMeter' :{method:'GET', url :'api/releve-compteurs/bulk-meter/:id', isArray: true}
            ,
            'addReading': { 
           	 method:'POST',
            	 url :'api/releve-compteur/reading',
            	 transformRequest: function (data) {
            		var clonedData = Object.assign({}, data);
            		
                	//clonedData.lastModifiedDate = DateUtils.convertDateTimeFromServer(data.lastModifiedDate);
                	clonedData.readingDate = DateUtils.convertDateTimeFromServer(data.readingDate);
                   return angular.toJson(clonedData);
                    
                }
            }
        });
    }
})();
