"use strict";

angular.module('wamasysApp').directive('currentAgence', function(CurrentAgence,$timeout,Principal, $state,Auth){
    return {
        restrict: "EA",
        replace: true,
        templateUrl: "app/components/current-agence/current-agence.tpl.html",
        scope: true,
        link: function(scope, element, attrs){
        
        	scope.changerAgence = function(idAgence){

        		Auth.changerAgence(idAgence).then(function () {

        			if($state.current.name.startsWith("reporting-")){
        				$state.reload();
        			}else{
        				$state.go("home",{},{reload: true});
        			}

           		});
        		
        	};

            scope.account = null;
            
            function getAccount() {
                Principal.identity().then(function(account) {
                	scope.account = account;
                	if(account.agence){
                		scope.currentAgence = account.agence.nom;
                	}
                	if(account.organisationName){
                		scope.currentOrganisation = account.organisationName;
                	}
                	if(account.agencesRattachement){
                		scope.agencesRattachement = account.agencesRattachement;
                		scope.agencesRattachementNoFilter = account.agencesRattachement;
                		//Trie de la liste des agences.
//                		scope.agencesRattachement.sort(
//                				function (a, b) {
//                					 (a.nom > b.nom) ? 1 : -1 ;
//                				}		
//                		) ;
                	}
                	
                	scope.isAuthenticated = Principal.isAuthenticated;
                });
            }
            
            getAccount();

 

           
            scope.showInputFilter = function (arg){
            	$('#iBalAgence').toggle();
            	$('#currentAgenceFilter').toggle();
            	$('#spanAgence').toggle();
            	$timeout(function () {
            		$('#currentAgenceFilter').focus();
                   });
            };
            
            scope.filterLostFocus = function (arg){
            	$('#spanAgence').toggle();
            	$('#iBalAgence').toggle();
            	$('#currentAgenceFilter').toggle();
//            	$('#spanAgence').dropdown();
            	element.find('#currentAgenceFilter')[0].value ="";
        		scope.agencesRattachement = scope.agencesRattachementNoFilter;
            	
            };
            
//            scope.applyFilter = function (){
//            	if(element.find('#currentAgenceFilter')[0] && element.find('#currentAgenceFilter')[0].value && element.find('#currentAgenceFilter')[0].value.length > 0){
//            		scope.agencesRattachement = scope.agencesRattachementNoFilter.filter(function (agence) {
//	  	          		  return agence.nom.toLowerCase().includes(element.find('#currentAgenceFilter')[0].value.toLowerCase());
//	  	          	})
//            	}else{
//            		scope.agencesRattachement = scope.agencesRattachementNoFilter;
//            	}
//            
//          
//            };
        }
    }
});
