(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ReportingConsoEauController', ReportingConsoEauController);

    ReportingConsoEauController.$inject = ['$scope', '$state', '$filter', 'ParseLinks', 'AlertService', 'ReportingConsommationEau'];

    function ReportingConsoEauController ($scope, $state, $filter,  ParseLinks, AlertService, ReportingConsommationEau) {
        var vm = this;
        vm.search=search;
        vm.showExplanation=true;
        vm.date = {
                startDate: $state.params.startDate?new Date($state.params.startDate):null,
                endDate: $state.params.endDate?new Date($state.params.endDate):null
        };


        function onSuccess(data, headers) {
            vm.reporting = data;
        }
        function onError(error) {
            AlertService.error(error.data.message);
        }
        
        function search () {
            if ( vm.date.startDate && vm.date.endDate) {
            	vm.showExplanation = false;
            	ReportingConsommationEau.query({
	        		fromDate:$filter('date')(new Date(vm.date.startDate), "yyyy-MM-dd"),
	        		toDate:$filter('date')(new Date(vm.date.endDate), "yyyy-MM-dd")
	            }, onSuccess, onError);
            }

        }

	      
        $scope.openRangeDateTimePicker = function() {
				$('#daterange1').data('daterangepicker').toggle();
		}

        
		$scope.getTotalConsommationEauTotale = function() {
			var total = 0;
			if (vm.reporting != undefined) {
				for (var i = 0; i < vm.reporting.listeCluster.length; i++) {
					var cluster = vm.reporting.listeCluster[i];
					total += cluster.consommationEauTotale ;
				}

			}
			return total;
		}
		$scope.getTotalIndividualMeterActifs = function() {
			var total = 0;
			if (vm.reporting != undefined) {
				for (var i = 0; i < vm.reporting.listeCluster.length; i++) {
					var cluster = vm.reporting.listeCluster[i];
					total += cluster.nbrIndividualMeterActifs ;
				}

			}
			return total;
		}
		$scope.getTotalFamilles = function() {
			var total = 0;
			if (vm.reporting != undefined) {
				for (var i = 0; i < vm.reporting.listeCluster.length; i++) {
					var cluster = vm.reporting.listeCluster[i];
					total += cluster.nbrFamilles ;
				}
			}
			return total;
		}
		$scope.getTotalIndividus = function() {
			var total = 0;
			if (vm.reporting != undefined) {
				for (var i = 0; i < vm.reporting.listeCluster.length; i++) {
					var cluster = vm.reporting.listeCluster[i];
					total += cluster.nbrIndividus ;
				}
			}
			return total;
		}
		
		$scope.getTotalConsommationEauContrat = function() {
			var total = 0;
			if (vm.reporting != undefined) {
				for (var i = 0; i < vm.reporting.listeContractProfil.length; i++) {
					var contractProfil = vm.reporting.listeContractProfil[i];
					total += contractProfil.consommationEauTotale ;
				}
			}
			return total;
		}

		$scope.getTotalnbrIndividualMeterActifs = function() {
			var total = 0;
			if (vm.reporting != undefined) {
				for (var i = 0; i < vm.reporting.listeContractProfil.length; i++) {
					var contractProfil = vm.reporting.listeContractProfil[i];
					total += contractProfil.nbrIndividualMeterActifs ;
				}
			}
			return total;
		}
		
		$scope.getTotalnbrFamilles = function() {
			var total = 0;
			if (vm.reporting != undefined) {
				for (var i = 0; i < vm.reporting.listeContractProfil.length; i++) {
					var contractProfil = vm.reporting.listeContractProfil[i];
					total += contractProfil.nbrFamilles ;
				}
			}
			return total;
		}
		
		$scope.getTotalnbrIndividus = function() {
			var total = 0;
			if (vm.reporting != undefined) {
				for (var i = 0; i < vm.reporting.listeContractProfil.length; i++) {
					var contractProfil = vm.reporting.listeContractProfil[i];
					total += contractProfil.nbrIndividus ;
				}
			}
			return total;
		}
		
		
		$scope.getTotalConsommationEauBulk = function() {
			var total = 0;
			if (vm.reporting != undefined) {
				for (var i = 0; i < vm.reporting.listeBulkMeter.length; i++) {
					var bulk = vm.reporting.listeBulkMeter[i];
					total += bulk.consoEauTotale ;
				}
			}
			return total;
		}

		$scope.getPourcentagePerte = function() {

			return ($scope.getTotalConsommationEauBulk()- $scope.getTotalConsommationEauContrat ()) /$scope.getTotalConsommationEauBulk() * 100;
		}
		


	}
})();
