(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ContratReopenController',ContratReopenController);

    ContratReopenController.$inject = ['$uibModalInstance', 'entity', 'LigneContrat'];

    function ContratReopenController($uibModalInstance, entity, LigneContrat) {
        var vm = this;
        vm.ligneContrat = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmReopen = function (id) {
        	LigneContrat.reopen({idLigneContrat: id},{},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
