(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('ConsommationEauBulkMeter', ConsommationEauBulkMeter);

    ConsommationEauBulkMeter.$inject = ['$resource', 'DateUtils'];

    function ConsommationEauBulkMeter ($resource, DateUtils) {
        var resourceUrl =  'api/consommation-eau-bulk-meters/:id';

        return $resource(resourceUrl, {}, {
        	  'byBulkMeter': { method: 'GET', url:'api/consommation-eau-bulk-meters/bulk-meter/:id', isArray: true}
        
        	//{id}
           /* 'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.dateDebut = DateUtils.convertLocalDateFromServer(data.dateDebut);
                    data.dateFin = DateUtils.convertLocalDateFromServer(data.dateFin);
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.dateDebut = DateUtils.convertLocalDateToServer(data.dateDebut);
                    data.dateFin = DateUtils.convertLocalDateToServer(data.dateFin);
                    return angular.toJson(data);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data.dateDebut = DateUtils.convertLocalDateToServer(data.dateDebut);
                    data.dateFin = DateUtils.convertLocalDateToServer(data.dateFin);
                    return angular.toJson(data);
                }
            }*/
        });
    }
})();
