(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ContractProfilDialogController', ContractProfilDialogController);

    ContractProfilDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'ContractProfil'];

    function ContractProfilDialogController ($scope, $stateParams, $uibModalInstance, entity, ContractProfil) {
        var vm = this;
        vm.contractProfil = entity;
        vm.load = function(id) {
            ContractProfil.get({id : id}, function(result) {
                vm.contractProfil = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:contractProfilUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.contractProfil.id !== null) {
                ContractProfil.update(vm.contractProfil, onSaveSuccess, onSaveError);
            } else {
                ContractProfil.save(vm.contractProfil, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
