(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ChangerCompteurContratDialogController', ChangerCompteurContratDialogController);

    ChangerCompteurContratDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'HistoriqueCompteurContrat', 'Contrat', 'IndividualMeter'];

    function ChangerCompteurContratDialogController ($scope, $stateParams, $uibModalInstance, entity, HistoriqueCompteurContrat, Contrat, IndividualMeter) {
        var vm = this;
        vm.historiqueCompteurContrat = {contrat : entity} ;
        //     $stateParams.id
        vm.individualmeters = IndividualMeter.queryNonSupprimeNonUtilise();
        vm.isSaving = false;
   /*     vm.load = function(id) {
            HistoriqueCompteurContrat.get({id : id}, function(result) {
                vm.historiqueCompteurContrat = result;
            });
        };*/

        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:historiqueCompteurContratUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            
            HistoriqueCompteurContrat.save(vm.historiqueCompteurContrat, onSaveSuccess, onSaveError);
            
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();
