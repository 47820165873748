(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('LigneContrat', LigneContrat);

    LigneContrat.$inject = ['$resource', 'DateUtils'];

    function LigneContrat ($resource, DateUtils) {
        var resourceUrl =  'api/ligne-contrats/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.dateActivation = DateUtils.convertLocalDateFromServer(data.dateActivation);
                    data.datePaiementInitial = DateUtils.convertLocalDateFromServer(data.datePaiementInitial);
                	
                    return data;
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                	var clonedData = Object.assign({}, data);
                	clonedData.dateActivation = DateUtils.convertLocalDateToServer(data.dateActivation);
                	clonedData.datePaiementInitial = DateUtils.convertLocalDateToServer(data.datePaiementInitial);
                    return angular.toJson(clonedData);
                }
            },/*
            'update': { method:'PUT' ,
                transformRequest: function (data) {
                    data.dateActivation = DateUtils.convertLocalDateToServer(data.dateActivation);
                    return angular.toJson(data);
                }
            },
            'updatePaiementInitial': {
                method: 'PUT', 
                url :'api/ligne-contrats/update-paiement-initial/',
                transformRequest: function (data) {
                    data.dateActivation = DateUtils.convertLocalDateToServer(data.dateActivation);
                    data.datePaiementInitial = DateUtils.convertLocalDateToServer(data.datePaiementInitial);
                    return angular.toJson(data);
                }
            },*/
            'standBy': {
                method: 'POST', 
                url :'api/ligne-contrats/:idLigneContrat/stand_by'
            },
            'reopen': {
                method: 'POST', 
                url :'api/ligne-contrats/:idLigneContrat/reopen'
            },
            'findByContrat':{ method: 'GET', isArray: true, url :'api/ligne-contrats/search-by-contrat/:idContrat'},
            'findActifByContrat':{ method: 'GET', isArray: true, url :'api/ligne-contrats/search-actif-by-contrat/:idContrat'}
        });
    }
})();
