"use strict";

angular.module('wamasysApp').factory('CurrentAgence', function($http){
    return {
        list: [
               {
                   "href": "/",
                   "title": "CEB - Cebu"
               },
               {
                   "href": "/",
                   "title": "MAN - Manille"
               }
           ]
    }
});