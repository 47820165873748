(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('HistoriqueChangementCompteurDialogController', HistoriqueChangementCompteurDialogController);

    HistoriqueChangementCompteurDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Contrat', 'HistoriqueCompteurContrat'];

    function HistoriqueChangementCompteurDialogController ($scope, $stateParams, $uibModalInstance, entity, Contrat, HistoriqueCompteurContrat ) {
        var vm = this;
        vm.listHistoriqueCompteurContrat = HistoriqueCompteurContrat.getHistoriqueByContrat( {id:$stateParams.id});
        
        
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
