(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('TicketsContratController', TicketsContratController);

    TicketsContratController.$inject = ['$scope', '$rootScope', '$stateParams', 'Principal','entity', 'Contrat','RedmineService'];

    function TicketsContratController($scope, $rootScope, $stateParams,Principal, entity, Contrat,RedmineService) {
        var vm = this;

        vm.user = {} ;
        Principal.identity().then(function(account) {
        	if(account){
              vm.user =account ;
        	}
        });

    	vm.listIssues = RedmineService.issuesForContract({contractRef:  $stateParams.ref});
    	
        
    }
})();
