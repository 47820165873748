(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('EventFormCategoryDetailController', EventFormCategoryDetailController);

    EventFormCategoryDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'EventFormCategory'];

    function EventFormCategoryDetailController($scope, $rootScope, $stateParams, entity, EventFormCategory) {
        var vm = this;
        vm.EventFormCategory = entity;
        
        EventFormCategory.queryListSubCategoryInclDeleted({id: $stateParams.id}, function(result) {
        	vm.EventFormCategory.listEventFormSubCategory =   result;
        	
        	vm.EventFormCategory.listEventFormSubCategory.forEach(function (cat){
   			 if(cat.customFields){
        		 try {
        			 cat.customFields = JSON.parse(cat.customFields);
         	    } catch (e) {
         	    	console.log(e);
         	    } 
			 }
        	});
           
        });

        vm.load = function (id) {
            EventFormCategory.get({id: id}, function(result) {
                vm.EventFormCategory = result;
                EventFormCategory.getListSubCategory({id: id}, function(result) {
                	vm.EventFormCategory.listEventFormSubCategory =   result;
                });
            });
        };
        var unsubscribe = $rootScope.$on('wamasysApp:EventFormCategoryUpdate', function(event, result) {
            vm.EventFormCategory = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
