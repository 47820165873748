(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('Cluster', Cluster);

    Cluster.$inject = ['$resource', 'DateUtils'];

    function Cluster ($resource, DateUtils) {
        var resourceUrl =  'api/clusters/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.dateCreation = DateUtils.convertDateTimeFromServer(data.dateCreation);
                    data.dateSuppression = DateUtils.convertDateTimeFromServer(data.dateSuppression);
                    return data;
                }
            },
            'update': { method:'PUT' },

            'queryActif': { method: 'GET', url:'api/clusters-actif', isArray: true},
            'clusterActifByCollecteur' :{
             	 method:'GET', 
             	isArray: true,
             	 url :'api/clusters-actif-by-collector/:idCollector'
             }
           
        });
    }
})();
