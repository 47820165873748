(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('RedmineService', RedmineService);

    RedmineService.$inject = ['$resource','DateUtils'];

    function RedmineService ($resource,DateUtils) {
        var resourceUrl =  'api/redmine/';

        return $resource(resourceUrl, {}, {
             'myIssues': {
                 method: 'GET',
                 url :'api/redmine/issues/assigned-to-me'
              },
             'issuesCreatedByMe': {
                 method: 'GET',
                 url :'api/redmine/issues/created-by-me'
              },
             'issuesForContract': {
                 method: 'GET',
                 url :'api/redmine/issues/contract/:contractRef'
              },
              'issuesNotAssigned': {
                  method: 'GET',
                  url :'api/redmine/issues/not-assigned'
               },
              
             'createIssue': {
                 method: 'POST',
                 url :'api/redmine/issues'
              }
            		
        });
    }
})();
