(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('BulkMeterAddCorrectionController', BulkMeterAddCorrectionController);

    BulkMeterAddCorrectionController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'BulkMeter'];

    function BulkMeterAddCorrectionController ($scope, $stateParams, $uibModalInstance, entity, BulkMeter) {
        var vm = this;
        vm.bulkMeter = entity;
        vm.consommationEauBulkMeter = {bulkMeter: vm.bulkMeter,isCorrection: true,lastModifiedDate:Date.now() };
        vm.load = function(id) {
            BulkMeter.get({id : id}, function(result) {
                vm.bulkMeter = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:bulkMeterUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            
            BulkMeter.createCorrection(vm.consommationEauBulkMeter, onSaveSuccess, onSaveError);
          
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateSuppression = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
        

    }
})();
