(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ReceiptsContratController', ReceiptsContratController);

    ReceiptsContratController.$inject = ['$scope', '$state','$filter', '$rootScope', '$stateParams', 'entity', 'Factures', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function ReceiptsContratController($scope, $state,$filter, $rootScope, $stateParams, entity, Factures, ParseLinks, AlertService, pagingParams, paginationConstants) {
        var vm = this;
        vm.contrat = entity;
        vm.idContrat=$stateParams.id;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;

        vm.activeTab=1;
        vm.setActiveTab = function(index){
        	vm.activeTab = index;
        }
        
        vm.load = function () {
  
            vm.currentSearch=false;

            Factures.queryReceipts(
            	{id : $stateParams.id,
	            page: pagingParams.page - 1,
	            size: paginationConstants.itemsPerPage,
	            sort: sort()
            }, onSuccess, onError);
            
            

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(result, headers) {
    			vm.factures =  result;
    			vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.page = pagingParams.page;
                
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        };
        
        vm.load();

        vm.transition = function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                id:$stateParams.id,
                currentSearch : vm.currentSearch
            });
        };

		  
        $scope.downloadReceipt = function(idReceipt) {
			Factures.getReceipts({
				id : idReceipt
			}, function(result) {
		          
        			if(result.receiptTemplate && result.receiptTemplate.length > 0){
        				var contentFinal = createReceipt(result.receiptTemplate,result);

        				var popupWin = window.open('', '_blank');
        				popupWin.document.open();

        				popupWin.document
        						.write('<html><head>'
        								+ '<style type="text/css" media="print">@page { size: portrait; }    </style>'
        								+ '<style type="text/css" >.size1{ font-size: 18px; }  .size2{ font-size: 21px; }  .size3{ font-size: 24px; }  </style>'
        								
        								+ '</head><body onload="window.print()" style="font-family:control;width:262px;font-size: 18px;">'
        								+ contentFinal + '</body></html>');
        				popupWin.document.close();
        			}else{
                        AlertService.error(
                        		$filter('translate')('wamasysApp.contrat.documents.noReceiptTemplateForPrinting')		);
        			}
			});
		}


        
 
    var divOpen =false;
    var spanOpen =false;
    
    function createReceipt(  textData ,  donneesContrat)  {
    	var result = "";
    	
    	if(textData){
        	textData = textData.replace(/\n/g, "<br/>");
        	textData = textData.replace(/\s\s/g, " &nbsp;");
	     	while(textData.length>0){
	     		
                //On recherche les commandes
                var index = textData.indexOf("${");
                if(index<0){
                    //Si pas de commande on imprime le text
                	result =result+ textData.substring(0,textData.length);
                    textData = '';
                }else{
                    //Si on a une commande on envoie à l'imprimante le text à imprimer avant.
                    if(index>0){
                    	result = result+ textData.substring(0,index);
                    	textData = textData.substring(index,textData.length);
                    }
                    index = 0;//N'est plus utilisé, mais l'index de départ devient 0

                    //On recherche le nom de la commande, on l'execute puis on supprime le text en question du buffer
                    var indexlast = textData.indexOf("}");
                    var cmd = textData.substring(2,indexlast);
                    
                    result = result+ executerCommande(cmd, donneesContrat,$filter);
                    
                	textData = textData.substring(indexlast+1,textData.length);
                }
            }
	     	
	    	if(divOpen) result = result+ '</span>'; 
	    	if(spanOpen) result = result+ '</span>'; 
	    	}
    	
     	return result;

    }

    /*
    */
    function executerCommande(  command ,donneesContrat,$filter)  {
    	var result = "";
        if(command==null ){
            return;
        }
        switch (command) {
            case "center" :
            	if(divOpen)
            		result = result + '</span>'; 
            	result = result + '<span  style="text-align: center; display: block;">'; //mPrinter.addTextAlign(Printer.ALIGN_CENTER);
            	divOpen=true;
                break;
            case "left" :
            	if(divOpen)
            		result = result +'</span>'; 
            	
            	divOpen=false;
                break;
                
            case "right" :
            	if(divOpen)
            		result = result +'</span>'; 
            	result = result + '<span style="float: right">'; 
            	divOpen=true;
                break;
                
            case "s1" :
            	if(spanOpen)
            		result = result +'</pre>'; 
//            	result = result + '<pre class="size1">'; 
//            	spanOpen=true;
                break;
                
            case "s2" :
            	if(spanOpen)
            		result = result +'</pre>'; 
            	result = result + '<pre class="size2">'; 
            	spanOpen=true;
                break;
                
            case "s3" :
            	if(spanOpen)
            		result = result +'</pre>'; 
            	result = result + '<pre class="size3">'; 
            	spanOpen=true;
                break;

            case "checkId" :
            	return donneesContrat.paymentReceipt.checkId;
                break;

            case "receiptId" :
            	return donneesContrat.paymentReceipt.receiptNumber;
                break;
                
            case "dateReceipt" :
            	//const options = {  year: 'numeric', month: 'numeric', day: 'numeric' };
            	var datePaiement = donneesContrat.datePaiement;
            	if(!datePaiement){
            		datePaiement=new Date();
            	}else{
            		datePaiement =new Date(datePaiement);
            	}
            
            	return datePaiement.toLocaleDateString(
            			navigator.language, {  year: 'numeric', month: 'numeric', day: 'numeric' })
            			+ " - " 
            			+ datePaiement.toLocaleTimeString(navigator.language);
            //	return $filter('date')(, "mediumDate");
                break;

            case "paid" :
            	return donneesContrat.paymentReceipt.amountPaid;
                break;
           
            case "paidExclTax" :
            	var paidExclTax = 0;
            	for( var i = 0; i < donneesContrat.listPaymentReceived.length; i++  ){
            		paidExclTax = paidExclTax + donneesContrat.listPaymentReceived[i].paidExclTax;
            	}
            	return paidExclTax;
                break;

            case "customerCopy" :
            	if(donneesContrat.paymentReceipt.originalPrinted){
            		return $filter('translate')('wamasysApp.contrat.paiement.duplicate');
            	}else{
            		return $filter('translate')('wamasysApp.contrat.paiement.customerCopy');
            	}
                break;
            case "servicesPaid" :
            	var retour = "";
            	for( var i = 0; i < donneesContrat.listPaymentReceived.length; i++  ){
            		retour = retour + '<span style="float: left">' + donneesContrat.listPaymentReceived[i].serviceName + '</span>'
            		+ '<span style="float: right">' + donneesContrat.listPaymentReceived[i].paidInclTax + '</span><br/>';
            		//  paidExclTax;
            	}
            	return retour ;
                break;

            case "servicesPaidExclTaxes" :
            	var retour = "";
            	for( var i = 0; i < donneesContrat.listPaymentReceived.length; i++  ){
            		retour = retour + '<span style="float: left">' + donneesContrat.listPaymentReceived[i].serviceName + '</span>'
            		+ '<span style="float: right">' + donneesContrat.listPaymentReceived[i].paidExclTax + '</span><br/>';
            	}
            	return retour ;
                break;
                
            case "taxesPaid" :
            	var map = {};

            	for( var i = 0; i < donneesContrat.listPaymentReceived.length; i++  ){
            		if(donneesContrat.listPaymentReceived[i].taxeName || donneesContrat.listPaymentReceived[i].taxPaid){
            			if(map[donneesContrat.listPaymentReceived[i].taxeName ]){
            				map[donneesContrat.listPaymentReceived[i].taxeName ] = map[donneesContrat.listPaymentReceived[i].taxeName] + donneesContrat.listPaymentReceived[i].taxPaid;
            			}else{
            				map[donneesContrat.listPaymentReceived[i].taxeName ] =  donneesContrat.listPaymentReceived[i].taxPaid;
            			}
            			
            		}
            	}
            			
            	
            	var retour = "";

            	for (var key in map) {
            		 if (map.hasOwnProperty(key)) {
               			retour = retour + '<span style="float: left">' + key + '</span>'
               			+ '<span style="float: right">' + Number.parseFloat(map[key] ).toFixed(2) + '</span><br/>';
            		 }
            	}

            	return retour ;
                break;

            case "remainingBalance" :
            	var remainingBalance = 0.0;
            	for( var i = 0; i < donneesContrat.listPaymentReceived.length; i++  ){
            		var lignePaiement =  donneesContrat.listPaymentReceived[i];
            		 if(lignePaiement.paidInclTax ==null ){
                         remainingBalance = remainingBalance +
                         lignePaiement.remainingBalanceAfterPayment
                         	// -  lignePaiement.amountToBePaid
                         ;

                     }else{
                         remainingBalance = remainingBalance +
                                 lignePaiement.remainingBalanceAfterPayment
                                 //	-  lignePaiement.amountToBePaid
                                  //       + lignePaiement.paidInclTax
                                         ;

                     }
            		
            	}
            	return remainingBalance.toFixed(2); ;
                break;
                

            case "amountBilled" :
            	var totalMontantAPayerTtc = 0.0;
            	for( var i = 0; i < donneesContrat.listPaymentReceived.length; i++  ){
                		totalMontantAPayerTtc = totalMontantAPayerTtc
                			+ donneesContrat.listPaymentReceived[i].amountToBePaid
            		
            	}
            	return totalMontantAPayerTtc.toFixed(2); 
                break;
                
            case "totalToBePaid" :
            	var totalToBePaid = 0.0;
            	for( var i = 0; i < donneesContrat.listPaymentReceived.length; i++  ){
            		
            		totalToBePaid = totalToBePaid
            			+ donneesContrat.listPaymentReceived[i].amountToBePaid
            			- donneesContrat.listPaymentReceived[i].remainingBalanceBeforePayment
            	}
            	return totalToBePaid.toFixed(2); 
                break;

            case "balanceBeforePayment" :
            	var balanceBeforePayment = 0.0;
            	for( var i = 0; i < donneesContrat.listPaymentReceived.length; i++  ){
            		balanceBeforePayment = balanceBeforePayment
            			+ donneesContrat.listPaymentReceived[i].remainingBalanceBeforePayment
            	}
            	return balanceBeforePayment.toFixed(2); 
                break;

            case "servicesToBePaid" :
            	var retour = "";
            	for( var i = 0; i < donneesContrat.listPaymentReceived.length; i++  ){
            		retour = retour + '<span style="float: left">' + donneesContrat.listPaymentReceived[i].serviceName + '</span>'
            		+ '<span style="float: right">' + donneesContrat.listPaymentReceived[i].amountToBePaid + '</span><br/>';
            	}
            	return retour ;
                break;
                
            case "taxesToBePaid" :
            	var map = {};

            	for( var i = 0; i < donneesContrat.listPaymentReceived.length; i++  ){
            		if(donneesContrat.listPaymentReceived[i].taxeName || donneesContrat.listPaymentReceived[i].taxToBePaid){
            			if(map[donneesContrat.listPaymentReceived[i].taxeName ]){
            				map[donneesContrat.listPaymentReceived[i].taxeName ] = map[donneesContrat.listPaymentReceived[i].taxeName] + donneesContrat.listPaymentReceived[i].taxToBePaid;
            			}else{
            				map[donneesContrat.listPaymentReceived[i].taxeName ] =  donneesContrat.listPaymentReceived[i].taxToBePaid;
            			}
            			
            		}
            	}
            			
            	
            	var retour = "";
            	for (var key in map) {
            		 if (map.hasOwnProperty(key)) {
               			retour = retour + '<span style="float: left">' + key + '</span>'
               			+ '<span style="float: right">' + Number.parseFloat(map[key] ).toFixed(2)+ '</span><br/>';
            		 }
            	}

            	
            	return retour ;
                break;
                
                
            case "machineIdNumber" :
            	return donneesContrat.paymentReceipt.machineIdNumber;
                break;
                
            case "contractRef" :
            	return donneesContrat.contrat.referenceContrat;
                break;
                
            case "contractName" :
            	return donneesContrat.contrat.nomPersonne;
                break;
                
            case "contractAddress" :
            	return donneesContrat.contrat.adresse;
                break;
                
            case "logo" :
//                printLogo(mPrinter);
                break;


            default :
                break;

        }
//        if (command.startsWith("qrCode:")) {
//            String url = command.substring(7);
//            printQrCode(mPrinter,  url);
//        }
        return result;
    }
    }

})();
