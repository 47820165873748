(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ContratBillOutstandingCreditController',ContratBillOutstandingCreditController);

    ContratBillOutstandingCreditController.$inject = ['$uibModalInstance', 'entity', 'Billing'];

    function ContratBillOutstandingCreditController($uibModalInstance, entity, Billing) {
        var vm = this;
        vm.ligneContrat = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirm  = function (id) {
        	Billing.billOutstandingCredit({idLigneContrat: id},{},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
