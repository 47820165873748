(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ContratAddWaterCorrectionController', ContratAddWaterCorrectionController);

    ContratAddWaterCorrectionController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Contrat'];

    function ContratAddWaterCorrectionController ($scope, $stateParams, $uibModalInstance, entity, Contrat) {
        var vm = this;
        vm.contrat = entity;
        
        
        if(entity && entity.$promise){
            entity.$promise.then(function(value) {
            	vm.consommationEauContrat = {contrat: vm.contrat,
            			individualMeter: vm.contrat.individualMeter,
            			nombreFamille: vm.contrat.nombreFamille,
            			nombrePersonne: vm.contrat.nombrePersonne,
            			isCorrection: true,
            			lastModifiedDate:Date.now() };
            });	
        }
        
 

        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:contratUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            
            Contrat.createCorrectionWaterConsumption(vm.consommationEauContrat, onSaveSuccess, onSaveError);
          
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateSuppression = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
