(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('TypeAvoir', TypeAvoir);

    TypeAvoir.$inject = ['$resource'];

    function TypeAvoir ($resource) {
        var resourceUrl =  'api/type-avoirs/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'queryInclDeleted': {
                method: 'GET',
                isArray: true,
                params: {inclDeleted:true}
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
