(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('PaiementAgenceDialogController', PaiementAgenceDialogController);

    PaiementAgenceDialogController.$inject = ['$scope','$filter', 'entity','$stateParams', '$uibModalInstance','Billing','AlertService'];

    function PaiementAgenceDialogController ($scope,$filter,entity,$stateParams, $uibModalInstance,  Billing ,AlertService) {
        var vm = this;
        vm.contrat = entity;
        
        vm.paymentDTO = {
        		paidAmount:0,
        		contrat:null,
        		datePaiement : new Date(),
        		listLigneCollectionList:[]
        };
        vm.showDetail = false;
 
        vm.calculTotalToBePaid = function(){
        	var payment = 0;
       	 	angular.forEach(vm.contrat.ligneContrats, function(itm){
       	 		if(itm.closed === false &&  itm.derniereLignePaiement != null){
           	 		payment = payment - itm.derniereLignePaiement.moneyboxApresPaiement ;
       	 		}
            });
        	
       	 	return payment;
        }
        
        vm.calculPaidOnEachLine = function(){
        	var payment = 0;
       	 	angular.forEach(vm.contrat.ligneContrats, function(itm){
       	 		if(itm.closed === false &&  itm.derniereLignePaiement != null){
           	 		payment = payment + itm.derniereLignePaiement.montantPayeCollecteur ;
       	 		}
            });
        	
       	 	return payment.toFixed(2);
        }
 
        vm.countLigneActives = function(){
        	var result = 0;
       	 	angular.forEach(vm.contrat.ligneContrats, function(itm){
       	 		if(itm.closed === false){
       	 			result++ ;
       	 		}
            });
        	
       	 	return result;
        }
        
        vm.onSavePayment = function () {
        	if( vm.showDetail){
           	 	if(vm.calculPaidOnEachLine() === Number.parseFloat(vm.paymentDTO.paidAmount).toFixed(2)){
           	 		//Save si le total des lignes === le montant  global payé.
       	    		vm.save();
           	 	}else{
                    AlertService.error("error.business.billing.paymentTotalNotEqualInDetail");

           	 	}
        	}else{
        		if(vm.paymentDTO.paidAmount === vm.calculTotalToBePaid() ){
        			//Répartir le paiement entre les lignes
               	 	angular.forEach(vm.contrat.ligneContrats, function(itm){
               	 		if(itm.derniereLignePaiement && itm.closed === false){
               	 			itm.derniereLignePaiement.montantPayeCollecteur = - itm.derniereLignePaiement.moneyboxApresPaiement ;
               	 		}
                    });
               	 	//On refait un controle.
            		if(vm.paymentDTO.paidAmount === vm.calculPaidOnEachLine()){
            			vm.save();
            		}else{
       	    		 	vm.showDetail = true;
    	   	    	}
	   	    	}else if (vm.countLigneActives() == 1){
               	 	angular.forEach(vm.contrat.ligneContrats, function(itm){
               	 		if(itm.closed === false){
               	 			itm.derniereLignePaiement.montantPayeCollecteur = vm.paymentDTO.paidAmount ;
               	 		}
                    });	
               	 	//On refait un controle.
            		if(vm.paymentDTO.paidAmount === vm.calculPaidOnEachLine()){
            			vm.save();
            		}else{
       	    		 	vm.showDetail = true;
    	   	    	}
	   	    	}else{
   	    		 	vm.showDetail = true;
	   	    	}
        		
        		if(vm.showDetail ){
        			//On répartie le montant entre les lignes.
        			var mapRatio = {};
        			var listLignePaiement = [];
        			 
        			var toBePaidPositive = 0;
        			var countToBePaidZero = 0;
        			var countToBePaidNegative = 0;
               	 	angular.forEach(vm.contrat.ligneContrats, function(itm){
               	 		if(itm.closed === false &&  itm.derniereLignePaiement != null && itm.derniereLignePaiement.moneyboxApresPaiement < 0 ){
               	 			toBePaidPositive = toBePaidPositive - itm.derniereLignePaiement.moneyboxApresPaiement ;
               	 		}else if(itm.closed === false &&  itm.derniereLignePaiement != null && itm.derniereLignePaiement.moneyboxApresPaiement === 0){
               	 			countToBePaidZero++ ;
               	 		}else if(itm.closed === false && itm.derniereLignePaiement != null && itm.derniereLignePaiement.moneyboxApresPaiement > 0){
               	 			countToBePaidNegative++;
               	 		}
                    });
               	 	
               	 	//cas où on a un tobePaid positif à payer, on réparti entre les lignes.
               	 	if(toBePaidPositive>0){
	               		 angular.forEach(vm.contrat.ligneContrats, function(itm){
	                	 		if(itm.closed === false &&  itm.derniereLignePaiement != null ){
	                	 			if(itm.derniereLignePaiement.moneyboxApresPaiement < 0 ){
	                	 				var ratio =  (- itm.derniereLignePaiement.moneyboxApresPaiement ) / toBePaidPositive ;
	                	 				mapRatio[itm.derniereLignePaiement.id]= ratio;
	                	 				listLignePaiement.push(itm.derniereLignePaiement);
	                	 			}else{
	                	 				mapRatio[itm.derniereLignePaiement.id]=  0;
	                	 				listLignePaiement.push(itm.derniereLignePaiement);
	                	 			}
	                	 			
	                	 		}
	         			 });	
               	 	}else if(countToBePaidZero > 0){
               	 		//Pas de toBePaid positif, dans ce cas on 
	               		 angular.forEach(vm.contrat.ligneContrats, function(itm){
	             	 		if(itm.closed === false &&  itm.derniereLignePaiement != null ){
	             	 			if(itm.derniereLignePaiement.moneyboxApresPaiement === 0 ){
	             	 				mapRatio[itm.derniereLignePaiement.id]= 1/countToBePaidZero;
	             	 				listLignePaiement.push(itm.derniereLignePaiement);
	             	 			}else{
                	 				mapRatio[itm.derniereLignePaiement.id]=  0;
	             	 				listLignePaiement.push(itm.derniereLignePaiement);
	             	 			}
	             	 		}
	               		 });	
              	 	}else if(countToBePaidNegative > 0){
               	 		//Pas de toBePaid positif, dans ce cas on 
	               		 angular.forEach(vm.contrat.ligneContrats, function(itm){
	             	 		if(itm.closed === false &&  itm.derniereLignePaiement != null ){
	             	 			if(itm.derniereLignePaiement.moneyboxApresPaiement > 0 ){
	             	 				mapRatio[itm.derniereLignePaiement.id]= 1/countToBePaidNegative;
	             	 				listLignePaiement.push(itm.derniereLignePaiement);
	             	 			}else{
	             	 				mapRatio[itm.derniereLignePaiement.id]=  0;
	             	 				listLignePaiement.push(itm.derniereLignePaiement);
	             	 			}
	             	 			
	             	 		}
	               		 });	
               	 	}
        	

        			 for (var i = 0; i < listLignePaiement.length; i++) {
        				 
        				 listLignePaiement[i].montantPayeCollecteur =  Number.parseFloat( 
        						 (vm.paymentDTO.paidAmount *  mapRatio[listLignePaiement[i].id]).toFixed(2));	
        			 }
        			 
        			 //On controle qu'on a pas besoin d'ajoute run arrondi à la fin
        			 var totalPaid = 0;
        			 for (var i = 0; i < listLignePaiement.length; i++) {
        				 totalPaid = totalPaid+listLignePaiement[i].montantPayeCollecteur ;
        				 if(i == (listLignePaiement.length-1)){
        					 if( vm.paymentDTO.paidAmount !== totalPaid ){
        						 listLignePaiement[i].montantPayeCollecteur  = 
        							 Number.parseFloat( ( listLignePaiement[i].montantPayeCollecteur  +( vm.paymentDTO.paidAmount - totalPaid )).toFixed(2));
        					 }
        				 }
        			 }
        		}
        	}
        }
        
        var onSaveSuccess = function (result) {

            $scope.$emit('wamasysApp:paiementAgenceUpdate', vm.resultPayment);
	        $uibModalInstance.close(vm.resultPayment);
	        vm.isSaving = false;

        	vm.resultPayment = result ;
        	vm.printReceipt();
	        
	        /*
        	$("#btnSavePayment").hide();
        	$("#bodyPaiement").hide();
        	$("#bodyPaiementEffectue").show();
        	

            vm.isSaving = false;*/
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            
            
            //On ajoute les lignes dans l'array
            vm.paymentDTO.listLigneCollectionList = [];
            angular.forEach(vm.contrat.ligneContrats, function(itm){
            	if(itm.closed === false && itm.derniereLignePaiement){ // .montantPayeCollecteur 

                	itm.derniereLignePaiement.ligneContrat = {id:itm.id};
                	vm.paymentDTO.listLigneCollectionList.push(itm.derniereLignePaiement);
            	}
            });
            vm.paymentDTO.contrat = vm.contrat,
            
            Billing.savePayement(vm.paymentDTO , onSaveSuccess, onSaveError);
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        
        
        

		
		vm.printReceipt = function() {
			
//	        $scope.$emit('wamasysApp:paiementAgenceUpdate', vm.resultPayment);
//	        $uibModalInstance.close(vm.resultPayment);
//	        vm.isSaving = false;
	        
			var content = vm.resultPayment.receiptTemplate;

			/*
			content = '<strong>'
					+ $filter('translate')
							(
									'wamasysApp.collectionListe.titreListeCollecteImpression')
					+ ' '
					+ $filter('date')(vm.collectionListe.dateFinPeriode,
							"mediumDate")
		*/

			/*
			content += '<div align="center">E&V Tubig Pag-asa, Inc.</br>' + 
			 'address</br>' + 
			 'Philippines</br></div>' + 
			 '</br>' + 
			 'VAT Registered TIN 123-456-789-0000' + 
			 '</br>' + 
			 '</br>' + 
			 'ablablabla' + 
			 '<h2><div align="center">OFFICIAL RECEIPT </div></h2>' + 
			 '</br>' + 
			 '</br>' + 
			 'MIN : ${machineIdNumber}' + 
			 '</br>' + 
			 '</br>' + 
			 'Receipt : ${receiptId}' + 
			 'Date : ${dateReceipt} ' + 
			 'Contract : ${contractRef} ' + 
			 'Name : ${contractName} ' + 
			 'Address: ${contractAddress}' + 
			 'SERVICE              |               PAID' + 
			 '${servicesPaid}' + 
			 '-----------------------------------------' + 
			 'TAXES                |               PAID' + 
			 '${taxesPaid}' + 
			 '${s2}${right}Total Paid  ${paid}' + 
			 '${s1}${left}' + 
			 'Internal id : ${checkId}' + 
			 'Wamasys' + 
			 'blablabla' + 
			 '<br/>' + 
			 '--------------------1--------------------<br/>' + 
			 'D--------------------------------------0E' + 
			 '<br/>' + 
			 'Water service <span align="right">100,00.00</span><br/>' + 
			 'Water Access <span align="right">100,00.00</span><br/>' + 
			 '<h4><div align="right">Payé : 10 000.00  </div></h4>' + 
			 '<br/>';
			 */
			
			//On boucle, des qu'on voit une commande on fait.
			
			if(content && content.length > 0){
				var contentFinal = createReceipt(content,vm.resultPayment,$filter);

				var popupWin = window.open('', '_blank');
				popupWin.document.open();

				popupWin.document
						.write('<html><head>'
								+ '<style type="text/css" media="print">@page { size: portrait; }    </style>'
								+ '<style type="text/css" >.size1{ font-size: 18px; }  .size2{ font-size: 21px; }  .size3{ font-size: 24px; }  </style>'
								
								+ '</head><body onload="window.print()" style="font-family:control;width:262px;font-size: 18px;">'
								+ contentFinal + '</body></html>');
				popupWin.document.close();
			}
		
	
		}
    }
    
    var divOpen =false;
    var spanOpen =false;
    
    function createReceipt(  textData ,  donneesContrat,$filter)  {
    	var result = "";
    	
    	if(textData){
        	textData = textData.replace(/\n/g, "<br/>");
        	textData = textData.replace(/\s\s/g, " &nbsp;");
	     	while(textData.length>0){
	     		
                //On recherche les commandes
                var index = textData.indexOf("${");
                if(index<0){
                    //Si pas de commande on imprime le text
                	result =result+ textData.substring(0,textData.length);
                    textData = '';
                }else{
                    //Si on a une commande on envoie à l'imprimante le text à imprimer avant.
                    if(index>0){
                    	result = result+ textData.substring(0,index);
                    	textData = textData.substring(index,textData.length);
                    }
                    index = 0;//N'est plus utilisé, mais l'index de départ devient 0

                    //On recherche le nom de la commande, on l'execute puis on supprime le text en question du buffer
                    var indexlast = textData.indexOf("}");
                    var cmd = textData.substring(2,indexlast);
                    
                    result = result+ executerCommande(cmd, donneesContrat,$filter);
                    
                	textData = textData.substring(indexlast+1,textData.length);
                }
            }
	     	
	    	if(divOpen) result = result+ '</span>'; 
	    	if(spanOpen) result = result+ '</span>'; 
	    	}
    	
     	return result;

    }

    /*
    */
    function executerCommande(  command ,donneesContrat,$filter)  {
    	var result = "";
        if(command==null ){
            return;
        }
        switch (command) {
            case "center" :
            	if(divOpen)
            		result = result + '</span>'; 
            	result = result + '<span  style="text-align: center; display: block;">'; //mPrinter.addTextAlign(Printer.ALIGN_CENTER);
            	divOpen=true;
                break;
            case "left" :
            	if(divOpen)
            		result = result +'</span>'; 
            	
            	divOpen=false;
                break;
                
            case "right" :
            	if(divOpen)
            		result = result +'</span>'; 
            	result = result + '<span style="float: right">'; 
            	divOpen=true;
                break;
                
            case "s1" :
            	if(spanOpen)
            		result = result +'</pre>'; 
//            	result = result + '<pre class="size1">'; 
//            	spanOpen=true;
                break;
                
            case "s2" :
            	if(spanOpen)
            		result = result +'</pre>'; 
            	result = result + '<pre class="size2">'; 
            	spanOpen=true;
                break;
                
            case "s3" :
            	if(spanOpen)
            		result = result +'</pre>'; 
            	result = result + '<pre class="size3">'; 
            	spanOpen=true;
                break;

            case "checkId" :
            	return donneesContrat.paymentReceipt.checkId;
                break;

            case "receiptId" :
            	return donneesContrat.paymentReceipt.receiptNumber;
                break;
                
            case "dateReceipt" :
            	//const options = {  year: 'numeric', month: 'numeric', day: 'numeric' };
             	var datePaiement = donneesContrat.datePaiement;
            	if(!datePaiement){
            		datePaiement=new Date();
            	}else{
            		datePaiement =new Date(datePaiement);
            	}
            	return datePaiement.toLocaleDateString(
            			navigator.language, {  year: 'numeric', month: 'numeric', day: 'numeric' })
            			+ " - " 
            			+ datePaiement.toLocaleTimeString(navigator.language);
            //	return $filter('date')(, "mediumDate");
                break;

            case "paid" :
            	return donneesContrat.paidAmount;
                break;

            case "paidExclTax" :
            	var paidExclTax = 0;
            	for( var i = 0; i < donneesContrat.listPaymentReceived.length; i++  ){
            		paidExclTax = paidExclTax + donneesContrat.listPaymentReceived[i].paidExclTax;
            	}
            	return paidExclTax;
                break;

            case "customerCopy" :
            	//Attention ici on indique déjà que c'est imprimé, donc on agfiche toujours customercopy
//            	if(donneesContrat.paymentReceipt.originalPrinted){
//            		return $filter('translate')('wamasysApp.contrat.paiement.duplicate');
//            	}else{
//            		return $filter('translate')('wamasysApp.contrat.paiement.customerCopy');
//            	}
            	return $filter('translate')('wamasysApp.contrat.paiement.customerCopy');
                break;
                
            case "servicesPaid" :
            	var retour = "";
            	for( var i = 0; i < donneesContrat.listPaymentReceived.length; i++  ){
            		retour = retour + '<span style="float: left">' + donneesContrat.listPaymentReceived[i].serviceName + '</span>'
            		+ '<span style="float: right">' + donneesContrat.listPaymentReceived[i].paidInclTax + '</span><br/>';
            		//  paidExclTax;
            	}
            	return retour ;
                break;
                
            case "taxesPaid" :
            	var map = {};

            	for( var i = 0; i < donneesContrat.listPaymentReceived.length; i++  ){
            		if(donneesContrat.listPaymentReceived[i].taxeName || donneesContrat.listPaymentReceived[i].taxPaid){
            			if(map[donneesContrat.listPaymentReceived[i].taxeName ]){
            				map[donneesContrat.listPaymentReceived[i].taxeName ] = map[donneesContrat.listPaymentReceived[i].taxeName] + donneesContrat.listPaymentReceived[i].taxPaid;
            			}else{
            				map[donneesContrat.listPaymentReceived[i].taxeName ] =  donneesContrat.listPaymentReceived[i].taxPaid;
            			}
            			
            		}
            	}
            			
            	
            	var retour = "";

            	for (var key in map) {
            		 if (map.hasOwnProperty(key)) {
               			retour = retour + '<span style="float: left">' + key + '</span>'
               			+ '<span style="float: right">' + Number.parseFloat(map[key] ).toFixed(2) + '</span><br/>';
            		 }
            	}

            	return retour ;
                break;

            case "remainingBalance" :
            	var remainingBalance = 0.0;
            	for( var i = 0; i < donneesContrat.listPaymentReceived.length; i++  ){
            		var lignePaiement =  donneesContrat.listPaymentReceived[i];
            		 if(lignePaiement.paidInclTax ==null ){
                         remainingBalance = remainingBalance +
                         lignePaiement.remainingBalanceAfterPayment ;
                        // -   lignePaiement.amountToBePaid;

                     }else{
                         remainingBalance = remainingBalance +
                                 lignePaiement.remainingBalanceAfterPayment ;;
                               //  -  lignePaiement.amountToBePaid + lignePaiement.paidInclTax;

                     }
            		
            	}
            	return remainingBalance.toFixed(2); ;
                break;
                

            case "amountBilled" :
            	var totalMontantAPayerTtc = 0.0;
            	for( var i = 0; i < donneesContrat.listPaymentReceived.length; i++  ){
                		totalMontantAPayerTtc = totalMontantAPayerTtc
                			+ donneesContrat.listPaymentReceived[i].amountToBePaid
            		
            	}
            	return totalMontantAPayerTtc.toFixed(2); 
                break;
                
            case "totalToBePaid" :
            	var totalToBePaid = 0.0;
            	for( var i = 0; i < donneesContrat.listPaymentReceived.length; i++  ){
            		
            		totalToBePaid = totalToBePaid
            			+ donneesContrat.listPaymentReceived[i].amountToBePaid
            			- donneesContrat.listPaymentReceived[i].remainingBalanceBeforePayment
            	}
            	return totalToBePaid.toFixed(2); 
                break;

            case "balanceBeforePayment" :
            	var balanceBeforePayment = 0.0;
            	for( var i = 0; i < donneesContrat.listPaymentReceived.length; i++  ){
            		balanceBeforePayment = balanceBeforePayment
            			+ donneesContrat.listPaymentReceived[i].remainingBalanceBeforePayment
            	}
            	return balanceBeforePayment.toFixed(2); 
                break;

            case "servicesToBePaid" :
            	var retour = "";
            	for( var i = 0; i < donneesContrat.listPaymentReceived.length; i++  ){
            		retour = retour + '<span style="float: left">' + donneesContrat.listPaymentReceived[i].serviceName + '</span>'
            		+ '<span style="float: right">' + donneesContrat.listPaymentReceived[i].amountToBePaid + '</span><br/>';
            	}
            	return retour ;
                break;

            case "servicesPaidExclTaxes" :
            	var retour = "";
            	for( var i = 0; i < donneesContrat.listPaymentReceived.length; i++  ){
            		retour = retour + '<span style="float: left">' + donneesContrat.listPaymentReceived[i].serviceName + '</span>'
            		+ '<span style="float: right">' + donneesContrat.listPaymentReceived[i].paidExclTax + '</span><br/>';
            	}
            	return retour ;
                break;
                
            case "taxesToBePaid" :
            	var map = {};

            	for( var i = 0; i < donneesContrat.listPaymentReceived.length; i++  ){
            		if(donneesContrat.listPaymentReceived[i].taxeName || donneesContrat.listPaymentReceived[i].taxToBePaid){
            			if(map[donneesContrat.listPaymentReceived[i].taxeName ]){
            				map[donneesContrat.listPaymentReceived[i].taxeName ] = map[donneesContrat.listPaymentReceived[i].taxeName] + donneesContrat.listPaymentReceived[i].taxToBePaid;
            			}else{
            				map[donneesContrat.listPaymentReceived[i].taxeName ] =  donneesContrat.listPaymentReceived[i].taxToBePaid;
            			}
            			
            		}
            	}
            			
            	
            	var retour = "";
            	for (var key in map) {
            		 if (map.hasOwnProperty(key)) {
               			retour = retour + '<span style="float: left">' + key + '</span>'
               			+ '<span style="float: right">' + Number.parseFloat(map[key] ).toFixed(2)+ '</span><br/>';
            		 }
            	}

            	
            	return retour ;
                break;
                
                
            case "machineIdNumber" :
            	return donneesContrat.paymentReceipt.machineIdNumber;
                break;
                
            case "contractRef" :
            	return donneesContrat.contrat.referenceContrat;
                break;
                
            case "contractName" :
            	return donneesContrat.contrat.nomPersonne;
                break;
                
            case "contractAddress" :
            	return donneesContrat.contrat.adresse;
                break;
                
            case "logo" :
//                printLogo(mPrinter);
                break;


            default :
                break;

        }
//        if (command.startsWith("qrCode:")) {
//            String url = command.substring(7);
//            printQrCode(mPrinter,  url);
//        }
        return result;
    }
})();
