(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('modele-contrat', {
            parent: 'entity',
            url: '/modele-contrat?page&sort&search',
            data: {
                authenticate: true,
                authorities: ['ROLE_AREA_CONFIGURATION','ROLE_DATA_OPERATOR', 'ROLE_READ_ONLY'],
                pageTitle: 'wamasysApp.modeleContrat.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/business/modele-contrat/modele-contrats.html'
                }
        
            },
          

            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('modeleContrat');
                    $translatePartialLoader.addPart('serviceVendu');
                    $translatePartialLoader.addPart('typePaiementService');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
	
        .state('modele-contrat.liste-contrat',{
			url : '/liste-contrat/',
			views : {
	            'panneau@content': {
	                templateUrl: 'app/business/modele-contrat/modele-contrats-liste-contrat.html',	
	                controller: 'ModeleContratController',
	                controllerAs: 'vm'
	            }
			}
		})	



    
        .state('modele-contrat-detail', {
            parent: 'entity',
            url: '/modele-contrat/{id}',
            data: {
                authenticate: true,
                pageTitle: 'wamasysApp.modeleContrat.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/business/modele-contrat/modele-contrat-detail.html',
                    controller: 'ModeleContratDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('modeleContrat');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'ModeleContrat', function($stateParams, ModeleContrat) {
                    return ModeleContrat.get({id : $stateParams.id});
                }]
            }
        })

        .state('modele-contrat.liste-contrat.new', {
            url: '/new',
            data: {
                authorities: ['ROLE_AREA_CONFIGURATION']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/modele-contrat/modele-contrat-dialog.html',
                    controller: 'ModeleContratDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                libelle: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('modele-contrat.liste-contrat', null, { reload: true });
                }, function() {
                    $state.go('modele-contrat.liste-contrat');
                });
            }]
        })
        .state('modele-contrat.edit', {
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_AREA_CONFIGURATION']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/modele-contrat/modele-contrat-dialog.html',
                    controller: 'ModeleContratDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ModeleContrat', function(ModeleContrat) {
                            return ModeleContrat.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('modele-contrat', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('modele-contrat.delete', {
            parent: 'modele-contrat',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_AREA_CONFIGURATION']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/modele-contrat/modele-contrat-delete-dialog.html',
                    controller: 'ModeleContratDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ModeleContrat', function(ModeleContrat) {
                            return ModeleContrat.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('modele-contrat', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
