(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('CollecteurDetailController', CollecteurDetailController);

    CollecteurDetailController.$inject = ['$scope', '$filter','$rootScope', '$stateParams', 'entity', 'User', 'CollectionListe'];

    function CollecteurDetailController($scope,$filter, $rootScope, $stateParams, entity, User, CollectionListe) {
        var vm = this;
        vm.user = entity;
        vm.reportCollectionList = [];
        vm.load = function (login) {
        	User.get({login: login}, function(result) {
                vm.user = result;
            });
        };
        vm.aucunResultatTrouve = false;
        
        vm.searchCollecteur = function (){
        	
			var dateDebutFiltre = $scope.date.startDate.format("YYYY-MM-DD");
			var dateFinFiltre=$scope.date.endDate.format("YYYY-MM-DD");
			CollectionListe.reportCollectionListeByCollecteur(
						{idCollecteur:vm.user.id,
						fromDate: dateDebutFiltre,
						toDate:dateFinFiltre}, 
						
						function (result){
							if(result && result.length >0){
								vm.aucunResultatTrouve = false;
								vm.reportCollectionList = result;
							}else{
								vm.aucunResultatTrouve = true;
								vm.reportCollectionList = [];
							}
						});
			
        }

        var unsubscribe = $rootScope.$on('wamasysApp:collecteurUpdate', function(event, result) {
            vm.user = result;
        });
        
        $scope.$on('$destroy', unsubscribe);

        
        $scope.date = {
                startDate: moment().subtract(1, "month"),
                endDate: moment()
            };

		      
		 $scope.openRangeDateTimePicker = function() {
					$('#daterange1').data('daterangepicker').toggle();
			}
		

		    $scope.opts = {
		    		eventHandlers:{'apply.daterangepicker':function(ev) {
		    		//	var dateDebutFiltredd =$scope.date.startDate.format("yyyy-MM-dd");
		    		//	var dateDesdfsdfbutFiltredd =new Date($scope.date.startDate);
		    		
		    			
		    			}
		    		}
		    };
	
    }
})();
