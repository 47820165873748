(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('IOmobileMoneyController', IOmobileMoneyController);

    IOmobileMoneyController.$inject = ['$scope', '$state', '$filter', 'ParseLinks', 'AlertService', 'Importation','Contrat'];

    function IOmobileMoneyController ($scope, $state, $filter,  ParseLinks, AlertService, Importation,Contrat) {
        var vm = this;
     
        

    	vm.download = function(){
    		Contrat.downloadImpayeMobileMoney( {}
//        		fromDate:$filter('date')(new Date(vm.date.startDate), "yyyy-MM-dd"),
//        		toDate:$filter('date')(new Date(vm.date.endDate), "yyyy-MM-dd")}
    			,function (content){
    		      var url = URL.createObjectURL(content.response);
    		      var a = document.createElement('a');
    		      a.href = url;
    		      a.download = content.fileName;
    		      a.target = '_blank';
    		      a.click();
    		}
    				
    		);
    	}
    	
		 //Boite de dialog d'attente.
			var $dialog = $(
					'<div class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true" style="padding-top:15%; overflow-y:visible;">' +
					'<div class="modal-dialog modal-m">' +
					'<div class="modal-content">' +
						'<div class="modal-header"><h3 style="margin:0;"></h3></div>' +
						'<div class="modal-body">' +
							'<div class="progress progress-striped active" style="margin-bottom:0;"><div class="progress-bar" style="width: 100%"></div></div>' +
						'</div>' +
					'</div></div></div>');
	
			var options = {};
			var message = 'Please wait ! Action in progress.';
			var settings = $.extend({
				dialogSize: 'm',
				progressType: '',
				onHide: null // This callback runs after the dialog was hidden
			}, options);
			$dialog.find('.modal-dialog').attr('class', 'modal-dialog').addClass('modal-' + settings.dialogSize);
			$dialog.find('.progress-bar').attr('class', 'progress-bar');
			$dialog.find('h3').text(message);
			
			
		
		
		
         vm.importCreditNote = function(){
         	 vm.businessAlert = null;
             vm.businessInfo = null;
             var file = $scope.fileToImportCreditNote;
             var fd = new FormData();
             fd.append('file', file);

			 $dialog.modal();
             Importation.importPaiementMobileMoney({},fd, onSaveSuccess, onSaveError);
             $scope.fileToImportCreditNote = '';
             
          };

          var onSaveSuccess = function (result) {
			  $dialog.modal('hide');
              vm.businessInfo = "Les paiements ont été correctement enregistrés";
              $scope.fileToImportCreditNote = '';
           //   $scope.$emit('wamasysApp:importUpdate', result);
          };

          var onSaveError = function (httpResponse) {
				$dialog.modal('hide');
              $scope.fileToImportCreditNote = '';
              var errorHeader = httpResponse.headers('X-wamasysApp-error');
//              var entityKey = httpResponse.headers('X-wamasysApp-params');
              if(errorHeader){
             	 if(errorHeader.search("error.business.")>-1){
                      vm.businessAlert =  $filter('translate')(errorHeader);
             	 }else{
                      vm.businessAlert = errorHeader.replace("error.", "");
             	 }
              }
              
              angular.copy( $scope.fileToUploadGPSCoordonnees);
              angular.element("input[type='file']").val(null);
              
              vm.isSaving = false;
          };
    }
})();
