(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('PrintContratDialogController', PrintContratDialogController);

    PrintContratDialogController.$inject = ['$scope', '$filter', '$stateParams', '$uibModalInstance', 'entity', 'Factures'];

    function PrintContratDialogController ($scope, $filter, $stateParams, $uibModalInstance, entity, Factures) {
        var vm = this;
        vm.idContrat = entity.id;

		var $dialog = $(
				'<div class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true" style="padding-top:15%; overflow-y:visible;">' +
				'<div class="modal-dialog modal-m">' +
				'<div class="modal-content">' +
					'<div class="modal-header"><h3 style="margin:0;"></h3></div>' +
					'<div class="modal-body">' +
						'<div class="progress progress-striped active" style="margin-bottom:0;"><div class="progress-bar" style="width: 100%"></div></div>' +
					'</div>' +
				'</div></div></div>');
		
        var onPrintSuccess = function (result) {
			$dialog.modal('hide');
            $uibModalInstance.close();
            vm.isPrinting = false;
		      var url = URL.createObjectURL(result.response);
		      var a = document.createElement('a');
		      a.href = url;
		      a.download = result.fileName;
		      a.target = '_blank';
		      a.click();

        };

        var onPrintError = function () {
			$dialog.modal('hide');
            vm.isPrinting = false;
        };

        vm.print = function () {



			var options = {};
			var message = 'Please wait ! Action in progress.';
			var settings = $.extend({
				dialogSize: 'm',
				progressType: '',
				onHide: null // This callback runs after the dialog was hidden
			}, options);
			$dialog.find('.modal-dialog').attr('class', 'modal-dialog').addClass('modal-' + settings.dialogSize);
			$dialog.find('.progress-bar').attr('class', 'progress-bar');
			$dialog.find('h3').text(message);
			$dialog.modal();
			
        	Factures.generer({
            	id:vm.idContrat
//            	,
//            	dateFrom:$filter('date')(vm.dateFrom, "yyyy-MM-dd"),
//        		dateTo:$filter('date')(vm.dateTo, "yyyy-MM-dd")
            }, onPrintSuccess, onPrintError);
            
        }; 

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

//        vm.datePickerOpenStatus = {};
////        vm.datePickerOpenStatus.dateFrom = false;
//        vm.datePickerOpenStatus.dateTo = false;
//
//        vm.openCalendar = function(date) {
//            vm.datePickerOpenStatus[date] = true;
//        };

    }
})();
