(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('compteur', {
            parent: 'entity',
            url: '/compteur',
            data: {
                authorities: ['ROLE_DATA_OPERATOR','ROLE_AREA_MANAGEMENT','ROLE_READ_ONLY'],
                pageTitle: 'wamasysApp.individualMeter.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/business/individual-meter/individual-meters.html'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('individualMeter');
                    $translatePartialLoader.addPart('cluster');
                    $translatePartialLoader.addPart('etatCluster');
                    $translatePartialLoader.addPart('etatReleveCompteur');
                    $translatePartialLoader.addPart('bulkMeter');
                    $translatePartialLoader.addPart('etatMeter');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        
        
        .state('compteur.individual-meter',{
			url : '/individual-meter?page&sort&search&isIOT&text&cluster&etat&modelMeter',
			views : {

				'panneau@content' : {
	                templateUrl: 'app/business/individual-meter/individual-meters-liste.html',	
	                controller: 'IndividualMeterController',
	                controllerAs: 'vm'
	            }
			}
		})
        .state('compteur.bulk-meter',{
			url : '/bulk-meter',
			views : {

				'panneau@content' : {
					templateUrl : 'app/business/bulk-meter/bulk-meters.html',
					controller : 'BulkMeterController',
					controllerAs : 'vm'
				}
			}
		})
		.state('compteur.cluster',{
			url : '/cluster',
			views : {
				'panneau@content' : {
					templateUrl : 'app/business/cluster/clusters.html',

					controller : 'ClusterController',
					controllerAs : 'vm'
					}
				}
			})
        .state('individual-meter-detail', {
            parent: 'entity',
            url: '/individual-meter/{id}?page&sort&search',
            data: {
                authorities: ['ROLE_DATA_OPERATOR','ROLE_AREA_MANAGEMENT','ROLE_READ_ONLY'],
                pageTitle: 'wamasysApp.individualMeter.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/business/individual-meter/individual-meter-detail.html',
                    controller: 'IndividualMeterDetailController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
            	pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('individualMeter');
                    $translatePartialLoader.addPart('etatMeter');
                    $translatePartialLoader.addPart('collectionListe');
                    $translatePartialLoader.addPart('etatCollectionList');
                    $translatePartialLoader.addPart('etatReleveCompteur');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'IndividualMeter', function($stateParams, IndividualMeter) {
                    return IndividualMeter.get({id : $stateParams.id});
                }]
            }
        })
        .state('compteur.individual-meter.new', {
            url: '/new',
            data: {
                authorities: ['ROLE_AREA_MANAGEMENT','ROLE_DATA_OPERATOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/individual-meter/individual-meter-dialog.html',
                    controller: 'IndividualMeterDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                numeroSerie: null,
                                dateCreation: null,
                                type: null,
                                valeurInitiale: null,
                                etat: null,
                                dateSuppression: null,
                                runningOrder: null,
                                longitude: null,
                                latitude: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('compteur.individual-meter', null, { reload: true });
                }, function() {
                    $state.go('compteur.individual-meter');
                });
            }]
        })
        .state('compteur.individual-meter.edit', {
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_AREA_MANAGEMENT','ROLE_DATA_OPERATOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/individual-meter/individual-meter-dialog.html',
                    controller: 'IndividualMeterDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['IndividualMeter', function(IndividualMeter) {
                            return IndividualMeter.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('compteur.individual-meter', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('compteur.individual-meter.delete', {
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_AREA_MANAGEMENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/individual-meter/individual-meter-delete-dialog.html',
                    controller: 'IndividualMeterDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['IndividualMeter', function(IndividualMeter) {
                            return IndividualMeter.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('compteur.individual-meter', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        
        .state('individual-meter-detail.addreading', {
            url: '/individual-meter/{id}/addreading',
            data: {
                authorities: ['ROLE_AREA_MANAGEMENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/individual-meter/individual-meter-addreading-dialog.html',
                    controller: 'IndividualMeterAddReadingController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['IndividualMeter', function(IndividualMeter) {
                            return IndividualMeter.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('individual-meter-detail', {id : $stateParams.id}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        
        ;
    }

})();
