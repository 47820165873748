(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('EventFormController', EventFormController);

    EventFormController.$inject = ['$scope', '$state', 'EventForm', 'ParseLinks', '$filter','AlertService','EventFormCategory', 'pagingParams', 'paginationConstants'];

    function EventFormController ($scope, $state, EventForm, ParseLinks, $filter,AlertService,EventFormCategory,pagingParams, paginationConstants) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.updateSubCategory = updateSubCategory;
        vm.searchText = $state.params.searchText;
 
     	 vm.date = {
                 startDate: $state.params.startDate?new Date($state.params.startDate):null,
                 endDate: $state.params.endDate?new Date($state.params.endDate):null

         }
         if($state.params.categoryId){
        	 vm.category = {id:$state.params.categoryId};
        	  vm.updateSubCategory ();
         }
         if($state.params.subCategoryId){
        	 vm.subCategory = {id:$state.params.subCategoryId};
         }
     	 
        vm.listSubCategory = [];
     	 
        EventFormCategory.query({
            page: 0,
            size: 100
        }, onSuccess, onError);
        
        function onSuccess(data, headers) {
        	vm.listCategory = data;
        }
        
        function onError(error) {
            AlertService.error(error.data.message);
        }
        
        function updateSubCategory(){
       	 
            EventFormCategory.getListSubCategory({id: vm.category.id}, function(result) {
            	vm.listSubCategory =   result;

           });
        }

        vm.loadAll();
        
        function loadAll () {
            vm.currentSearch=true;
            EventForm.query({
            	searchText : vm.searchText,
            	categoryId: vm.category?vm.category.id:null,
            	subCategoryId: vm.subCategory?vm.subCategory.id:null,
            	fromDate:vm.date?$filter('date')(vm.date.startDate, "yyyy-MM-dd"):null,
            	toDate:vm.date?$filter('date')(vm.date.endDate, "yyyy-MM-dd"):null,
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.eventForms = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
            	searchText : vm.searchText,
            	categoryId: vm.category?vm.category.id:null,
            	subCategoryId: vm.subCategory?vm.subCategory.id:null,
            	startDate:vm.date?$filter('date')(vm.date.startDate, "yyyy-MM-dd"):null,
            	endDate:vm.date?$filter('date')(vm.date.endDate, "yyyy-MM-dd"):null
            });
        }

        function search () {
      
            vm.links = null;
            vm.page = 1;
            vm.predicate = '';
            vm.reverse = false;
            vm.currentSearch=true;
            vm.transition();
        }
        
        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true; 
        	vm.searchText =null;
            vm.category= null;
            vm.subCategory= null;
            vm.date.startDate= null;
            vm.date.endDate= null;
            currentSearch : vm.currentSearch= null;
            vm.transition();
        }
        
    }
})();
