(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$rootScope', '$state', '$timeout', 'Auth','$scope', 'AlertService','Principal', 'LoginService','ReportingDashboard','RedmineService','pagingParams','paginationConstants','ParseLinks'];

    function HomeController ($rootScope,$state, $timeout, Auth,$scope,AlertService, Principal, LoginService,ReportingDashboard,RedmineService,pagingParams, paginationConstants,ParseLinks) {
        var vm = this;
        
        vm.idDashboard = "d0f660b0-c7ac-11e8-8320-c93d887536a8";
	    vm.filtreagence = true;
	    vm.periodKibana = true;
	    
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        $scope.$on('authenticationSuccess', function() {
            getAccount();

        	vm.loadAll();
            loadGraphic();
        	
        });

        getAccount();
        
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                
//                if( vm.isAuthenticated ){
//                	vm.myIssues();
//                	                	
//                }
        
            });
        }
   

        vm.transition = transition;
        vm.loadAll = loadAll;

    	vm.loadAll();

        
        function loadAll() {
        	vm.issuefilter = pagingParams.issuefilter;
        	if(pagingParams.issuefilter == 'myissues'){
        		vm.indextab = 0;
        		 RedmineService.myIssues({
 	                page: pagingParams.page - 1,
 	                size: 10}, onSuccess, onError);
        	}
        }
        vm.myIssues = function () {
        	vm.issuefilter = 'myissues';
        	loadPage(1);
        }

        
        function onSuccess(data, headers) {
//            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = data.total_count;
            vm.queryCount = vm.totalItems;
            vm.listIssues = data;
//            vm.page = pagingParams.offset;
          vm.page = (data.offset/data.limit)+1;
            
        }
        
        function onError(error) {
        	if(error.data.message)
        		AlertService.error(error.data.message);
        }
        
        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }
        
        function transition () {
	        $state.transitionTo($state.$current, {
	            page: vm.page,
	            issuefilter: vm.issuefilter
	        	});
        }
        
        /////////////////////////////////
        


        
        function loadGraphic() {
            vm.reporting =  ReportingDashboard.get();
        	 if( vm.reporting &&  vm.reporting.$promise){
             	
             	vm.reporting.$promise.then(function(value)  {
             
     	        	var billingLabels = [];
     	        	var billingData = [];
     	
     	        	for (var i = 0 ; i < vm.reporting.billing.aggregations.aggregation.buckets.length; i++) {
     	        		billingLabels.push( vm.reporting.billing.aggregations.aggregation.buckets[i].key_as_string);
     	        		billingData.push( parseFloat(vm.reporting.billing.aggregations.aggregation.buckets[i].balance.value).toFixed( 2 ));
     	        	}
     	
     	        	var paymentLabels = [];
     	        	var paymentData = [];
     	        	for (var i = 0 ; i < vm.reporting.payment.aggregations.aggregation.buckets.length; i++) {
     	        		paymentLabels.push( vm.reporting.payment.aggregations.aggregation.buckets[i].key_as_string);
     	        		paymentData.push( parseFloat(vm.reporting.payment.aggregations.aggregation.buckets[i].balance.value).toFixed( 2 ));
     	        	}
     	        	
     	            $scope.billingLabels = billingLabels;
     	            $scope.billingData = [billingData,paymentData];
     	            $scope.billingSeries = ['Billing','Payments'];
     	            
     	            $scope.paymentLabels = paymentLabels;
     	            $scope.paymentData = [billingData,paymentData];
     	            
     	        	var statLabels = [];
     	        	var statData = [];
     	        	for (var i = 0 ; i < vm.reporting.statistic.aggregations.aggregation.buckets.length; i++) {
     	        		statLabels.push( vm.reporting.statistic.aggregations.aggregation.buckets[i].key_as_string);
     	        		statData.push( parseFloat(vm.reporting.statistic.aggregations.aggregation.buckets[i].maxBalance.value).toFixed( 2 ));
     	        	}
     	            $scope.statLabels = statLabels;
     	            $scope.statData = [statData];

     	        	var balanceLabels = [];
     	        	var balanceData = [];
     	        	for (var i = 0 ; i < vm.reporting.balance.aggregations.aggregation.buckets.length; i++) {
     	        		balanceLabels.push( vm.reporting.balance.aggregations.aggregation.buckets[i].key_as_string);
     	        		balanceData.push( parseFloat(vm.reporting.balance.aggregations.aggregation.buckets[i].maxBalance.value).toFixed( 2 ));
     	        	}
     	            $scope.balanceLabels = balanceLabels;
     	            $scope.balanceData = [balanceData];
     	            
             	});
             }
        }


        loadGraphic();
      
        $scope.onClick = function (points, evt) {
          console.log(points, evt);
        };
        $scope.datasetOverride = [{ yAxisID: 'y-axis-1' }, { yAxisID: 'y-axis-2' }];
        $scope.options = {
          scales: {
            yAxes: [
              {
                id: 'y-axis-1',
                type: 'linear',
                display: true,
                position: 'left'
              }
            ]
          }
        };

        
        $scope.labels3 = ["January", "February", "March", "April", "May", "June", "July"];
        $scope.series3 = ['Series A'];
        $scope.data3 = [
          [90, 95, 93, 97, 100, 98, 95]
        ];
        $scope.onClick = function (points, evt) {
          console.log(points, evt);
        };
        $scope.datasetOverride = [{ yAxisID: 'y-axis-1' }, { yAxisID: 'y-axis-2' }];
        $scope.options3 = {
          scales: {
            yAxes: [
              {
                id: 'y-axis-1',
                type: 'linear',
                display: true,
                position: 'left',
                min:0
              }
            ]
          }
        };
        
        ///
        ///
        $scope.labels2 = ["Download Sales", "In-Store Sales", "Mail-Order Sales"];
        $scope.data2 = [80,20];
        $scope.options2 = {
                circumference: 3.14,
                rotation: -3.14
              };
        
        /////////////////////////////////
       
	    //Code utilisé pour le login
	    vm.textErreur = 'login.messages.error.authentication';
	    vm.authenticationError = false;
	    vm.cancel = cancel;
	    vm.credentials = {};
	    vm.login = login;
	    vm.password = null;
	    vm.rememberMe = true;
	    vm.requestResetPassword = requestResetPassword;
	    vm.username = null;
	
	    $timeout(function (){angular.element('[ng-model="vm.username"]').focus();});
	
	    function cancel () {
	        vm.credentials = {
	            username: null,
	            password: null,
	            rememberMe: true
	        };
	        vm.authenticationError = false;
	    }
	
	    function login (event) {
	        event.preventDefault();
	        Auth.login({
	            username: vm.username,
	            password: vm.password,
	            rememberMe: vm.rememberMe
	        }).then(function () {
	            vm.authenticationError = false;
	            // If we're redirected to login, our
	            // previousState is already set in the authExpiredInterceptor. When login succesful go to stored state
	            if ($rootScope.redirected && $rootScope.previousStateName) {
	                $state.go($rootScope.previousStateName, $rootScope.previousStateParams);
	                $rootScope.redirected = false;
	            } else {
	                $rootScope.$broadcast('authenticationSuccess');
	            }
	        }).catch(function (erreur) {
	        	erreur.headers('X-wamasysApp-error');
	        	vm.textErreur = erreur.headers('X-wamasysApp-error');
	            vm.authenticationError = true;
	        });
	    }
	
	    function requestResetPassword () {
	        $state.go('requestReset');
	    }
    }
    
})();
