(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('InformationContratController', InformationContratController);

    InformationContratController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Contrat','ContractType','ContractProfil','PortefeuilleContrat','Agence','Principal'];

    function InformationContratController($scope, $rootScope, $stateParams, entity, Contrat,ContractType,ContractProfil,PortefeuilleContrat,Agence,Principal) {
        var vm = this;
        vm.contrat = entity;
        
        Principal.identity().then(function(account) {
        	if(account){
        		vm.onlinePaymentConf =  Agence.getOnlinePaymentConf({idArea:account.agence.id});
            
        	}
        });
        
        

        ContractType.query({}, function(result) {
        	vm.contractTypes = result;
        	entity.$promise.then(function(value) {
	        	if(vm.contrat.contractType){
	        		var  fnctIdEqual = function(element) { return element.id === vm.contrat.contractType.id};
	        		var index = vm.contractTypes.findIndex(fnctIdEqual);
	        		if(index === -1 ){
	        			vm.contractTypes.push(vm.contrat.contractType);
	        		}
	        	}
        	});
        });
        ContractProfil.query({}, function(result) {
        	vm.contractProfils = result;
        	entity.$promise.then(function(value) {
	        	if(vm.contrat.contractProfil){
	        		var  fnctIdEqual = function(element) {return element.id === vm.contrat.contractProfil.id};
	        		var index = vm.contractProfils.findIndex(fnctIdEqual);
	        		if(index === -1 ){
	        			vm.contractProfils.push(vm.contrat.contractProfil);
	        		}
	        	}
        	});
        });
//        vm.contractProfils = ContractProfil.query();
        
        vm.portefeuillecontrats = PortefeuilleContrat.query({ offset: 0,size: 1000});
        
        
        vm.onlinePaymentList = Contrat.getListOnlinePayment({idContrat: $stateParams.id});


        var unsubscribe = $rootScope.$on('wamasysApp:contratUpdate', function(event, result) {
            vm.contrat = result;
        });
        $scope.$on('$destroy', unsubscribe);

        
        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:contratUpdate', result);
         //   $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.contrat.id !== null) {
                Contrat.update(vm.contrat, onSaveSuccess, onSaveError);
            } else {
                Contrat.save(vm.contrat, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        
    }
})();
