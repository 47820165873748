(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('BillingPortefeuilleDialogController', BillingPortefeuilleDialogController );

    BillingPortefeuilleDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity',  'Billing', 'UserSearch'];

    function BillingPortefeuilleDialogController  ($scope, $stateParams, $uibModalInstance, entity, Billing, UserSearch) {
        var vm = this;
        vm.portefeuilleContrat = entity;
   //     vm.dateFacturation = Date.now() ;
        
		var $dialog = $(
				'<div class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true" style="padding-top:15%; overflow-y:visible;">' +
				'<div class="modal-dialog modal-m">' +
				'<div class="modal-content">' +
					'<div class="modal-header"><h3 style="margin:0;"></h3></div>' +
					'<div class="modal-body">' +
						'<div class="progress progress-striped active" style="margin-bottom:0;"><div class="progress-bar" style="width: 100%"></div></div>' +
					'</div>' +
				'</div></div></div>');
		
        var onSaveSuccess = function (result) {
			$dialog.modal('hide');
            $scope.$emit('wamasysApp:billingContractUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
			$dialog.modal('hide');
            vm.isSaving = false;
        };

        vm.save = function () {



			var options = {};
			var message = 'Please wait ! Action in progress.';
			var settings = $.extend({
				dialogSize: 'm',
				progressType: '',
				onHide: null // This callback runs after the dialog was hidden
			}, options);
			$dialog.find('.modal-dialog').attr('class', 'modal-dialog').addClass('modal-' + settings.dialogSize);
			$dialog.find('.progress-bar').attr('class', 'progress-bar');
			$dialog.find('h3').text(message);
			$dialog.modal();
			
            vm.isSaving = true;
            Billing.createForPortfolio(
            		{
            			listIdPortefeuilleContrat:[vm.portefeuilleContrat.id]
            		}
            		, onSaveSuccess, onSaveError);
        
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

    //    vm.datePickerOpenStatus = {};
    //    vm.datePickerOpenStatus.dateFacturation = false;

  //      vm.openCalendar = function(date) {
  //          vm.datePickerOpenStatus[date] = true;
  //      };
    }
})();
