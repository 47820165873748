'use strict';

angular.module('wamasysApp').directive('fileModel',[ '$parse', function ($parse) { 
    return {
        restrict: 'EA',
		scope: {
			setFileData: "&"
		},
        link: function(scope, element, attrs) {
           var model = $parse(attrs['fileModel']);
           var modelSetter = model.assign;
           
           element.bind('change', function(){
              scope.$apply(function(){
                 modelSetter(scope, element[0].files[0]);
				 scope.setFileData({ value: element[0].files[0] });
              });
           });
        }
     };
} ]);