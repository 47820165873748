(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .factory('ContratSearch', ContratSearch);

    ContratSearch.$inject = ['$resource'];

    function ContratSearch($resource) {
        var resourceUrl =  'api/contrats/searchcontrats/';

        return $resource(resourceUrl, {}, {
            'query': { 
            	method: 'GET',
                isArray: true}
        });
    }
})();
