(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ReportingFuiteEauController', ReportingFuiteEauController);

    ReportingFuiteEauController.$inject = ['$scope', '$state', '$filter', 'ParseLinks', 'AlertService', 'ReportingFuiteEau'];

    function ReportingFuiteEauController ($scope, $state, $filter,  ParseLinks, AlertService, ReportingFuiteEau) {
        var vm = this;
        vm.search=search;
        vm.date = {
                startDate: $state.params.startDate?new Date($state.params.startDate):null,
                endDate: $state.params.endDate?new Date($state.params.endDate):null

        };


        function onSuccess(data, headers) {
            vm.reportConsoEau = data;
        }
        function onError(error) {
            AlertService.error(error.data.message);
        }
        
        function search () {
            if ( vm.date.startDate && vm.date.endDate) {
            

	        	ReportingFuiteEau.query({
	        		fromDate:$filter('date')(new Date(vm.date.startDate), "yyyy-MM-dd"),
	        		toDate:$filter('date')(new Date(vm.date.endDate), "yyyy-MM-dd")
	            }, onSuccess, onError);
            }

        }

	      
		 $scope.openRangeDateTimePicker = function() {
					$('#daterange1').data('daterangepicker').toggle();
			}
		


    }
})();
