(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('CasNonReleveDeleteController',CasNonReleveDeleteController);

    CasNonReleveDeleteController.$inject = ['$uibModalInstance', 'entity', 'CasNonReleve'];

    function CasNonReleveDeleteController($uibModalInstance, entity, CasNonReleve) {
        var vm = this;
        vm.casNonReleve = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            CasNonReleve.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
