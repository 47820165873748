(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ContractTypeDetailController', ContractTypeDetailController);

    ContractTypeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'ContractType'];

    function ContractTypeDetailController($scope, $rootScope, $stateParams, entity, ContractType) {
        var vm = this;
        vm.contractType = entity;
        vm.load = function (id) {
            ContractType.get({id: id}, function(result) {
                vm.contractType = result;
            });
        };
        var unsubscribe = $rootScope.$on('wamasysApp:contractTypeUpdate', function(event, result) {
            vm.contractType = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
