(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('HistoPaiementsContratController', HistoPaiementsContratController);

    HistoPaiementsContratController.$inject = ['$scope', '$state','$filter', '$rootScope', '$stateParams', 'entity', 'Contrat','CollectionListe','ServiceVendu','LigneContrat', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function HistoPaiementsContratController($scope, $state,$filter, $rootScope, $stateParams, entity, Contrat,CollectionListe,ServiceVendu,LigneContrat, ParseLinks, AlertService, pagingParams, paginationConstants) {
        var vm = this;
        vm.contrat = entity;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;

        vm.clear = clear;
        vm.search = search;
        vm.ligneContrats = LigneContrat.findByContrat({idContrat:$stateParams.id, offset: 0,size: 1000});
        
        if($state.params.ligneContrat){
        	vm.ligneContrat = {
        			id:$state.params.ligneContrat
        	}
        }
        
//        if($state.params.serviceVendu){
//        	vm.serviceVendu = {
//        			id:$state.params.serviceVendu
//        	}
//        }
        
	   	 vm.date = {
	             startDate: $state.params.startDate?new Date($state.params.startDate):null,
	             endDate: $state.params.endDate?new Date($state.params.endDate):null
	
	     }
   	 
        vm.load = function () {
            Contrat.get({id: $stateParams.id}, function(result) {
                vm.contrat = result;
            });
            if (vm.ligneContrat  || vm.date.startDate || vm.date.endDate ) {
                vm.currentSearch=true;
	            CollectionListe.collectionListeValideByContrat(
	            		{id : $stateParams.id,
	            		page: pagingParams.page - 1,
	                     size: paginationConstants.itemsPerPage,
	                     sort: sort(),
	                     ligneContrat:$state.params.ligneContrat,
	             		fromDate:$filter('date')(vm.date.startDate, "yyyy-MM-dd"),
	            		toDate:$filter('date')(vm.date.endDate, "yyyy-MM-dd")
	            		}, onSuccess, onError);
            }else{

                vm.currentSearch=false;
	            CollectionListe.collectionListeValideByContrat(
	            	{id : $stateParams.id,
		            page: pagingParams.page - 1,
		            size: paginationConstants.itemsPerPage,
		            sort: sort()
	            }, onSuccess, onError);
            }

            function sort() {
//                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
//                if (vm.predicate !== 'id') {
//                    result.push('id');
//                }
                var result = ['datePaiement,desc','id,desc'];
                return result;
            }

            function onSuccess(result, headers) {
    			vm.collectionListeValide =  result;
    			vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.page = pagingParams.page;
                
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        };
        
        vm.load();

        vm.transition = function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                ligneContrat: vm.ligneContrat?vm.ligneContrat.id:null,
                startDate: vm.date.startDate,
                endDate: vm.date.endDate,
                id:$stateParams.id,
                currentSearch : vm.currentSearch
            });
        };

        vm.calcultotalPaye = function  (listPaymentReceived){
        	var totalPaye = 0;
        	
        	listPaymentReceived.forEach(function(paymentReceived) {
        		totalPaye += paymentReceived.paidInclTax;
        	});

        	return totalPaye;
        }

        vm.calcultotalAvoirAbandonDette = function  (listAvoirAbandonDette){
        	var total = 0;
        	
        	listAvoirAbandonDette.forEach(function(avoirAbandonDette) {
        		if(avoirAbandonDette.type === 'CREDIT_NOTE' || avoirAbandonDette.type === 'DEBT_WAIVER' ){

            		total += avoirAbandonDette.montantTtc;
        		}
        	});

        	return total;
        }
        
        vm.detailListPaymentReceived ;
        vm.modalDetailPayment = function  (listPaymentReceived){
            vm.detailListPaymentReceived = listPaymentReceived;
        	$('#modalPaymentReceived').modal();
        	
        }

        function search () {

            vm.links = null;
            vm.page = 1;
            vm.predicate = '';
            vm.reverse = false;
            vm.currentSearch=true;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true; 
            
    		vm.ligneContrat = null;
            vm.date.startDate= null;
            vm.date.endDate= null;
            currentSearch : vm.currentSearch= null;
            vm.transition();
        }

    }
})();
