(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('ReportingCollectionList', ReportingCollectionList);

    ReportingCollectionList.$inject = ['$resource'];

    function ReportingCollectionList ($resource) {
        var resourceUrl =  'api/reporting-collection-list/search';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
        
    }
})();