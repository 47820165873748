(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('ReportingGlobal', ReportingGlobal);

    ReportingGlobal.$inject = ['$resource'];

    function ReportingGlobal ($resource) {
        var resourceUrl =  'api/reporting-global/search';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false}
        });
        
    }
    
})();
