(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('IndividualMeterDetailController', IndividualMeterDetailController);

    IndividualMeterDetailController.$inject = ['$scope','$state', '$rootScope', '$stateParams', 'entity', 'IndividualMeter', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'Cluster', 'ReleveCompteur'];

    function IndividualMeterDetailController($scope, $state,  $rootScope, $stateParams, entity, IndividualMeter, ParseLinks, AlertService, pagingParams, paginationConstants, Cluster, ReleveCompteur) {
        var vm = this;
        vm.individualMeter = entity;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.load = function () {
           ReleveCompteur.byIndividualMeter({
        	   id:$stateParams.id,                
        	   page: pagingParams.page - 1,
               size: paginationConstants.itemsPerPage
           }, function(result, headers) {
        	   vm.listeCollectionList  = result;
               vm.links = ParseLinks.parse(headers('link'));
               vm.totalItems = headers('X-Total-Count');
               vm.queryCount = vm.totalItems;
               vm.page = pagingParams.page;
            },  function (error) {
                AlertService.error(error.data.message);
            });
           

        };
        vm.load();
        var unsubscribe = $rootScope.$on('wamasysApp:individualMeterUpdate', function(event, result) {
            vm.individualMeter = result;
        });
        $scope.$on('$destroy', unsubscribe);

       
        vm.transition = function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                id:$stateParams.id,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
            });
        };
    }
})();
