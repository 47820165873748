(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ContratStandbyController',ContratStandbyController);

    ContratStandbyController.$inject = ['$uibModalInstance', 'entity', 'LigneContrat'];

    function ContratStandbyController($uibModalInstance, entity, LigneContrat) {
        var vm = this;
        vm.ligneContrat = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmStandBy = function (id) {
        	LigneContrat.standBy({idLigneContrat: id},{},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
