(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('BulkMeterDialogController', BulkMeterDialogController);

    BulkMeterDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'BulkMeter', 'UserSearch','ModelMeter','WaterNetwork'];

    function BulkMeterDialogController ($scope, $stateParams, $uibModalInstance, entity, BulkMeter, UserSearch,ModelMeter,WaterNetwork) {
        var vm = this;
        vm.bulkMeter = entity;
        vm.users = UserSearch.searchCollecteur();
        vm.modelMeters = ModelMeter.query({ offset: 0,size: 1000});
        vm.waterNetworks = WaterNetwork.queryFilterArea();
        vm.load = function(id) {
            BulkMeter.get({id : id}, function(result) {
                vm.bulkMeter = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:bulkMeterUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.bulkMeter.id !== null) {
                BulkMeter.update(vm.bulkMeter, onSaveSuccess, onSaveError);
            } else {
                BulkMeter.save(vm.bulkMeter, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateSuppression = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
