(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('HistoConsoContratController', HistoConsoContratController);

    HistoConsoContratController.$inject = ['$scope', '$state', '$rootScope', '$stateParams', '$uibModalInstance',  'ConsommationEauContrat', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function HistoConsoContratController($scope, $state, $rootScope, $stateParams,$uibModalInstance,  ConsommationEauContrat, ParseLinks, AlertService, pagingParams, paginationConstants) {
        var vm = this;
//        vm.contrat = entity;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.load = function () {
//            Contrat.get({id: $stateParams.id}, function(result) {
//                vm.contrat = result;
//            });
            ConsommationEauContrat.byContrat({
               id:$stateParams.id,                
        	   page: pagingParams.page - 1,
               size: paginationConstants.itemsPerPage
               }, function(result, headers) {
          	   vm.listeConsommationEau = result;
               vm.links = ParseLinks.parse(headers('link'));
               vm.totalItems = headers('X-Total-Count');
               vm.queryCount = vm.totalItems;
               vm.page = pagingParams.page;
              },  function (error) {
                  AlertService.error(error.data.message);
              });
        };
        vm.load();

        vm.transition = function transition () {
            $uibModalInstance.close({
                page: vm.page,
                id:$stateParams.id,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
            });
        };


        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        
    }
})();
