(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ContratCreateTicketController', ContratCreateTicketController);

    ContratCreateTicketController.$inject = ['$scope', '$stateParams', '$uibModalInstance',   'RedmineService','UserSearch'];

    function ContratCreateTicketController ($scope, $stateParams, $uibModalInstance,  RedmineService,UserSearch) {
        var vm = this;
//        vm.RedmineService = {bulkMeter: vm.bulkMeter,isCorrection: true,lastModifiedDate:Date.now() };
        vm.users = UserSearch.searchUserArea({ offset: 0,size: 1000});
        vm.contractRef=  $stateParams.ref;
        
        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:redmineUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
//            customFields :;
            vm.redmineIssue.custom_fields =  [{"value": vm.contractRef,"id":1}];
            RedmineService.createIssue(vm.redmineIssue, onSaveSuccess, onSaveError);
          
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateSuppression = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
