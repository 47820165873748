(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('IndividualMeterAddReadingController', IndividualMeterAddReadingController);

    IndividualMeterAddReadingController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'IndividualMeter','ReleveCompteur'];

    function IndividualMeterAddReadingController ($scope, $stateParams, $uibModalInstance, entity, IndividualMeter,ReleveCompteur) {
        var vm = this;
        vm.individualMeter = entity;
        vm.readingIndividualMeter = {individualMeter: vm.individualMeter,releveCompteur:{id:-1},valeurPrecedentReleve: vm.individualMeter.valeurPrecedenteLecture };
        vm.load = function(id) {
            IndividualMeter.get({id : id}, function(result) {
                vm.individualMeter = result;
                vm.readingIndividualMeter.valeurPrecedentReleve = vm.individualMeter.valeurPrecedenteLecture;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:individualMeterUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            vm.readingIndividualMeter.datePrecedentReleveCompteur= vm.individualMeter.datePrecedenteLecture; 
            vm.readingIndividualMeter.valeurPrecedentReleve = vm.individualMeter.valeurPrecedenteLecture;
            ReleveCompteur.addReading(vm.readingIndividualMeter, onSaveSuccess, onSaveError);
          
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.readingDate = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
