(function() {
    'use strict';

//    var srcIFrame= "https://c4d9c071efbd4c70bcb88dc3b740506e.eu-west-1.aws.found.io:9243/app/kibana#/dashboard/";

    
    var mapLeaflet = {
              bindings: {
            	  contrat:'=',
            	  readonly:'@'
                },
                templateUrl: "app/components/map-leaflet/map-leaflet.tpl.html",

              controller: mapLeafletController
    };
    
    mapLeafletController.$inject  = ['$scope', '$rootScope','Principal','$timeout']; 


    function mapLeafletController($scope, $rootScope,Principal,$timeout){
    	var ctrl = this;

       //Nécessaire d'initialiser dans le rootscope par la librairie ui-leaflet.
	   angular.extend($scope, {
	     center: {},
	     markers: {},
	     defaults: {},
	     paths: {},
	     layers: {
        	baselayers: {
                osm: {
                    name: 'OpenStreetMap',
                    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                    type: 'xyz'
                },
                satelite: {
                    name: 'Satelite',
 	                url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
                    type: 'xyz'
                 }
//                ,
//                 googleRoadmap: {
//                     name: 'Google Streets',
//                     layerType: 'ROADMAP',
//                     type: 'google'
//                 }
            }
        },
        events: {
            map: {
                enable: ['contextmenu'],
                logic: 'emit'
            }
        }
	   });

       ctrl.updateCoord = function(){

			         	
       		// On remet à 0 en cas d'erreur sur le champs.
			if (!ctrl.contrat.lat || !ctrl.contrat.lon) {
				return;
			}
       	
       	
       	
	        angular.extend($scope, {
	       	    center: {
	                lat: ctrl.contrat.lat,
	                lng: ctrl.contrat.lon,
	       	        zoom: 10
	       	    },
	            defaults: {
	                scrollWheelZoom: false
	            },
	            markers: {
	            	contrat: {
	                    lat: ctrl.contrat.lat,
	                    lng: ctrl.contrat.lon,
	                    message: ctrl.contrat.referenceContrat  + "<br/> @"+ctrl.contrat.lat + "," +  ctrl.contrat.lon,
//	                    focus: true,
	                    draggable: false,
//	                    icon : {
//	                        iconUrl: 'https://angular-ui.github.io/ui-leaflet/examples/img/100x100_PNG/eiffel100.png',
//	                        iconSize: [80, 80],
//	                        iconAnchor: [40, 80],
//	                        popupAnchor: [0, 0],
//	                        shadowSize: [0, 0],
//	                        shadowAnchor: [0, 0]
//	                    }
	                }
	            }
//	            ,paths: {
//	                p1: {
//	                    color: '#008000',
//	                    weight: 8,
//	                    latlngs: [
//	                        { lat: 51.50, lng: -0.082 },
//	                        { lat: 48.83, lng: 2.37 },
//	                        { lat: 41.91, lng: 12.48 }
//	                    ],
//	                },
//	                p2: {
//		                latlngs: [ 
//			                        { lat: 10.50, lng: -0.082 },
//			                        { lat: 15.45, lng: -5.625 },
//			                        { lat: 18.83, lng: 2.37 },
//			                        { lat: 14.91, lng: 12.48 },
//			                        { lat: 10.91, lng: 5.48 }
//			              ],
//	                    stroke: false,
//	                    fillColor: '#ff69b4',
//	                    message: 'TEST',
//	                    type: 'polygon'
//	                },
//                   pg2: {
//                       weight: 1,
//                       color: '#2e3974',
//                       latlngs: [
//                           [ { lat: 50.50, lng: -0.082 },  { lat: 48.83, lng: 2.37 },{ lat: 44.91, lng: 12.48 } ],
//                           [  { lat: 40.91, lng: 5.48 },  { lat: 48.83, lng: 2.37 }, { lat: 44.91, lng: 12.48 } ]
//                       ],
//	                    message: 'TEST multiPolygon',
//	                    type: 'multiPolygon'
//                   },
//                   r1: {
//	                    latlngs: [{ lat: 20.91, lng: 5.48 }, { lat: 24.91, lng: 12.48 }],
//	                    message: 'TEST rectangle',
//	                    weight: 1,
//	                    type: 'rectangle'
//                   },
//                   c2: {
//	                    weight: 2,
//	                    color: '#ff612f',
//	                    latlngs: {lat: 51.50, lng: 12},
//	                    radius: 10,
//	                    message: 'TEST circleMarker',
//	                    type: 'circleMarker'
//                   },
//	            }
	        })
       }

       $scope.$on('leafletDirectiveMap.contextmenu', function(event, args){
       	if(! ctrl.contrat.lat || ! ctrl.contrat.lon){

          	 	ctrl.contrat.lat = args.leafletEvent.latlng.lat;
          	 	ctrl.contrat.lon = args.leafletEvent.latlng.lng;
               ctrl.updateCoord();
       	}
       });
       
       $scope.centerMap= function(){ 
       	
       	  angular.extend($scope, {
         	    center: {
                     lat: ctrl.contrat.lat,
                     lng: ctrl.contrat.lon,
         	        zoom: 16
         	    }
       	  });
       	
       }
       
       $scope.deleteMarker= function(){ 

         ctrl.contrat.lat = null;
         ctrl.contrat.lon =null;

     	  angular.extend($scope, {	
     		  markers: {}
     	  });
     	
     }  
       $scope.addMarker= function(){ 

	       	if(! ctrl.contrat.lat || ! ctrl.contrat.lon){
	       		var agence = {lat:48.8582846,lon:2.2944474};//On ajoute Paris par défaut
	        	
	        	if(Principal.isIdentityResolved()){
	        		
	    	    	var account= Principal.getIdentityIfExist();
	
	            	if(account.agence && account.agence.lat && account.agence.lon){
	            		agence = account.agence;
	            	}
	        	}
            	
	            ctrl.contrat.lat = agence.lat;
	            ctrl.contrat.lon = agence.lon ;
	            ctrl.updateCoord ();
	           	
		        angular.extend($scope, {
	        	    center: {
	                    lat: ctrl.contrat.lat,
	                    lng: ctrl.contrat.lon,
	        	        zoom: 8
	        	    }
		        });
	       	}
    	
     }

      
      $scope.editMarker = function(){
    	  $scope.addMarker();
    	  
    	  var markers =  $scope.markers;
    	
    	  if( markers.contrat.draggable){
    		  markers.contrat.draggable=false;
        	  $('#Btn3').removeClass( 'btn-danger' );
    	  }else{
    		  markers.contrat.draggable=true;
        	  $('#Btn3').addClass( 'btn-danger' );
    	  }
              
    	  
    	 
    	 
    	 
//    	  markers.contrat.draggable=!markers.contrat.draggable;
    	  
      }
		       
       
       if(ctrl.contrat && ctrl.contrat.$promise){
    	   ctrl.contrat.$promise.then(function(value) {
    		   ctrl.updateCoord ();
           	
           });	
       }else{
    	   ctrl.updateCoord();
       }

       $scope.$on("leafletDirectiveMarker.dragend", function(event, args){
    	   ctrl.contrat.lat = args.model.lat;
    	   ctrl.contrat.lon = args.model.lng;
     	   $scope.markers.contrat.lat = args.model.lat;
     	   $scope.markers.contrat.lng = args.model.lng;
       });

         
      }
  
    angular
        .module('wamasysApp')
        .component('mapLeaflet', mapLeaflet);

    
})();