(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('MainController', MainController);

    MainController.$inject = ['$location', '$scope', 'Principal'];

    function MainController ($location, $scope, Principal) {

        $scope.isAuthenticated = Principal.isAuthenticated;
        
        // Obtenez l'URL actuelle
        var currentUrl = $location.absUrl();
        
        // Vérifiez si l'URL contient 'localhost' ou 'integration'
        if (currentUrl.indexOf('localhost') !== -1 || currentUrl.indexOf('integration.wamasys.com') !== -1) {
            // Si l'URL contient 'localhost' ou 'integration', définissez ENV sur 'dev'
            $scope.RIBBON_TEST = true;
      //      $scope.ENV = 'dev';
        } else {
            // Sinon, définissez ENV sur une autre valeur ou laissez-le non défini selon vos besoins
            // Par exemple, si vous voulez cacher quelque chose dans d'autres environnements
            $scope.RIBBON_TEST = false;
        }
        
    }
})();
