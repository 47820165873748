(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('EventFormSubCategoryDeleteController',EventFormSubCategoryDeleteController);

    EventFormSubCategoryDeleteController.$inject = ['$uibModalInstance', 'entity', 'EventFormCategory'];

    function EventFormSubCategoryDeleteController($uibModalInstance, entity, EventFormCategory) {
        var vm = this;
        vm.EventFormCategory = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            EventFormCategory.deleteSubCategory({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
