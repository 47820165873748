(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('BillingContractDialogController', BillingContractDialogController );

    BillingContractDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity',  'Billing', 'UserSearch'];

    function BillingContractDialogController  ($scope, $stateParams, $uibModalInstance, entity, Billing, UserSearch) {
        var vm = this;
        vm.contrat = entity;
        
        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:billingContractUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            Billing.createForContract(
            		{
            			contractId:vm.contrat.id
            		}
            		, onSaveSuccess, onSaveError);
        
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

//        vm.datePickerOpenStatus = {};
//        vm.datePickerOpenStatus.dateFacturation = false;
//
//        vm.openCalendar = function(date) {
//            vm.datePickerOpenStatus[date] = true;
//        };
    }
})();
