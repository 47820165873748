(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('ReportingGlobalGraphEau', ReportingGlobalGraphEau);

    ReportingGlobalGraphEau.$inject = ['$resource'];

    function ReportingGlobalGraphEau ($resource) {
        var resourceUrl =  'api/reporting-global-graph-eau/search';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false}
        });
        
    }
})();
