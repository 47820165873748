(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('BulkMeterDetailController', BulkMeterDetailController);

    BulkMeterDetailController.$inject = ['$scope', '$state' , '$rootScope', '$stateParams', 'entity', 'BulkMeter', 'ReleveCompteur', 'User','ConsommationEauBulkMeter', 'pagingParams', 'paginationConstants', 'AlertService', 'ParseLinks'];

    function BulkMeterDetailController($scope, $state,  $rootScope, $stateParams, entity, BulkMeter, ReleveCompteur, User,ConsommationEauBulkMeter, pagingParams, paginationConstants, AlertService, ParseLinks) {
        var vm = this;
        vm.bulkMeter = entity;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.load = function (id) {
            ReleveCompteur.byBulkMeter({
            	id:id,
         	    page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage
            }, function(result, headers) {
         	   vm.listeCollectionList  = result;
               vm.links = ParseLinks.parse(headers('link'));
               vm.totalItems = headers('X-Total-Count');
               vm.queryCount = vm.totalItems;
               vm.page = pagingParams.page;
             },  function (error) {
                 AlertService.error(error.data.message);
             });
            ConsommationEauBulkMeter.byBulkMeter({
            	id:id,
          	    page: pagingParams.consoPage - 1,
                size: paginationConstants.itemsPerPage
            }, function(result, headers) {
          	   vm.consommationBulkMeter  = result;
               vm.links = ParseLinks.parse(headers('link'));
               vm.totalItemsConso = headers('X-Total-Count');
               vm.queryCountConso = vm.totalItemsConso;
               vm.consoPage = pagingParams.consoPage;
            },  function (error) {
                AlertService.error(error.data.message);
            });
        };
        
        if($stateParams.id){
            vm.load($stateParams.id);
        }
        
        var unsubscribe = $rootScope.$on('wamasysApp:bulkMeterUpdate', function(event, result) {
            vm.bulkMeter = result;
        });
        $scope.$on('$destroy', unsubscribe);

        vm.transition = function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                consoPage: vm.consoPage,
                id:$stateParams.id,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
            });
        };
    }
})();
