(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ModelMeterDetailController', ModelMeterDetailController);

    ModelMeterDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'ModelMeter'];

    function ModelMeterDetailController($scope, $rootScope, $stateParams, entity, ModelMeter) {
        var vm = this;
        vm.modelMeter = entity;
        vm.load = function (id) {
            ModelMeter.get({id: id}, function(result) {
                vm.modelMeter = result;
            });
        };
        var unsubscribe = $rootScope.$on('wamasysApp:modelMeterUpdate', function(event, result) {
            vm.modelMeter = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
