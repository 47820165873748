(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('AdminTools', AdminTools);

    AdminTools.$inject = ['$resource','DateUtils'];

    function AdminTools ($resource,DateUtils) {
        var resourceUrl =  'api/admin-tools/';

        return $resource(resourceUrl, {}, {
            'createAreaTest': {
            	method: 'POST',
              	 url :'api/admin-tools/createAreaTest'
             },
             'anonymiserArea': {
                method: 'POST',
                url :'api/admin-tools/anonymiser_donnees'
             },
             'completerDonneesTest': {
                 method: 'GET',
                 url :'api/admin-tools/completer-donnees-test/:idOrga/:idAgence'
              }
             ,
             'majTeamwork': {
                 method: 'GET',
                 url :'api/admin-tools/init_user_teamwork/:idOrga'
              },
             'exportElkCheckexport': {
                method: 'POST',
                url :'api/export/checkexport?'
//                	,                params: {toDate: toDate}
             },
             'exportElkCreditNoteRefund': {
                 method: 'POST',
                 url :'api/export/credit_note_refund',
                 params: {fromDate: null, toDate: null}
              },
              'exportElkFacturation': {
                  method: 'POST',
                  url :'api/export/facturation'
//                	  ,
//                  params: {fromDate: null, toDate: null}
               },
               'exportElkconsommationeau': {
                   method: 'POST',
                   url :'api/export/consommationeau',
                   params: {fromDate: null, toDate: null}
                },
                'initialiserroleareaelastic': {
                    method: 'POST',
                    url :'api/export/initialiserroleareaelastic'
                 },
                 'exportAllEventInElastic': {
                     method: 'GET',
                     url :'api/form-event/exportAllEventInElastic'
                  }
               
        });
    }
})();
