(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('WaterNetworkDetailController', WaterNetworkDetailController);

    WaterNetworkDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'WaterNetwork'];

    function WaterNetworkDetailController($scope, $rootScope, $stateParams, entity, WaterNetwork) {
        var vm = this;
        vm.waterNetwork = entity;
        vm.load = function (id) {
            WaterNetwork.get({id: id}, function(result) {
                vm.waterNetwork = result;
            });
        };
        var unsubscribe = $rootScope.$on('wamasysApp:waterNetworkUpdate', function(event, result) {
            vm.waterNetwork = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
