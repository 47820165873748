(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ExpenseDeleteController',ExpenseDeleteController);

    ExpenseDeleteController.$inject = ['$uibModalInstance', 'entity', 'Expense'];

    function ExpenseDeleteController($uibModalInstance, entity, Expense) {
        var vm = this;
        vm.expense = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            Expense.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
