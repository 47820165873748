(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('Agence', Agence);

    Agence.$inject = ['$resource','DateUtils'];

    function Agence ($resource,DateUtils) {
        var resourceUrl =  'api/agences/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.dateDebutDemarrage = DateUtils.convertLocalDateFromServer(data.dateDebutDemarrage);
                    return data;
                }
            },
            'update': { method:'PUT',
                transformRequest: function (data) {
                	var clonedData = Object.assign({}, data);
                	clonedData.dateDebutDemarrage = DateUtils.convertLocalDateToServer(data.dateDebutDemarrage);
                    return angular.toJson(clonedData);
                }
            }
            ,
            'saveClientPortalConf': { method:'PUT',
            	url:'api/agences/client-portal-conf',
                transformRequest: function (data) {
                	var clonedData = Object.assign({}, data);
//                	clonedData.dateDebutDemarrage = DateUtils.convertLocalDateToServer(data.dateDebutDemarrage);
                    return angular.toJson(clonedData);
                }
            },
            'getClientPortalConf': {
                method: 'GET',
                url:'api/agences/:idArea/client-portal-conf',
                transformResponse: function (data) {
                	if(data){
                	     data = angular.fromJson(data);
//                       data.dateDebutDemarrage = DateUtils.convertLocalDateFromServer(data.dateDebutDemarrage);
                       return data;
                	}
                	 return data;
                }
            },
            'saveOnlinePaymentConf': { method:'PUT',
            	url:'api/agences/online-payment-conf',
                transformRequest: function (data) {
                	var clonedData = Object.assign({}, data);
//                	clonedData.dateDebutDemarrage = DateUtils.convertLocalDateToServer(data.dateDebutDemarrage);
                    return angular.toJson(clonedData);
                }
            },
            'getOnlinePaymentConf': {
                method: 'GET',
                url:'api/agences/:idArea/online-payment-conf',
                transformResponse: function (data) {
                	if(data){
                	     data = angular.fromJson(data);
//                       data.dateDebutDemarrage = DateUtils.convertLocalDateFromServer(data.dateDebutDemarrage);
                       return data;
                	}
                	 return data;
                }
            },
            'saveGeometryConf': { method:'PUT',
            	url:'api/agences/geometry-conf/',
                transformRequest: function (data) {
                	var clonedData = Object.assign({}, data);
//                	clonedData.dateDebutDemarrage = DateUtils.convertLocalDateToServer(data.dateDebutDemarrage);
                    return angular.toJson(clonedData);
                }
            },
            'getTimezones': {
                method: 'GET',
                url:'api/timezones',
                isArray: true
            }
            
        });
    }
})();
