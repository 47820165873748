(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('reporting-global-graph-eau', {
            parent: 'entity',
            url: '/reporting-global-graph-eau',
            data: {
                authorities: ['ROLE_READ_ONLY'],
                pageTitle: 'wamasysApp.reportingGlobal.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/business/reporting-global-graph-eau/reporting-global-graph-eau.html',
                    controller: 'ReportingGlobalGraphEauController',
                    controllerAs: 'vm'
                }
            },

            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('reportingGlobal');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        });
        
        $stateProvider
        .state('reporting-global-graph-consoeauglobal', {
            parent: 'entity',
            url: '/reporting-global-graph-consoeauglobal',
            data: {
                authorities: ['ROLE_READ_ONLY'],
                pageTitle: 'wamasysApp.reportingGlobal.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/business/reporting-global-graph-eau/reporting-global-graph-eau.html',
                    controller: 'ReportingGlobalGraphConsEauGlobalController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('reportingGlobal');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }  );
        
        

        $stateProvider
        .state('reporting-global-graph-stat-contrat', {
            parent: 'entity',
            url: '/reporting-global-graph-stat-contrat',
            data: {
                authorities: ['ROLE_READ_ONLY'],
                pageTitle: 'wamasysApp.reportingGlobal.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/business/reporting-global-graph-eau/reporting-global-graph-eau.html',
                    controller: 'ReportingGlobalGraphStatContratController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('reportingGlobal');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        });
        

        $stateProvider
        .state('reporting-global-graph-synthese', {
            parent: 'entity',
            url: '/reporting-global-graph-synthese',
            data: {
                authorities: ['ROLE_READ_ONLY'],
                pageTitle: 'wamasysApp.reportingGlobal.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/business/reporting-global-graph-eau/reporting-global-graph-eau.html',
                    controller: 'ReportingGlobalGraphSyntheseController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('reportingGlobal');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }  );
        

        $stateProvider
        .state('reporting-global-graph-synthese-hebdo', {
            parent: 'entity',
            url: '/reporting-global-graph-synthese-hebdo',
            data: {
                authorities: ['ROLE_READ_ONLY'],
                pageTitle: 'wamasysApp.reportingGlobal.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/business/reporting-global-graph-eau/reporting-global-graph-eau.html',
                    controller: 'ReportingGlobalGraphSyntheseHebdoController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('reportingGlobal');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }  );
        

        $stateProvider
        .state('reporting-global-graph-anomalie-finances', {
            parent: 'entity',
            url: '/reporting-global-graph-anomalie-finances',
            data: {
                authorities: ['ROLE_READ_ONLY'],
                pageTitle: 'wamasysApp.reportingGlobal.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/business/reporting-global-graph-eau/reporting-global-graph-eau.html',
                    controller: 'ReportingGlobalGraphAnomalieFinancesController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('reportingGlobal');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }  );


        $stateProvider
        .state('reporting-global-graph-anomalie-water', {
            parent: 'entity',
            url: '/reporting-global-graph-anomalie-water',
            data: {
                authorities: ['ROLE_READ_ONLY'],
                pageTitle: 'wamasysApp.reportingGlobal.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/business/reporting-global-graph-eau/reporting-global-graph-eau.html',
                    controller: 'ReportingGlobalGraphAnomalieWaterController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('reportingGlobal');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }  );
        
        
        $stateProvider
        .state('reporting-global-graph-interagence', {
            parent: 'entity',
            url: '/reporting-global-graph-interagence',
            data: {
                authorities: ['ROLE_READ_ONLY'],
                pageTitle: 'wamasysApp.reportingGlobal.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/business/reporting-global-graph-eau/reporting-global-graph-eau.html',
                    controller: 'ReportingGlobalGraphInterAgenceController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('reportingGlobal');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }  );

        $stateProvider
        .state('reporting-global-graph-summary-global', {
            parent: 'entity',
            url: '/reporting-global-graph-summary-global',
            data: {
                authorities: ['ROLE_READ_ONLY'],
                pageTitle: 'wamasysApp.reportingGlobal.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/business/reporting-global-graph-eau/reporting-global-graph-eau.html',
                    controller: 'ReportingGlobalGraphSummaryGlobalController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('reportingGlobal');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }  );

        $stateProvider
        .state('reporting-global-graph-search-payment', {
            parent: 'entity',
            url: '/reporting-global-graph-search-payment',
            data: {
                authorities: ['ROLE_READ_ONLY'],
                pageTitle: 'wamasysApp.reportingGlobal.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/business/reporting-global-graph-eau/reporting-global-graph-eau.html',
                    controller: 'ReportingGlobalGraphSearchPaymentController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('reportingGlobal');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }  );

        $stateProvider
        .state('reporting-global-graph-summary-annual-global', {
            parent: 'entity',
            url: '/reporting-global-graph-summary-annual-global',
            data: {
                authorities: ['ROLE_READ_ONLY'],
                pageTitle: 'wamasysApp.reportingGlobal.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/business/reporting-global-graph-eau/reporting-global-graph-eau.html',
                    controller: 'ReportingGlobalGraphSummaryAnnualController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('reportingGlobal');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }  );

        $stateProvider
        .state('reporting-global-geolocation', {
            parent: 'entity',
            url: '/reporting-global-geolocation',
            data: {
                authorities: ['ROLE_READ_ONLY'],
                pageTitle: 'wamasysApp.reportingGlobal.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/business/reporting-global-graph-eau/reporting-global-graph-eau.html',
                    controller: 'ReportingGlobalGeolocationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('reportingGlobal');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }  );

        $stateProvider
        .state('reporting-global-evenement', {
            parent: 'entity',
            url: '/reporting-global-evenement',
            data: {
                authorities: ['ROLE_EVT_READ_ONLY'],
                pageTitle: 'wamasysApp.reportingGlobal.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/business/reporting-global-graph-eau/reporting-global-graph-eau.html',
                    controller: 'ReportingGlobalEvenementController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('reportingGlobal');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }  );


        $stateProvider
        .state('reporting-budget', {
            parent: 'entity',
            url: '/reporting-budget',
            data: {
                authorities: ['ROLE_BUDGET_READ_ONLY'],
                pageTitle: 'wamasysApp.reportingGlobal.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/business/reporting-global-graph-eau/reporting-global-graph-eau.html',
                    controller: 'ReportingBudgetController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('reportingGlobal');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }  );


        $stateProvider
        .state('reporting-budget-global', {
            parent: 'entity',
            url: '/reporting-budget-global',
            data: {
                authorities: ['ROLE_BUDGET_READ_ONLY'],
                pageTitle: 'wamasysApp.reportingGlobal.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/business/reporting-global-graph-eau/reporting-global-graph-eau.html',
                    controller: 'ReportingBudgetGlobalController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('reportingGlobal');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }  );
        
        
        $stateProvider
        .state('reporting-global-graph-stat-financial', {
            parent: 'entity',
            url: '/reporting-global-stat-financial',
            data: {
                authorities: ['ROLE_READ_ONLY'],
                pageTitle: 'wamasysApp.reportingGlobal.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/business/reporting-global-graph-eau/reporting-global-graph-eau.html',
                    controller: 'ReportingGlobalStatFinancialController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('reportingGlobal');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }  );

        $stateProvider
        .state('reporting-global-discount', {
            parent: 'entity',
            url: '/reporting-global-discount',
            data: {
                authorities: ['ROLE_READ_ONLY'],
                pageTitle: 'wamasysApp.reportingGlobal.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/business/reporting-global-graph-eau/reporting-global-graph-eau.html',
                    controller: 'ReportingGlobalDiscountController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('reportingGlobal');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }  );


        $stateProvider
        .state('reporting-global-iot-smart-water-meter', {
            parent: 'entity',
            url: '/reporting-iot-smart-water-meter',
            data: {
                authorities: ['ROLE_READ_ONLY'],
                pageTitle: 'wamasysApp.reportingGlobal.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/business/reporting-global-graph-eau/reporting-global-graph-eau.html',
                    controller: 'ReportingGlobalIOTSmartWaterMeterController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('reportingGlobal');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }  );
        


        $stateProvider
        .state('reporting-global-my-dashboard', {
            parent: 'entity',
            url: '/reporting-global-my-dashboard/{index}',
            data: {
                authorities: ['ROLE_READ_ONLY','ROLE_EVT_READ_ONLY'],
                pageTitle: 'wamasysApp.reportingGlobal.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/business/reporting-global-graph-eau/reporting-global-graph-eau.html',
                    controller: 'ReportingGlobalMyDashboardController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            	confDashboard: ['$stateParams', 'Principal', function($stateParams, Principal) {
                	return  Principal.identity().then(function(account) {
	           			 if(account.listDashboardKibana){
	                  		 try {
	                  			 if(typeof account.listDashboardKibana === 'string'){
	                      			 account.listDashboardKibana = JSON.parse(account.listDashboardKibana);
	                  			 }
	                  			 if( account.listDashboardKibana &&  account.listDashboardKibana.dashboards){
		                  			 for(var i  = 0;i<account.listDashboardKibana.dashboards.length;i++){
		                  				 if(account.listDashboardKibana.dashboards[i].index ==  $stateParams.index){
		                  					 return account.listDashboardKibana.dashboards[i];
		                  				 }
		                  			 } 
	                  			 }
	                  			 if( account.listDashboardKibana &&  account.listDashboardKibana.dashboardsmenu){
		                  			 for(var i  = 0;i<account.listDashboardKibana.dashboardsmenu.length;i++){
			                  			 for(var x  = 0;x<account.listDashboardKibana.dashboardsmenu[i].items.length;x++){
			                  				 if(account.listDashboardKibana.dashboardsmenu[i].items[x].index ==  $stateParams.index){
			                  					 return account.listDashboardKibana.dashboardsmenu[i].items[x];
			                  				 }
			                  			 }
		                  			 }
	                  			 }
	                   	    } catch (e) {
	                   	    	console.log(e);
	                   	    } 
	          			 }
	           			 return null;
	           			 
	                  });
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('reportingGlobal');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }  );
        
        
    }

})();
