(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ExpenseController', ExpenseController);

    ExpenseController.$inject = ['$scope', '$state', 'Expense', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function ExpenseController ($scope, $state, Expense, ParseLinks, AlertService, pagingParams, paginationConstants) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.loadAll();

        function loadAll () {
            Expense.queryInclDeleted({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.expenses = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }


    	vm.download = function(){
    		Expense.downloadcsv( {}
    			,function (content){
    		      var url = URL.createObjectURL(content.response);
    		      var a = document.createElement('a');
    		      a.href = url;
    		      a.download = content.fileName;
    		      a.target = '_blank';
    		      a.click();
    		}
    				
    		);
    	}



    
    	
    }
})();
