(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('EventForm', EventForm);

    EventForm.$inject = ['$resource', 'DateUtils'];

    function EventForm ($resource, DateUtils) {
        var resourceUrl =  'api/form-event/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.dateRecord = DateUtils.convertDateTimeFromServer(data.dateRecord);
                    return data;
                }
            },
            'update': { method:'PUT'}
        });
    }
})();
