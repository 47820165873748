(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('IndividualMeterController', IndividualMeterController);

    IndividualMeterController.$inject = ['$scope', '$state', 'IndividualMeter','Cluster', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'IndividualMeterSearch','ModelMeter'];

    function IndividualMeterController ($scope, $state, IndividualMeter,Cluster, ParseLinks, AlertService, pagingParams, paginationConstants, IndividualMeterSearch,ModelMeter) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.search = search;
        vm.clear = clear;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.clusters = Cluster.query({ offset: 0,size: 1000});
        vm.modelMeters = ModelMeter.query({ offset: 0,size: 1000});
        vm.etat = $state.params.etat;
        if($state.params.cluster){
        	vm.cluster = {
        			id:$state.params.cluster
        	}
        }
        if($state.params.modelMeter){
        	vm.modelMeter = {
        			id:$state.params.modelMeter
        	}
        }
        vm.text = $state.params.text;
        vm.isIOT = $state.params.isIOT;
        
        vm.currentSearch = false ;
        if( $state.params.text ||  $state.params.isIOT || $state.params.etat || $state.params.cluster || $state.params.modelMeter){
            vm.currentSearch = true ;
        }
        
        vm.loadAll();

        function loadAll () {
        	IndividualMeterSearch.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                etat:vm.etat,
                cluster:$state.params.cluster,
                modelMeter:$state.params.modelMeter,
                text:vm.text,
                isIOT:vm.isIOT,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.individualMeters = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }
        
        function search () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = '';
            vm.reverse = false;
            vm.transition();
        }
        
        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.etat =null;
            vm.text =null;
            vm.isIOT =null;
            vm.cluster =null;
            vm.modelMeter =null;
            vm.transition();
        }


        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                etat:vm.etat,
                text:vm.text,
                isIOT:vm.isIOT,
                modelMeter:vm.modelMeter?vm.modelMeter.id:null,
                cluster:vm.cluster?vm.cluster.id:null
            });
        }
        


      	vm.download = function(){
      		IndividualMeter.downloadcsv( {
    	        etat:vm.etat,
    	        cluster:$state.params.cluster,
    	        modelMeter:$state.params.modelMeter,
    	        text:vm.text,
                isIOT:vm.isIOT,
        		}
    			,function (content){
    		      var url = URL.createObjectURL(content.response);
    		      var a = document.createElement('a');
    		      a.href = url;
    		      a.download = content.fileName;
    		      a.target = '_blank';
    		      a.click();
    		}
    				
    		);
    	}
      	
    }
})();
