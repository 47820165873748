(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ClusterDialogController', ClusterDialogController);

    ClusterDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Cluster',  'UserSearch', 'IndividualMeter','WaterNetwork'];

    function ClusterDialogController ($scope, $stateParams, $uibModalInstance, entity, Cluster, UserSearch, IndividualMeter,WaterNetwork) {
        var vm = this;
        vm.cluster = entity;
        vm.users = UserSearch.searchCollecteur();
        vm.individualmeters = IndividualMeter.query();
        vm.waterNetworks = WaterNetwork.queryFilterArea();
        vm.load = function(id) {
            Cluster.get({id : id}, function(result) {
                vm.cluster = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:clusterUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.cluster.id !== null) {
                Cluster.update(vm.cluster, onSaveSuccess, onSaveError);
            } else {
                Cluster.save(vm.cluster, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateCreation = false;
        vm.datePickerOpenStatus.dateSuppression = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
