(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ExpenseDialogController', ExpenseDialogController);

    ExpenseDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Expense','ExpenseType','TypeTax'];

    function ExpenseDialogController ($scope, $stateParams, $uibModalInstance, entity, Expense,ExpenseType,TypeTax) {
        var vm = this;
        vm.expense = entity;
        vm.typeTaxs = TypeTax.query({size:100});
        vm.taxeSelected = null;
        vm.load = function(id) {
            Expense.get({id : id}, function(result) {
                vm.expense = result;
            });
        };

        ExpenseType.query({size:100}, function(result) {
        	vm.expenseTypes = result;
        	if(entity.$promise){
        		entity.$promise.then(function(value) {
    	        	if( vm.expense.expenseType){
    	        		var  fnctIdEqual = function(element) {return element.id === vm.expense.expenseType.id};
    	        		var index = vm.expenseTypes.findIndex(fnctIdEqual);
    	        		if(index === -1 ){
    	        			vm.expenseTypes.push(vm.expense.expenseType);
    	        		}
    	        	}
            	});	
        	}
        
        });
        
        vm.selectTax  = function() {
        	if(vm.expense.amountInclTaxes){
	        	 vm.expense.amountExclTaxes = vm.expense.amountInclTaxes / ((vm.taxeSelected.taxRate/100) +1);
	        	 vm.expense.amountExclTaxes =  Math.round( vm.expense.amountExclTaxes * 100) / 100;
	        	 vm.expense.taxes = vm.expense.amountInclTaxes -  vm.expense.amountExclTaxes;
	        	 vm.expense.taxes =  Math.round( vm.expense.taxes * 100) / 100;
	        	 vm.taxRate = vm.taxeSelected.taxRate;
        	}
        };
        
        vm.changeAmountInclTaxes = function() {
        	if(vm.expense.amountInclTaxes && vm.taxeSelected && vm.taxeSelected.taxRate){
        		vm.expense.amountExclTaxes = vm.expense.amountInclTaxes / ( 1+ (vm.taxeSelected.taxRate/100)); 
           	 	vm.expense.amountExclTaxes =  Math.round( vm.expense.amountExclTaxes * 100) / 100;
            	vm.taxRate = 	(( vm.expense.amountInclTaxes / vm.expense.amountExclTaxes )-1)*100;
           	 	vm.taxRate =  Math.round( vm.taxRate * 100) / 100;
           	 	vm.expense.taxes = vm.expense.amountInclTaxes -  vm.expense.amountExclTaxes;
           	 	vm.expense.taxes =  Math.round( vm.expense.taxes * 100) / 100;
        	}else{
        		vm.expense.amountExclTaxes = vm.expense.amountInclTaxes;
           	 	vm.expense.amountExclTaxes =  Math.round( vm.expense.amountExclTaxes * 100) / 100;
        		vm.expense.taxes = 0;
        		vm.taxRate = 0;
        	}
      
       };
        

       vm.changeAmountExclTaxes = function() {
       	if(vm.expense.amountInclTaxes &&  vm.expense.amountExclTaxes){
           	vm.taxRate = 	(( vm.expense.amountInclTaxes / vm.expense.amountExclTaxes )-1)*100;
          	vm.taxRate =  Math.round( vm.taxRate * 100) / 100;
          	vm.expense.taxes = vm.expense.amountInclTaxes -  vm.expense.amountExclTaxes;
          	vm.expense.taxes =  Math.round( vm.expense.taxes * 100) / 100;
       	}
     
      };
      
      vm.changeTaxes = function() {
         	if(vm.expense.amountInclTaxes &&  vm.expense.taxes){
         		vm.expense.amountExclTaxes = 	vm.expense.amountInclTaxes - vm.expense.taxes 
             	vm.taxRate = 	(( vm.expense.amountInclTaxes / vm.expense.amountExclTaxes )-1)*100;
            	vm.taxRate =  Math.round( vm.taxRate * 100) / 100;
         	}
        };
      
        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:expenseUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            
//            if(! vm.expense.amountInclTaxes || vm.expense.amountInclTaxes < 0 ){
//            	editForm.amountInclTaxes.$invalid = true;
//            	return ;
//            }
//            if(! vm.expense.amountExclTaxes || vm.expense.amountExclTaxes < 0 ){
//            	editForm.amountExclTaxes.$invalid = true;
//            	return ;
//            }
//            if(! vm.expense.taxes || vm.expense.taxes < 0 ){
//            	editForm.taxes.$invalid = true;
//            	return ;
//            }

            if (vm.expense.id !== null) {
                Expense.update(vm.expense, onSaveSuccess, onSaveError);
            } else {
                Expense.save(vm.expense, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.date = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
