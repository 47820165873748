(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('PortefeuilleContratDetailController', PortefeuilleContratDetailController);

    PortefeuilleContratDetailController.$inject = ['$scope','$state',  '$rootScope', '$stateParams', 'entity', 'PortefeuilleContrat', 'ParseLinks', 'AlertService', 'ContratSearch', 'Agence', 'User', 'pagingParams', 'paginationConstants'];

    function PortefeuilleContratDetailController($scope, $state, $rootScope, $stateParams, entity, PortefeuilleContrat,ParseLinks, AlertService, ContratSearch, Agence, User, pagingParams, paginationConstants) {
        var vm = this;
        vm.portefeuilleContrat = entity;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        
        vm.loadPage = function (page) {
        	ContratSearch.query({
            		portefeuilleContrat:$stateParams.id,                 
            		page: pagingParams.page -1,
            		size: paginationConstants.itemsPerPage,
            		sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.contrats = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        };
        vm.loadPage();
        
        var unsubscribe = $rootScope.$on('wamasysApp:portefeuilleContratUpdate', function(event, result) {
            vm.portefeuilleContrat = result;
        });
        $scope.$on('$destroy', unsubscribe);

        
        vm.transition = function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                id:$stateParams.id,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
            });
        };
        
        
	    var lundi = 1;	
	    var mardi = 2;	
	    var mercredi = 4;	
	    var  jeudi	=	8	;
	    var  vendredi=	16	;
	    var samedi	=	32	;
	    var dimanche=	64	;

        if(vm.portefeuilleContrat && vm.portefeuilleContrat.$promise){
		    vm.portefeuilleContrat.$promise.then(function(value) {
		
		        if(vm.portefeuilleContrat.autoBillingPeriodType == 'WEEKDAY'){
		            var billingValeurInitiale = vm.portefeuilleContrat.autoBillingInterval ;
		    	    
		    	    //On intialise le billing
		    		var billingLeLundi = (billingValeurInitiale | lundi) == billingValeurInitiale;
		    		var billingLeMardi= (billingValeurInitiale | mardi) == billingValeurInitiale;
		    		var billingLeMercredi= (billingValeurInitiale | mercredi) == billingValeurInitiale;
		    		var billingLeJeudi= (billingValeurInitiale | jeudi) == billingValeurInitiale;
		    		var billingLevendredi= (billingValeurInitiale | vendredi) == billingValeurInitiale;
		    		var billingLesamedi= (billingValeurInitiale | samedi) == billingValeurInitiale;
		    		var billingLedimanche= (billingValeurInitiale | dimanche) == billingValeurInitiale;
		    		
		    		vm.billingWeekday = [];
		    		if(billingLeLundi){
		    			vm.billingWeekday.push("wamasysApp.portefeuilleContrat.periodType.monday");
		    		}
		    		if(billingLeMardi){
		    			vm.billingWeekday.push("wamasysApp.portefeuilleContrat.periodType.tuesday");
		    		}
		    		if(billingLeMercredi){
		    			vm.billingWeekday.push("wamasysApp.portefeuilleContrat.periodType.wednesday");
		    		}
		    		if(billingLeJeudi){
		    			vm.billingWeekday.push("wamasysApp.portefeuilleContrat.periodType.thursday");
		    		}
		    		if(billingLevendredi){
		    			vm.billingWeekday.push("wamasysApp.portefeuilleContrat.periodType.friday");
		    		}
		    		if(billingLesamedi){
		    			vm.billingWeekday.push("wamasysApp.portefeuilleContrat.periodType.saturday");
		    		}
		    		if(billingLedimanche){
		    			vm.billingWeekday.push("wamasysApp.portefeuilleContrat.periodType.sunday");
		    		}
		        }
	
		        if(vm.portefeuilleContrat.autoInvoicePeriodType == 'WEEKDAY'){
		            var invoiceValeurInitiale = vm.portefeuilleContrat.autoInvoiceInterval ;
		    	    
		    	    //On intialise le invoice
		    		var invoiceLeLundi = (invoiceValeurInitiale | lundi) == invoiceValeurInitiale;
		    		var invoiceLeMardi= (invoiceValeurInitiale | mardi) == invoiceValeurInitiale;
		    		var invoiceLeMercredi= (invoiceValeurInitiale | mercredi) == invoiceValeurInitiale;
		    		var invoiceLeJeudi= (invoiceValeurInitiale | jeudi) == invoiceValeurInitiale;
		    		var invoiceLevendredi= (invoiceValeurInitiale | vendredi) == invoiceValeurInitiale;
		    		var invoiceLesamedi= (invoiceValeurInitiale | samedi) == invoiceValeurInitiale;
		    		var invoiceLedimanche= (invoiceValeurInitiale | dimanche) == invoiceValeurInitiale;
		    		
		    		vm.invoiceWeekday = [];
		    		if(invoiceLeLundi){
		    			vm.invoiceWeekday.push("wamasysApp.portefeuilleContrat.periodType.monday");
		    		}
		    		if(invoiceLeMardi){
		    			vm.invoiceWeekday.push("wamasysApp.portefeuilleContrat.periodType.tuesday");
		    		}
		    		if(invoiceLeMercredi){
		    			vm.invoiceWeekday.push("wamasysApp.portefeuilleContrat.periodType.wednesday");
		    		}
		    		if(invoiceLeJeudi){
		    			vm.invoiceWeekday.push("wamasysApp.portefeuilleContrat.periodType.thursday");
		    		}
		    		if(invoiceLevendredi){
		    			vm.invoiceWeekday.push("wamasysApp.portefeuilleContrat.periodType.friday");
		    		}
		    		if(invoiceLesamedi){
		    			vm.invoiceWeekday.push("wamasysApp.portefeuilleContrat.periodType.saturday");
		    		}
		    		if(invoiceLedimanche){
		    			vm.invoiceWeekday.push("wamasysApp.portefeuilleContrat.periodType.sunday");
		    		}
		        }
		    });
        }
    }
})();
