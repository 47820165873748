(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('TypeServiceDetailController', TypeServiceDetailController);

    TypeServiceDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'TypeService'];

    function TypeServiceDetailController($scope, $rootScope, $stateParams, entity, TypeService) {
        var vm = this;
        vm.typeService = entity;
        vm.load = function (id) {
            TypeService.get({id: id}, function(result) {
                vm.typeService = result;
            });
        };
        var unsubscribe = $rootScope.$on('wamasysApp:typeServiceUpdate', function(event, result) {
            vm.typeService = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
