(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('contrat', {
            parent: 'entity',
            url: '/contrat?page&sort&searchQuery&etat&compteurFerme&portefeuilleContrat&contractType&contractProfil&serviceVendu&startDate&endDate&onlinePaymentActive&onlinePaymentQuery&onlinePaymentType',
            data: {
                authorities: ['ROLE_DATA_OPERATOR','ROLE_READ_ONLY'],
                pageTitle: 'wamasysApp.contrat.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/business/contrat/contrats.html',
                    controller: 'ContratController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('contrat');
                    $translatePartialLoader.addPart('etatContrat');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('ligneContrat');
                    return $translate.refresh();
                }]
            }
        })
        .state('contrat-detail', {
            parent: 'entity',
            url: '/contrat/{id}',
            data: {
                authorities: ['ROLE_DATA_OPERATOR','ROLE_READ_ONLY'],
                pageTitle: 'wamasysApp.contrat.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/business/contrat/contrat-detail.html',
	                controller: 'ContratDetailController',
	                controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('contrat');
                    $translatePartialLoader.addPart('etatContrat');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('etatMeter');
                    $translatePartialLoader.addPart('collectionListe');
                    $translatePartialLoader.addPart('ligneContrat');
                    $translatePartialLoader.addPart('serviceVendu');
                    $translatePartialLoader.addPart('redmine');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Contrat', function($stateParams, Contrat) {
                    return Contrat.get({id : $stateParams.id});
                }]
            }
        })
        
        .state('contrat-detail.information', {
            url: '/information',
            views: {'panneau@content': {
	                templateUrl: 'app/business/contrat/information-contrat.html',	
	                controller: 'InformationContratController',
	                controllerAs: 'vm'
	            }
            }
        })
        
        
		.state('contrat-detail.services',{
			url : '/services',
			views : {
				'panneau@content' : {
					templateUrl : 'app/business/contrat/services-contrat.html',

					controller : 'ServicesContratController',
					controllerAs : 'vm'
					}
				}
			})
		.state('contrat-detail.paiements',{
			url : '/paiements',
			views : {
				'panneau@content' : {
					templateUrl : 'app/business/contrat/paiements-contrat.html',

					controller : 'PaiementsContratController',
					controllerAs : 'vm'
					}
				}
			})
			
		.state('contrat-detail.factures',{
				url : '/factures?page&sort',
	            params: {
	                page: {
	                    value: '1',
	                    squash: true
	                },
	                sort: {
	                    value: 'id,asc',
	                    squash: true
	                }
	            },
				views : {
					'panneau@content' : {
						templateUrl : 'app/business/contrat/factures-contrat.html',

						controller : 'FacturesContratController',
						controllerAs : 'vm'
						}
					},
		            resolve: {
		                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
		                    return {
		                        page: PaginationUtil.parsePage($stateParams.page),
		                        sort: $stateParams.sort,
		                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
		                        ascending: PaginationUtil.parseAscending($stateParams.sort),
		                        search: $stateParams.search
		                    };
		                }],
		            }
			})
			
		.state('contrat-detail.factures.receipts',{
				url : '/receipts?page&sort',
	            params: {
	                page: {
	                    value: '1',
	                    squash: true
	                },
	                sort: {
	                    value: 'id,asc',
	                    squash: true
	                }
	            },
				views : {
					'panneau@content' : {
						templateUrl : 'app/business/contrat/receipts-contrat.html',

						controller : 'ReceiptsContratController',
						controllerAs : 'vm'
						}
					},
		            resolve: {
		                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
		                    return {
		                        page: PaginationUtil.parsePage($stateParams.page),
		                        sort: $stateParams.sort,
		                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
		                        ascending: PaginationUtil.parseAscending($stateParams.sort),
		                        search: $stateParams.search
		                    };
		                }],
		            }
			})
			
			
        .state('contrat-detail.information.historiqueconsoeau', {
            url: '/histo-consommation-eau?page&sort',
            params: {
	            page: {
	                value: '1',
	                squash: true
	            },
	            sort: {
	                value: 'id,asc',
	                squash: true
	            }
            },
            data: {
                authorities: ['ROLE_DATA_OPERATOR','ROLE_READ_ONLY']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            	var page = $stateParams.page;
            	var sort = $stateParams.sort;
                $uibModal.open({
                    templateUrl: 'app/business/contrat/historiqueconso-contrat-dialog.html',
                    controller: 'HistoConsoContratController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
//                        entity: ['Contrat', function(Contrat) {
//                            return Contrat.get({id : $stateParams.id});
//                        }]
//                    	$transition$: () => $transition$ },
		                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
//		                	if(! $stateParams.page){
//	                    		$stateParams.page = 1;
//	                    	}
//	                    	if(! $stateParams.sort){
//	                    		$stateParams.sort = 'id,asc';
//	                    	}
	                    	
		                    return {
		                        page: PaginationUtil.parsePage(page),
		                        sort: sort,
		                        predicate: PaginationUtil.parsePredicate(sort),
		                        ascending: PaginationUtil.parseAscending(sort)
		                    };
		                }]
		            
                    }
                }).result.then(function(resolvedResponse) {
                	if(resolvedResponse){
                        $state.transitionTo($state.$current, resolvedResponse);
                	}else{
                		$state.go('contrat-detail.information',{id:$stateParams.id},{reload:true});
                	}
                }, function() {
                    $state.go('^');
                });
            }]
        })
        

			.state('contrat-detail.historiquepaiements',{
				url : '/historiquepaiements?page&sort&ligneContrat&startDate&endDate',
	            params: {
	                page: {
	                    value: '1',
	                    squash: true
	                },
	                sort: {
	                    value: 'id,asc',
	                    squash: true
	                }
	            },
				views : {
					'panneau@content' : {
						templateUrl : 'app/business/contrat/historiquepaiements-contrat.html',

						controller : 'HistoPaiementsContratController',
						controllerAs : 'vm'
						}
					},
		            resolve: {
		                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
		                    return {
		                        page: PaginationUtil.parsePage($stateParams.page),
		                        sort: $stateParams.sort,
		                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
		                        ascending: PaginationUtil.parseAscending($stateParams.sort),
		                        search: $stateParams.search
		                    };
		                }],
		            }
					
			})
			
			
			.state('contrat-detail.historiquepaiements.paymentcorrection',{
				url : '/historiquepaiements/{idPayment}/addPaymentCorrection',
            data: {
                authorities: ['ROLE_AREA_MANAGEMENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/contrat/contrat-addpaymentcorrection-dialog.html',
                    controller: 'ContratAddPaymentCorrectionController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        entity:  ['CollectionListe', function(CollectionListe) {
                        	return  CollectionListe.getPaymentReceived({id:$stateParams.idPayment});
                        }]
        
                    }
                }).result.then(function() {
                    $state.go('contrat-detail.historiquepaiements',{id:$stateParams.id},{reload:true});
                }, function() {
                    $state.go('^');
                });
            }]
        })
				
			.state('contrat-detail.dataanalyse',{
				url : '/dataanalyse',
				views : {
					'panneau@content' : {
						templateUrl : 'app/business/contrat/dataanalyse-contrat.html',

						controller : 'DataAnalyseContratController',
						controllerAs : 'vm'
						}
					}
					
			})
			
			.state('contrat-detail.tickets',{
				url : '/tickets/{ref}',
				views : {
					'panneau@content' : {
						templateUrl : 'app/business/contrat/tickets-contrat.html',
						controller : 'TicketsContratController',
						controllerAs : 'vm'
						}
					}
					
			})
	        .state('contrat-detail.tickets.new', {
	            parent: 'contrat-detail.tickets',
	            url: '/new',
	            data: {
//	                authorities: ['ROLE_DATA_OPERATOR']
	            },
	            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
	                $uibModal.open({
	                    templateUrl: 'app/business/contrat/contrat-createticket-dialog.controller.html',
	                    controller: 'ContratCreateTicketController',
	                    controllerAs: 'vm',
	                    backdrop: 'static',
	                    size: 'lg',
	                    resolve: {
	                        entity: function () {
	                            return {
	                            	id: null
	                            };
	                        }
	                    }
	                }).result.then(function(result) {  
	                	 $state.go('^',{},{reload:true});
	                }, function() {
	                    $state.go('^');
	                });
	            }]
	        })
			
        .state('contrat.new', {
            parent: 'contrat',
            url: '/new',
            data: {
                authorities: ['ROLE_DATA_OPERATOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/contrat/contrat-create-dialog.html',
                    controller: 'ContratCreateDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                nomPersonne: null,
                                referenceContrat: null,
                                indexAdresse: null,
                                adresse: null,
                                phone: null,
                                email: null,
                                nombreFamille: null,
                                nombrePersonne: null,
                                commentaire: null,
                                dateCloture: null,
                                etat: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function(result) {                	
                    $state.go('contrat-detail.services',{id:result.id});
                }, function() {
                    $state.go('^');
                });
            }]
        })
          .state('contrat-detail.services.newservice', {
            url: '/newservice',
            data: {
                authorities: ['ROLE_DATA_OPERATOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/contrat/contrat.services-dialog.html',
                    controller: 'ContratServicesDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity:  ['Contrat', function(Contrat) {
                        	return {
                            	contrat : {id:$stateParams.id}
                        		};
                        }]
                    }
                }).result.then(function(result) {                	
                    $state.go('contrat-detail.services',{id:result.contrat.id },{reload:true});
                }, function() { 	
                    $state.go('^');
                });
            }]
        })
        
        .state('contrat-detail.services.editService', {
            url: '/editService/{idLigneContrat}',
            data: {
                authorities: ['ROLE_DATA_OPERATOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/contrat/contrat.services-dialog.html',
                    controller: 'ContratServicesDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: { 
                        entity: ['LigneContrat', function(LigneContrat) {
                            return LigneContrat.get({id : $stateParams.idLigneContrat});
                        }]
                    }
                }).result.then(function(result) {                	
                    $state.go('contrat-detail.services',{id:$stateParams.id},{reload:true});
                }, function() {
                    $state.go('contrat-detail.services');
                });
            }]
        })
           .state('contrat-detail.services.delete', {
            url: '/{idLignecontrat}/delete',
            data: {
                authorities: ['ROLE_AREA_MANAGEMENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/contrat/service-contrat-delete-dialog.html',
                    controller: 'ServiceContratDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['LigneContrat', function(LigneContrat) {
                            return LigneContrat.get({id : $stateParams.idLignecontrat});
                        }]
                    }
                }).result.then(function(result) {
                    $state.go('contrat-detail.services',null,{reload:true});
                }, function() {
                    $state.go('^');
                });
            }]
        })

        .state('contrat.delete', {
            parent: 'contrat',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_AREA_MANAGEMENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/contrat/contrat-delete-dialog.html',
                    controller: 'ContratDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Contrat', function(Contrat) {
                            return Contrat.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('contrat', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('contrat-detail.factures.print', {
        	url: '/print',
        	data: {
        		authorities: ['ROLE_AREA_MANAGEMENT','ROLE_DATA_OPERATOR']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/business/contrat/print-contrat-dialog.html',
        			controller: 'PrintContratDialogController',
        			controllerAs: 'vm',
        			size: 'md',
        			resolve: {
        				entity: {
        					id:$stateParams.id
        				}
        			}
        		}).result.then(function() {
                    $state.go('contrat-detail.factures',{id:$stateParams.id},{reload:true});
                }, function() {
                    $state.go('^');
                });
        	}]
        })
        .state('contrat-detail.factures.generatePaymentRecoveryNotice', {
        	url: '/generate-payment-recovery-notice',
        	data: {
        		authorities: ['ROLE_AREA_MANAGEMENT','ROLE_DATA_OPERATOR']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/business/contrat/generate-payment-recoverynotice-dialog.html',
        			controller: 'GeneratePaymentRecoveryNoticeController',
        			controllerAs: 'vm',
        			size: 'md',
        			resolve: {
        				entity: {
        					id:$stateParams.id
        				}
        			}
        		}).result.then(function() {
                    $state.go('contrat-detail.factures',{id:$stateParams.id},{reload:true});
                }, function() {
                    $state.go('^');
                });
        	}]
        })
        .state('contrat-detail.information.cloturer', {
            url: '/cloturer',
            data: {
                authorities: ['ROLE_AREA_MANAGEMENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/contrat/cloturation-contrat-dialog.html',
                    controller: 'CloturationContratDialogController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: {
                        	id:$stateParams.id
                        }
                    }
                }).result.then(function() {
                    $state.go('contrat-detail.information',{id:$stateParams.id},{reload:true});
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('contrat-detail.information.activer', {
            url: '/activer',
            data: {
                authorities: ['ROLE_AREA_MANAGEMENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/contrat/activation-contrat-dialog.html',
                    controller: 'ActivationContratDialogController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: {
                        	id:$stateParams.id
                        }
                    }
                }).result.then(function() {
                    $state.go('contrat-detail.information',{id:$stateParams.id},{reload:true});
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('contrat-detail.information.historique-changement-compteur', {
            url: '/histo-changement-compteur',
            data: {
                authorities: ['ROLE_DATA_OPERATOR','ROLE_READ_ONLY']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/contrat/historique-changement-compteur-dialog.html',
                    controller: 'HistoriqueChangementCompteurDialogController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        entity: ['Contrat', function(Contrat) {
                            return Contrat.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('contrat-detail.information',{id:$stateParams.id},{reload:true});
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('contrat-detail.information.add-water-correction', {
            url: '/add-water-correction',
            data: {
                authorities: ['ROLE_AREA_MANAGEMENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/contrat/contrat-addwatercorrection-dialog.html',
                    controller: 'ContratAddWaterCorrectionController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        entity: ['Contrat', function(Contrat) {
                            return Contrat.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('contrat-detail.information',{id:$stateParams.id},{reload:true});
                }, function() {
                    $state.go('^');
                });
            }]
        })
        
        .state('contrat-detail.information.changercompteur', {
            url: '/changercompteur',
            data: {
                authorities: ['ROLE_DATA_OPERATOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/contrat/changer-compteur-contrat-dialog.html',
                    controller: 'ChangerCompteurContratDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Contrat', function(Contrat) {
                            return Contrat.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function(result) {                	
                    $state.go('contrat-detail.information',{id:$stateParams.id},{reload:true});
                }, function() {
                    $state.go('contrat-detail.information');
                });
            }]
        })
        
        .state('contrat-detail.information.onlinePaymentCreate', {
            url: '/onlinepayment/new',
            data: {
                authorities: ['ROLE_DATA_OPERATOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/contrat/contract-online-payment-dialog.html',
                    controller: 'ContractOnlinePaymentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Contrat', function(Contrat) {
                            return Contrat.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function(result) {                	
                    $state.go('contrat-detail.information',{id:$stateParams.id},{reload:true});
                }, function() {
                    $state.go('contrat-detail.information');
                });
            }]
        })
        .state('contrat-detail.information.onlinePaymentDelete', {
            url: '/onlinepayment/{idOnlinepayment}/delete',
            data: {
                authorities: ['ROLE_DATA_OPERATOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/contrat/contract-online-payment-delete-dialog.html',
                    controller: 'ContractOnlinePaymentDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Contrat', function(Contrat) {
                            return Contrat.getOnlinePayment({idOnlinePayment : $stateParams.idOnlinepayment});
                        }]
                    }
                }).result.then(function(result) {
                    $state.go('contrat-detail.information',null,{reload:true});
                }, function() {
                    $state.go('^');
                });
            }]
        })

        .state('contrat-detail.services.billing', {
            url: '/billing',
            data: {
                authorities: ['ROLE_DATA_OPERATOR','ROLE_AREA_MANAGEMENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/contrat/billing-contract-dialog.html',
                    controller: 'BillingContractDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity:  ['Contrat', function(Contrat) {
                        	return {id : $stateParams.id};
                        }]
                    }
                }).result.then(function(result) {                	
                    $state.go('contrat-detail.services',{id:$stateParams.id},{reload:true});
                }, function() {
                    $state.go('contrat-detail.services');
                });
            }]
        })
        
        .state('contrat-detail.services.standby', {
            url: '/standby/{idLigneContrat}',
            data: {
                authorities: ['ROLE_DATA_OPERATOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/contrat/contrat-standby-dialog.html',
                    controller: 'ContratStandbyController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity:  ['LigneContrat', function(LigneContrat) {
                        	return {id :  $stateParams.idLigneContrat};
                        }]
                    }
                }).result.then(function(result) {                	
                    $state.go('contrat-detail.services',{id:$stateParams.id},{reload:true});
                }, function() {
                    $state.go('contrat-detail.services');
                });
            }]
        })
        
        .state('contrat-detail.services.reopen', {
            url: '/reopen/{idLigneContrat}',
            data: {
                authorities: ['ROLE_AREA_MANAGEMENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/contrat/contrat-reopen-dialog.html',
                    controller: 'ContratReopenController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity:  ['LigneContrat', function(LigneContrat) {
                        	return {id :  $stateParams.idLigneContrat};
                        }]
                    }
                }).result.then(function(result) {                	
                    $state.go('contrat-detail.services',{id:$stateParams.id},{reload:true});
                }, function() {
                    $state.go('contrat-detail.services');
                });
            }]
        })
        .state('contrat-detail.services.billOutstandingCredit', {
            url: '/bill-outstanding-credit/{idLigneContrat}',
            data: {
                authorities: ['ROLE_AREA_MANAGEMENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/contrat/contrat-billoutstandingcredit-dialog.html',
                    controller: 'ContratBillOutstandingCreditController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity:  ['LigneContrat', function(LigneContrat) {
                        	return {id :  $stateParams.idLigneContrat};
                        }]
                    }
                }).result.then(function(result) {                	
                    $state.go('contrat-detail.services',{id:$stateParams.id},{reload:true});
                }, function() {
                    $state.go('contrat-detail.services');
                });
            }]
        })
        
        
        .state('contrat-detail.services.paiementinitial', {
            url: '/paiementinitial/{idLigneContrat}',
            data: {
                authorities: ['ROLE_DATA_OPERATOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/contrat/paiement-initial-dialog.html',
                    controller: 'PaiementInitialDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['LigneContrat', function(LigneContrat) {
                            return LigneContrat.get({id : $stateParams.idLigneContrat});
                        }]
                    }
                }).result.then(function(result) {                	
                    $state.go('contrat-detail.services',{id:$stateParams.id},{reload:true});
                }, function() {
                    $state.go('contrat-detail.services');
                });
            }]
        })
        .state('contrat-detail.paiements.paiementagence', {
            url: '/services/{idContrat}',
            data: {
                authorities: ['ROLE_DATA_OPERATOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/contrat/paiement-agence-dialog.html',
                    controller: 'PaiementAgenceDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg'
                    ,
                    resolve: {
                        entity: ['Contrat', function(Contrat) {
                            return Contrat.get({id : $stateParams.idContrat});
                        }]
                    }
                }).result.then(function(result) {      
                    $state.go('contrat-detail.paiements',{id:$stateParams.id},{reload:true});
                }, function() {
                    $state.go('contrat-detail.paiements');
                });
            }]
        })
        
        
        .state('contrat-detail.paiements.deletePaiement', {
            url: '/payments/{idPaymentReceived}/delete',
            data: {
                authorities: ['ROLE_AREA_MANAGEMENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/contrat/paiement-agence-delete-dialog.html',
                    controller: 'PaymentReceivedDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: {
                        	id:$stateParams.idPaymentReceived
                        	
                        }
                    }
                }).result.then(function() {
                    $state.go('contrat-detail.services',{id:$stateParams.id},{reload:true});
                }, function() {
                    $state.go('^');
                });
            }]
        })
        
        
        .state('contrat-detail.services.addBalanceTransfer', {
            url: '/addbalancetransfer',
            data: {
                authorities: ['ROLE_DATA_OPERATOR']
            },        
               onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/contrat/transfer-balance-dialog.html',
                    controller: 'TransferBalanceDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: { 
                        entity: function () {
                            return {
                                amount: null,
                                contrat:{id:$stateParams.id} ,
                                id:null
                            };
                        }
                    }
                }).result.then(function(result) {                	
                    $state.go('contrat-detail.services',{id:$stateParams.id},{reload:true});
                }, function() {
                    $state.go('contrat-detail.services');
                });
            }]
        })
        .state('contrat-detail.services.ajoutPenaliteRemiseRegul', {
            url: '/ajoutpenaliteremiseregul/{type}/{idLigneContrat}',
            data: {
                authorities: ['ROLE_AREA_MANAGEMENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/contrat/impact-penalit-remise-regul-dialog.html',
                    controller: 'ImpactPenalitRemiseRegulDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: { 
                        entity: function () {
                            return {
                                montant: null,
                                id:null,
                                type: $stateParams.type,
                                contrat:{id:$stateParams.id} ,
                                ligneContrat: {id:$stateParams.idLigneContrat}
                            };
                        }
                    }
                }).result.then(function(result) {                	
                    $state.go('contrat-detail.services',{id:$stateParams.id},{reload:true});
                }, function() {
                    $state.go('contrat-detail.services');
                });
            }]
        })
        .state('contrat-detail.services.deletePenaliteRemiseRegul', {
            url: '/penaliteremiseregul/{idPenaliteRemiseRegul}/delete',
            data: {
                authorities: ['ROLE_AREA_MANAGEMENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/contrat/impact-penalit-remise-regul-delete-dialog.html',
                    controller: 'ImpactPenalitRemiseRegulDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ImpactPenalitRemiseRegul', function(ImpactPenalitRemiseRegul) {
                            return ImpactPenalitRemiseRegul.get({id : $stateParams.idPenaliteRemiseRegul});
                        }]
                    
                    }
                }).result.then(function() {
                    $state.go('contrat-detail.services',{id:$stateParams.id},{reload:true});
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }
})();
