'use strict';

angular.module('wamasysApp')
    .controller('HeaderController', function ($scope, $location,  $translate, $state, JhiLanguageService,Auth, Principal, ENV) {
        $scope.isAuthenticated = Principal.isAuthenticated;
        $scope.$state = $state;
        $scope.inProduction = ENV === 'prod';
        
        $scope.currentLanguage =  $translate.use();
    	
    
        var vm = this;
        vm.showteamwork = false;
        vm.searchContratHeader = '' ;

        vm.isAuthenticated = Principal.isAuthenticated;
        vm.username = "" ;
        vm.user = {} ;
        Principal.identity().then(function(account) {
        	if(account){
          	  vm.username = account.login;
              vm.user =account ;
              
              Principal.hasModule("TEAM").then(function (result) {
                  if (result) {
                	  vm.showteamwork =true;
                  } else {
                	  vm.showteamwork =false;
                  }
              });

        	}
        });
        
        $scope.logout = function () {
            Auth.logout();
            $state.go('home');
        };
        
        vm.searchContrat = function(){
        	  $state.go('contrat', {searchQuery:vm.searchContratHeader},null);
        }
    })
    .directive('myEnter', function () {
    return function (scope, element, attrs) {
        element.bind("keydown keypress", function (event) {
            if(event.which === 13) {
                scope.$apply(function (){
                    scope.$eval(attrs.myEnter);
                });

                event.preventDefault();
            }
        });
    };
});
    ;
