(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('UserManagementDialogController',UserManagementDialogController);

    UserManagementDialogController.$inject = ['$stateParams', '$uibModalInstance', 'entity', 'User', 'JhiLanguageService', 'Agence'];

    function UserManagementDialogController ($stateParams, $uibModalInstance, entity, User, JhiLanguageService, Agence) {
        var vm = this;

        vm.authorities = ['ROLE_ADMIN','ROLE_AREA_CONFIGURATION','ROLE_AREA_MANAGEMENT','ROLE_DATA_OPERATOR','ROLE_COLLECTOR','ROLE_SPECIFIC_PRICE','ROLE_READ_ONLY','ROLE_EVT_EDIT','ROLE_EVT_READ_ONLY','ROLE_BUDGET_READ_ONLY','ROLE_BUDGET_EDIT'];
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.user = entity;


        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        vm.agences = Agence.query();

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function save () {
            vm.isSaving = true;
            if (vm.user.id !== null) {
                User.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                User.save(vm.user, onSaveSuccess, onSaveError);
            }
        }
    }
})();
