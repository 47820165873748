(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ContratDetailController', ContratDetailController);

    ContratDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Contrat'];

    function ContratDetailController($scope, $rootScope, $stateParams, entity, Contrat  ) {
        var vm = this;
        vm.idContrat = $stateParams.id;
        vm.contrat = entity;
        vm.load = function (id) {
            Contrat.get({id: id}, function(result) {
                vm.contrat = result;
            });
        };
        var unsubscribe = $rootScope.$on('wamasysApp:contratUpdate', function(event, result) {
            vm.contrat = result;
        });
        $scope.$on('$destroy', unsubscribe);
        
        /*
        vm.portefeuillecontrats = PortefeuilleContrat.query();

        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:contratUpdate', result);
         //   $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.contrat.id !== null) {
                Contrat.update(vm.contrat, onSaveSuccess, onSaveError);
            } else {
                Contrat.save(vm.contrat, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
*/
        
    }
})();
