'use strict';

angular.module('wamasysApp').directive('jqGridCollectionList', function (CollectionListe,Principal,$compile,$filter) {
    var jqGridCounter = 0;

    return {
        replace: true,
        restrict: 'E',
        scope: {
            gridData: '=',
            collectionList: '='
        },
        template: '<div>' +
            '<table></table>' +
            '<div class="jqgrid-pagination"></div>' +
            '</div>',

        link: function (scope, element) {
            var gridNumber = jqGridCounter++;
            var wrapperId = 'jqgrid-' + gridNumber;
            element.attr('id', wrapperId);

            var tableId = 'jqgrid-table-' + gridNumber;
            var table = element.find('table');
            table.attr('id', tableId);
            
            var idAgence ;
            getIdAgence();
            function getIdAgence() {
                Principal.identity().then(function(account) {
                	scope.account = account;
                	if(account.agence){
                    	idAgence = account.agence.id
                	}
                });
            };

            
    		scope.downloadImage = function(idAgence,idCl,idContratCl) {
	
    		    CollectionListe.downloadSignature({
    				idAgence : idAgence,
    				idCl:idCl,
    				idContratCl:idContratCl,
    			}, function(result) {
    		          
                    var file = result.response;
    
                    var isChrome = !!window.chrome && !!window.chrome.webstore;
                    var isIE = /*@cc_on!@*/false || !!document.documentMode;
                    var isEdge = !isIE && !!window.StyleMedia;
    
    
                    if (isChrome){
                        var url = window.URL || window.webkitURL;
    
                        var downloadLink = angular.element('<a></a>');
                        downloadLink.attr('href',url.createObjectURL(file));
                        downloadLink.attr('target','_self');
                        downloadLink.attr('download', 'image.jpg');
                        downloadLink[0].click();
                    }
                    else if(isEdge || isIE){
                        window.navigator.msSaveOrOpenBlob(file,'image.jpg');
    
                    }
                    else {
                        var fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                    }
    
    			});
    		}
            
             var gridData = {
                     data: [],
                     colNames: ["id",
                                $filter('translate')('wamasysApp.contrat.referenceContrat'), 
                                $filter('translate')('wamasysApp.contrat.nomPersonne'), 
                                $filter('translate')('wamasysApp.collectionListe.service'), 
//                                $filter('translate')('wamasysApp.contrat.type'), 
//                                $filter('translate')('wamasysApp.contrat.dernierReleveCompteur'),  
                                $filter('translate')('wamasysApp.collectionListe.quantity'), 
                                $filter('translate')('wamasysApp.collectionListe.signature'), 
                                $filter('translate')('wamasysApp.collectionListe.montantAPayer'), 
                                $filter('translate')('wamasysApp.collectionListe.moneyBoxAvantPaiement'), 
                                $filter('translate')('wamasysApp.collectionListe.montantTotalAPayer'),
//                                $filter('translate')('wamasysApp.collectionListe.payeEnAgence'), 
                                $filter('translate')('wamasysApp.collectionListe.montantCollecte')
                                ],
                     colModel: [
                        {
                            name: 'id',
                            index: 'id',
                            editable: false,
                            hidden:true
                        },
                        
                        {
                            name: 'referenceContrat',
                            index: 'referenceContrat',
                            formatter: function (cellValue, options, rowObject) {
                                 var lien = '<a target="_blank"  href="#/contrat/' + rowObject.idContrat + '/dataanalyse">'+rowObject.referenceContrat+'</a>';
                                 return lien;
                            }
                        },
                        {
                            name: 'nomClient',
                            index: 'nomClient'
                        },
                        {
                            name: 'service',
                            index: 'service'
                        },
//                        {
//                            name: 'typeService',
//                            index: 'typeService'
//                        },
//                        {
//                            name: 'dernierRelevecompteur',
//                            index: 'dernierRelevecompteur'
//                        },
                        {
                            name: 'quantity',
                            index: 'quantity'
                        },
                        {
                            name: 'signature',
                            index: 'signature'
                            	
                        },
                        {
                            name: 'montantAPayerTtc',
                            index: 'montantAPayerTtc'
                        },
                        {
                            name: 'moneyBoxAvantPaiement',
                            index: 'moneyBoxAvantPaiement'
                        },
                        {
                            name: 'montantTotalAPayer',
                            index: 'montantTotalAPayer'
                        },
                         {
                             name: 'montantCollecte',
                             index: 'montantCollecte',
                             align: "right",
                             formatter:'number',
                             formatoptions: { decimalPlaces: 2, decimalSeparator: ".", thousandsSeparator: "" },
                             editrules:{required: true, number:true},
                             editable: true
                         }
                     ]
                 }


            
            
            /* */
            table.jqGrid({
                data : gridData.data,
                datatype : "local",
                pgbuttons : false,
                viewrecords : false,
                pgtext : "",
                pginput : false,
                rowNum : 10000,
                editurl : "clientArray",
                height : 'auto',
      
                colNames : gridData.colNames || [],
                colModel : gridData.colModel || [],
 
                cmTemplate : {sortable: false },
                autowidth : true,
                loadComplete: function() {
                    //compiling DOM after table load
                    $compile($('.clickMe'))(scope);
                //    $compile(angular.element('#' + newValue.id))(scope);
                },

            });

         	scope.$on('updateLigneCollectionListe',function(event, data){
                /*            */
                var dataArray = [];

                
                if(data && data.contratCollectionLists){
                    for (var i = 0; i < data.contratCollectionLists.length; i++) { 
                    
                    	if(data.contratCollectionLists[i].lignePaiementService ){
                    		

                            for (var x = 0; x < data.contratCollectionLists[i].lignePaiementService.length; x++) { 
                            	
                            
                        	  var dataReleve = {
                        	      id : data.contratCollectionLists[i].lignePaiementService[x].idLignePaiementService,

                        	      idContrat :  data.contratCollectionLists[i].contrat.id,
                        	      referenceContrat :  data.contratCollectionLists[i].contrat.referenceContrat,
                                  nomClient : data.contratCollectionLists[i].contrat.nomPersonne,
                                  service : data.contratCollectionLists[i].lignePaiementService[x].ligneContrat.serviceVendu.libelle  ,
//                                  typeService : data.contratCollectionLists[i].lignePaiementService[x].ligneContrat.serviceVendu.typeService.libelle ,
                                  quantity : data.contratCollectionLists[i].lignePaiementService[x].quantity ,
                                  signature : null,
//                                  dernierRelevecompteur : data.contratCollectionLists[i].lignePaiementService[x].valeurCompteurReleve ,
                                  montantAPayerTtc :data.contratCollectionLists[i].lignePaiementService[x].montantAPayerTtc ,
                                  montantTotalAPayer: parseFloat(
                                		  parseFloat(data.contratCollectionLists[i].lignePaiementService[x].montantAPayerTtc) - 
                                		  parseFloat(data.contratCollectionLists[i].lignePaiementService[x].moneyboxAvantPaiement )).toFixed(2),
                                  dateDebutPeriode :  data.contratCollectionLists[i].date,
                                  moneyBoxAvantPaiement :  data.contratCollectionLists[i].lignePaiementService[x].moneyboxAvantPaiement ,
                                  montantCollecte :  data.contratCollectionLists[i].lignePaiementService[x].montantPayeCollecteur 
                        	  }
                        	  
                        	  if(data.contratCollectionLists[i].msgPourEncodeur && data.contratCollectionLists[i].msgPourEncodeur ===true){
                        		  dataReleve.referenceContrat = 
                        			  	'<span title="'
                        			  		+data.contratCollectionLists[i].textMsgPourEncodeur
                        			  	+ '"><span class="label bg-color-red txt-color-white" ><i class="fa fa-exclamation"></i>  </span> ' 
                        			  	+dataReleve.referenceContrat
                        			  	+'</span>';
                        	     
                        	  }
                        	  
                        	    if( data.contratCollectionLists[i].pathSignatureClient){
                        		 
                        	  dataReleve.signature = 
                        			  '<a class="clickMe" '
                        			  +'ng-click="downloadImage('+idAgence+','+data.id+','+data.contratCollectionLists[i].pathSignatureClient+')' 
                        			  +'">download</a>';
                        	
                        	  }
                        	  
                        	 
                        	  if("A_PAYER_AVEC_ECHELONNEMENT" == data.contratCollectionLists[i].lignePaiementService[x].ligneContrat.serviceVendu.typePaiement){
                        		  var montantBalanceCf =  parseFloat(data.contratCollectionLists[i].lignePaiementService[x].montantTotalAPayer)-
                        		  parseFloat(data.contratCollectionLists[i].lignePaiementService[x].montantTotalDejaPaye);
                        		  
                        		  
                        		  if(data.etat ==  "VALIDE"){
                        			  
                        			  if(data.contratCollectionLists[i].lignePaiementService[x].montantPayeCollecteur){
                        				  montantBalanceCf = parseFloat(montantBalanceCf) 
                        				  - parseFloat(data.contratCollectionLists[i].lignePaiementService[x].montantPayeCollecteur);
                        			  }
                        			  if(data.contratCollectionLists[i].lignePaiementService[x].montantPayeAgence){
                        				  montantBalanceCf = parseFloat(montantBalanceCf) 
                        				  - parseFloat(data.contratCollectionLists[i].lignePaiementService[x].montantPayeAgence);
                        				  
                        			  }
                        		  }
                        		 
                        		  dataReleve.service = dataReleve.service +" Credit Balance : "+montantBalanceCf.toFixed(2) ;
                        		  
                        	  }
                        	  
                              for (var z = 0; z < data.paymentCorrectionList.length; z++){
                              	if(data.paymentCorrectionList[z].ligneContratId === data.contratCollectionLists[i].lignePaiementService[x].ligneContrat.id){
                              		
                              	  
                              		dataReleve.referenceContrat =  
                      			  	'<span title="'+ "Payment correction : "+data.paymentCorrectionList[z].montantTtc +" on "+ dataReleve.montantCollecte +" paid."
                      			  	+ '"><span class="label bg-color-red txt-color-white" ><i class="fa fa-exclamation"></i>  </span> ' 
                      			  	+'</span>'
                      			  	+ dataReleve.referenceContrat ;

                              		dataReleve.montantCollecte =  (dataReleve.montantCollecte +data.paymentCorrectionList[z].montantTtc) ;
                              	}
                              }
                              
                          	  dataArray.push(dataReleve);
                            }
                        	  
                    	}
                    	
                     }

             	   
                }

         		table.jqGrid('setGridParam', { data: dataArray })
                .trigger('reloadGrid');
         	});

            element.find(".ui-jqgrid").removeClass("ui-widget ui-widget-content");
            element.find(".ui-jqgrid-view").children().removeClass("ui-widget-header ui-state-default");
            element.find(".ui-jqgrid-labels, .ui-search-toolbar").children().removeClass("ui-state-default ui-th-column ui-th-ltr");
            element.find(".ui-jqgrid-pager").removeClass("ui-state-default");
            element.find(".ui-jqgrid").removeClass("ui-widget-content");

            // add classes
            element.find(".ui-jqgrid-htable").addClass("table table-bordered table-hover");
            element.find(".ui-jqgrid-btable").addClass("table table-bordered table-striped");

            element.find(".ui-pg-div").removeClass().addClass("btn btn-sm btn-primary");
            element.find(".ui-icon.ui-icon-plus").removeClass().addClass("fa fa-plus");
            element.find(".ui-icon.ui-icon-pencil").removeClass().addClass("fa fa-pencil");
            element.find(".ui-icon.ui-icon-trash").removeClass().addClass("fa fa-trash-o");
            element.find(".ui-icon.ui-icon-search").removeClass().addClass("fa fa-search");
            element.find(".ui-icon.ui-icon-refresh").removeClass().addClass("fa fa-refresh");
            element.find(".ui-icon.ui-icon-disk").removeClass().addClass("fa fa-save").parent(".btn-primary").removeClass("btn-primary").addClass("btn-success");
            element.find(".ui-icon.ui-icon-cancel").removeClass().addClass("fa fa-times").parent(".btn-primary").removeClass("btn-primary").addClass("btn-danger");

            element.find(".ui-icon.ui-icon-seek-prev").wrap("<div class='btn btn-sm btn-default'></div>");
            element.find(".ui-icon.ui-icon-seek-prev").removeClass().addClass("fa fa-backward");

            element.find(".ui-icon.ui-icon-seek-first").wrap("<div class='btn btn-sm btn-default'></div>");
            element.find(".ui-icon.ui-icon-seek-first").removeClass().addClass("fa fa-fast-backward");

            element.find(".ui-icon.ui-icon-seek-next").wrap("<div class='btn btn-sm btn-default'></div>");
            element.find(".ui-icon.ui-icon-seek-next").removeClass().addClass("fa fa-forward");

            element.find(".ui-icon.ui-icon-seek-end").wrap("<div class='btn btn-sm btn-default'></div>");
            element.find(".ui-icon.ui-icon-seek-end").removeClass().addClass("fa fa-fast-forward");

            $(window).on('resize.jqGrid', function() {
               table.jqGrid('setGridWidth', $("#content").width());
            });


            $compile(element.contents())(scope);
        }
    }
});