(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('EntiteParenteDeleteController',EntiteParenteDeleteController);

    EntiteParenteDeleteController.$inject = ['$uibModalInstance', 'entity', 'EntiteParente'];

    function EntiteParenteDeleteController($uibModalInstance, entity, EntiteParente) {
        var vm = this;
        vm.entiteParente = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            EntiteParente.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
