(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('EventFormCategory', EventFormCategory);

    EventFormCategory.$inject = ['$resource'];

    function EventFormCategory ($resource) {
        var resourceUrl =  'api/event-form-categorys/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            
            'queryInclDeleted': {

                method: 'GET',
                isArray: true,
                params: {inclDeleted:true}
            },
            
            'update': { method:'PUT' },
            
          
            'getListSubCategory': {
                method: 'GET',
              	url :'api/event-form-categorys/:id/sub-category',
              	 isArray: true
            },

            'queryListSubCategoryInclDeleted': {
              	url :'api/event-form-categorys/:id/sub-category',
                method: 'GET',
                isArray: true,
                params: {inclDeleted:true}
            },
            'getSubCategory': {
                method: 'GET',
              	url :'api/event-form-categorys/sub-category/:id',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'createSubCategory': { method:'POST', 
              	url :'api/event-form-category/sub-category'
             },
             'updateSubCategory': { method:'PUT', 
               	url :'api/event-form-category/sub-category'
              },
              'deleteSubCategory': { method:'DELETE', 
                 url :'api/event-form-categorys/sub-category/:id'
              }
        });
    }
})();
