(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('EventFormSubCategoryDialogController', EventFormSubCategoryDialogController);

    EventFormSubCategoryDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'EventFormCategory','Principal','AlertService'];

    function EventFormSubCategoryDialogController ($scope, $stateParams, $uibModalInstance, entity, EventFormCategory,Principal,AlertService) {
        var vm = this;
        vm.eventFormSubCategory = entity;
        vm.load = function(id) {
        	EventFormCategory.get({id : id}, function(result) {
                vm.eventFormSubCategory = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:EventFormSubCategoryUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.customFieldReadLony =true;
        
        Principal.hasAuthority('ROLE_SUPER_ADMIN').then(function (result) {
            if (result) {
                vm.customFieldReadLony =false;
            } else {
                vm.customFieldReadLony =true;
            }
        });

        
        vm.save = function () {
        	//check the json
        	if(vm.eventFormSubCategory.customFields && vm.eventFormSubCategory.customFields.length >0){
        	    try {
        	        JSON.parse(vm.eventFormSubCategory.customFields);
        	    } catch (e) {
        	    	console.log(e);
        	    	console.log("json="+vm.eventFormSubCategory.customFields);
        	    	AlertService.error('entity.validation.pattern');
                    vm.isSaving = false;
                    return;
        	    }
        	}
        	
        	
        	
            vm.isSaving = true;
            if (vm.eventFormSubCategory.id !== null) {
            	EventFormCategory.updateSubCategory(vm.eventFormSubCategory, onSaveSuccess, onSaveError);
            } else {
            	EventFormCategory.createSubCategory(vm.eventFormSubCategory, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        
      
    }
})();
