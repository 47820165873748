(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('ConsommationEauContrat', ConsommationEauContrat);

    ConsommationEauContrat.$inject = ['$resource', 'DateUtils'];

    function ConsommationEauContrat ($resource, DateUtils) {
        var resourceUrl =  'api/consommation-eau-contrats/:id';

        return $resource(resourceUrl, {}, {
      	  'byContrat': { method: 'GET', url:'api//consommation-eau-contrats/contrat/:id', isArray: true}
        
          /*  'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.dateDebut = DateUtils.convertDateTimeFromServer(data.dateDebut);
                    data.dateFin = DateUtils.convertDateTimeFromServer(data.dateFin);
                    return data;
                }
            },
            'update': { method:'PUT' }*/
        });
    }
})();
