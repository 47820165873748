(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ContractOnlinePaymentDialogController', ContractOnlinePaymentDialogController);

    ContractOnlinePaymentDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Contrat','Principal','Agence'];

    function ContractOnlinePaymentDialogController ($scope, $stateParams, $uibModalInstance, entity,  Contrat,Principal,Agence) {
        var vm = this;
        vm.onlinePayment = {contrat : entity, active :true} ;
        vm.isSaving = false;
        
        Principal.identity().then(function(account) {
        	if(account){
        		vm.onlinePaymentConf =  Agence.getOnlinePaymentConf({idArea:account.agence.id});
        	}
        });

        var onSaveSuccess = function (result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            
            Contrat.addOnlinePayment (vm.onlinePayment, onSaveSuccess, onSaveError);
            
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();
