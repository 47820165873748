(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('PortefeuilleContrat', PortefeuilleContrat);

    PortefeuilleContrat.$inject = ['$resource','DateUtils'];

    function PortefeuilleContrat ($resource,DateUtils) {
        var resourceUrl =  'api/portefeuille-contrats/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.autoInvoiceNextDate = DateUtils.convertLocalDateFromServer(data.autoInvoiceNextDate);
                    data.autoBillingNextDate = DateUtils.convertLocalDateFromServer(data.autoBillingNextDate);
                    return data;
                }
            },
            'update': { method:'PUT' ,
                transformRequest: function (data) {
                	var clonedData = Object.assign({}, data);
                	clonedData.autoInvoiceNextDate = DateUtils.convertLocalDateToServer(data.autoInvoiceNextDate);
                	clonedData.autoBillingNextDate = DateUtils.convertLocalDateToServer(data.autoBillingNextDate);
                    return angular.toJson(clonedData);
                }
            },
            'save': { method:'POST' ,
                transformRequest: function (data) {
                	var clonedData = Object.assign({}, data);
                	clonedData.autoInvoiceNextDate = DateUtils.convertLocalDateToServer(data.autoInvoiceNextDate);
                	clonedData.autoBillingNextDate = DateUtils.convertLocalDateToServer(data.autoBillingNextDate);
                    return angular.toJson(clonedData);
                }
            },
            'portefeuilleContratByCollecteur' :{
              	 method:'GET', 
              	isArray: true,
              	 url :'api/portefeuille-contrats-by-collector/:idCollector'
              }
        });
    }
})();

