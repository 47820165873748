(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('Contrat', Contrat);

    Contrat.$inject = ['$resource', 'DateUtils'];

    function Contrat ($resource, DateUtils) {
        var resourceUrl =  'api/contrats/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            
            
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.dateCloture = DateUtils.convertLocalDateFromServer(data.dateCloture);
                    data.dateActivation = DateUtils.convertLocalDateFromServer(data.dateActivation);
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                	var clonedData = Object.assign({}, data);
                	clonedData.dateCloture = DateUtils.convertLocalDateToServer(data.dateCloture);
                	clonedData.dateActivation = DateUtils.convertLocalDateToServer(data.dateActivation);
                    return angular.toJson(clonedData);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                	var clonedData = Object.assign({}, data);
                	clonedData.dateCloture = DateUtils.convertLocalDateToServer(data.dateCloture);
                	clonedData.dateActivation = DateUtils.convertLocalDateToServer(data.dateActivation);
                    return angular.toJson(clonedData);
                }
            },
//            'delete': {
//                method: 'DELETE',
//                transformRequest: function (data) {
//                    return angular.toJson(data);
//                }
//            },
            'cloturer': {
                method: 'POST',
              	url :'api/contrats/cloturer',
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            },
            'createCorrectionWaterConsumption': {
                method: 'POST',
              	url :'api/contrats/create-correction-water-consumption',
                transformRequest: function (data) {
                	var clonedData = Object.assign({}, data);
                 	clonedData.lastModifiedDate = DateUtils.convertDateTimeFromServer(data.lastModifiedDate);
                 	clonedData.dateDebut = DateUtils.convertDateTimeFromServer(data.dateDebut);
                 	clonedData.dateFin = DateUtils.convertDateTimeFromServer(data.dateFin);
                    return angular.toJson(clonedData);
                }
            },
            'activer': {
                method: 'POST',
              	url :'api/contrats/activer',
                transformRequest: function (data) {
                	var clonedData = Object.assign({}, data);
                	clonedData.dateActivation = DateUtils.convertLocalDateToServer(data.dateActivation);
                    return angular.toJson(clonedData);
                }
            },
            'downloadcsv': {
                method: 'GET',
                headers: {
                    accept: 'text/csv'
                },
                responseType: 'arraybuffer',
              	url :'/api/contrats/downloadcsv',
                transformResponse: function (data,headers) {
                	  var filename = headers('FileName');
                	  var contentType = headers('ContentType');
                    var csv;
                    if (data) {
                        csv = new Blob([data], {
                            type: contentType
                        });
                    }
                    return {
                        response: csv,
                        fileName : filename,
                        contentType : contentType
                    };
                }
            },
            'downloadServicecsv': {
                method: 'GET',
                headers: {
                    accept: 'text/csv'
                },
                responseType: 'arraybuffer',
              	url :'/api/contrats/downloadDetailServicecsv',
                transformResponse: function (data,headers) {
                	  var filename = headers('FileName');
                	  var contentType = headers('ContentType');
                    var csv;
                    if (data) {
                        csv = new Blob([data], {
                            type: contentType
                        });
                    }
                    return {
                        response: csv,
                        fileName : filename,
                        contentType : contentType
                    };
                }
            },
            

            'downloadDetailServiceNotClosedcsv': {
                method: 'GET',
                headers: {
                    accept: 'text/csv'
                },
                responseType: 'arraybuffer',
              	url :'/api/contrats/downloadDetailServiceNotClosedcsv',
                transformResponse: function (data,headers) {
                	  var filename = headers('FileName');
                	  var contentType = headers('ContentType');
                    var csv;
                    if (data) {
                        csv = new Blob([data], {
                            type: contentType
                        });
                    }
                    return {
                        response: csv,
                        fileName : filename,
                        contentType : contentType
                    };
                }
            },

            'downloadImpayeMobileMoney': {
                method: 'GET',
                headers: {
                    accept: 'text/csv'
                },
                responseType: 'arraybuffer',
              	url :'/api/contrats/downloadimpayemobilemoney',
                transformResponse: function (data,headers) {
                	  var filename = headers('FileName');
                	  var contentType = headers('ContentType');
                    var csv;
                    if (data) {
                        csv = new Blob([data], {
                            type: contentType
                        });
                    }
                    return {
                        response: csv,
                        fileName : filename,
                        contentType : contentType
                    };
                }
            },
            'addOnlinePayment': {
                method: 'POST',
              	url :'api/contrats/add-online-payment',
                transformRequest: function (data) {
                	var clonedData = Object.assign({}, data);
                 	clonedData.lastModifiedDate = DateUtils.convertDateTimeFromServer(data.lastModifiedDate);
                 	clonedData.deletedDate = DateUtils.convertLocalDateToServer(data.deletedDate);
                    return angular.toJson(clonedData);
                }
            },
            'getOnlinePayment': {
                method: 'GET',
              	url :'api/contrats/online-payment/:idOnlinePayment',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
//                    data.dateCloture = DateUtils.convertLocalDateFromServer(data.dateCloture);
//                    data.dateActivation = DateUtils.convertLocalDateFromServer(data.dateActivation);
                    return data;
                }
            },
	        'deleteOnlinePayment': {
		          method: 'DELETE',
	              url :'api/contrats/online-payment/:idOnlinePayment',
		          transformRequest: function (data) {
		              return angular.toJson(data);
		          }
		      },
            'getListOnlinePayment': {
                method: 'GET',
              	url :'api/contrats/:idContrat/online-payment',
             	isArray: true
            }
            
        });
    }
})();
