(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .directive('hasModule', hasModule);

    function hasModule(Principal) {
        var directive = {
            restrict: 'A',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs) {
            var module = attrs.hasModule.replace(/\s+/g, '');

//            var setVisible = function () {
//                   element.removeClass('hidden');
//                };
            var setHidden = function () {
                    element.addClass('hidden');
                };
            var   defineVisibility = function (reset) {

//                    if (reset) {
//                        setVisible();
//                    }

                    Principal.hasModule(module)
                        .then(function (result) {
                            if (result) {
//                                setVisible();
                            } else {
                                setHidden();
                            }
                        });
                };

            if (module.length > 0) {
                defineVisibility(false);

                scope.$watch(function() {
                    return Principal.isAuthenticated();
                }, function() {
                    defineVisibility(false);
                });
            }
        }
    }
    
    

    angular
        .module('wamasysApp')
        .directive('cssColIfHasModule', cssColIfHasModule);

    function cssColIfHasModule(Principal) {
        var directive = {
            restrict: 'A',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs) {
            var module = attrs.cssColIfHasModule.replace(/\s+/g, '');

            var setVisible = function () {
                element.addClass('col-md-8');
                element.removeClass('col-md-12');
                },
                setHidden = function () {
                    element.addClass('col-md-12');
                    element.removeClass('col-md-8');
                },
                defineVisibility = function (reset) {

                    if (reset) {
                        setVisible();
                    }

                    Principal.hasModule(module)
                        .then(function (result) {
                            if (result) {
                                setVisible();
                            } else {
                                setHidden();
                            }
                        });
                };

            if (module.length > 0) {
                defineVisibility(false);

                scope.$watch(function() {
                    return Principal.isAuthenticated();
                }, function() {
                    defineVisibility(false);
                });
            }
        }
    }
})();
