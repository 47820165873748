(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('Billing', Billing);

    Billing.$inject = ['$resource', 'DateUtils'];

    function Billing ($resource, DateUtils) {
        var resourceUrl =  'api/billing';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'createForContract': {
                method: 'POST',
              	url :'/api/billing/contract',
                transformRequest: function (data) {
                	var clonedData = Object.assign({}, data);
              //  	clonedData.dateFacturation = DateUtils.convertLocalDateToServer(data.dateFacturation);
                    return angular.toJson(clonedData);
                }
            },
            'createForPortfolio': {
                method: 'POST',
              	url :'/api/billing/portfolio',
                transformRequest: function (data) {
                	var clonedData = Object.assign({}, data);
             //   	clonedData.dateFacturation = DateUtils.convertLocalDateToServer(data.dateFacturation);
                    return angular.toJson(clonedData);
                }
            },
            'savePayement': {
                method: 'PUT',
              	url :'/api/billing/payment',
//                transformRequest: function (data) {
//                    data.fromDate = DateUtils.convertLocalDateToServer(data.fromDate);
//                    return angular.toJson(data);
//                }
            },
            'billOutstandingCredit': {
                method: 'POST', 
                url :'api/billing/:idLigneContrat/bill_outstanding_credit'
            },
            
            'saveBalanceTransfer': {
                method: 'PUT',
              	url :'/api/billing/balance_transfer',
//                transformRequest: function (data) {
//                    data.fromDate = DateUtils.convertLocalDateToServer(data.fromDate);
//                    return angular.toJson(data);
//                }
            },
            'deletePayment': {
                method: 'DELETE',
              	url :'/api/billing/payment/:id'
            }
            
        });
    }
})();
