(function() {
    'use strict';

//    var srcIFrame= "https://c4d9c071efbd4c70bcb88dc3b740506e.eu-west-1.aws.found.io:9243/app/kibana#/dashboard/";

    
    var myIframe = {
              bindings: {
              	  height: '@',
              	  width: '@',
              	  scrolling: '@',
              	  iddashboard:'@',
              	  endurl:'@',
              	  idgotodashboard:'@',
              	  idcontrat:'@',
              	  filtreagence:'@',
              	  periodkibana :'@'
                },
    	      template: '<iframe id="iframeelk" class="frame" height="{{$ctrl.height}}" width="{{$ctrl.width}}" '
    	    	  +'frameborder="0" border="0" marginwidth="0" marginheight="0" scrolling="{{$ctrl.scrolling}}" src="{{embedUrl}}"></iframe>',

              controller: myIframeController
    };
    
    myIframeController.$inject  = ['$scope', '$sce','Principal','$timeout']; 


    function myIframeController($scope, $sce,Principal,$timeout){
    	var ctrl = this;
    	
    	
    	if(Principal.isIdentityResolved()){
    		
	    	var account= Principal.getIdentityIfExist();
	    	var srcIFrame= account.kibanaUrl;
	    	
	    	if(ctrl.endurl){
	    		srcIFrame= srcIFrame+ctrl.endurl;
	    	}else{
	    	  	if(ctrl.iddashboard){
		    		srcIFrame= srcIFrame+"/app/kibana#/dashboard/"+ctrl.iddashboard ;
		    	}else if(ctrl.idgotodashboard){
		    		//FIXME Attention les short URL (goto) ne marchent pas bien avec le embed donc on ne l'utilise pas pour l'instant
		    		srcIFrame= srcIFrame+"/goto/"+ctrl.idgotodashboard ;
		    	}
	    	}
	    	
	  
        	
        

     	    if(ctrl.periodkibana && ctrl.periodkibana == 'true'){
     	    	srcIFrame = srcIFrame+"?embed=true&show-time-filter=true";
     	    }else{
     	    	//now-1y%2Fy
      	    	var dateFrom='now-12M%2FM';//2018-03-26 09:34:12.715
      	    	var dateTo ='now-1d';//now-1w%2Fy
      	    	srcIFrame = srcIFrame
      	    	  +"?embed=true&show-time-filter=true"
      	    	  +"&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:"+
      	    	  dateFrom+",interval:'1M',mode:quick,timezone:Europe%2FBerlin,to:"+
      	   	      dateTo +"))" ;
     	    }
         	 
         	  
           	//Filtre sur contrat
     	    if(ctrl.idcontrat){
     		  srcIFrame = srcIFrame+ "&_a=(description:'',filters:!(('$state':(store:appState)," +
  			"meta:(alias:!n,disabled:!f,index:'2e040d90-4312-11e8-81c3-b55237bddc5a'," +
      		"key:contractNumber,negate:!f,"  +
      		"params:(query:'"+ctrl.idcontrat+"'),type:phrase)," +
      		"query:(match_phrase:(contractNumber:'"+ctrl.idcontrat+"'))))" +
      		",query:(language:lucene,query:''))";
     		  
 
     	    }
    

     	    
     	    
     	    
     	    
         	// filtre sur une agence.
           	if(ctrl.filtreagence && ctrl.filtreagence=='true'){

      	 		//On fait un timeout sinon ça pose un problème avec laffichage du menu latéral
//      	 		$timeout(function(){
      	 			
            	if(account.agence){
            		ctrl.currentCodeAgence = account.agence.codeAgence;
            		ctrl.currentAreaName = account.agence.nom;
            		if(account.agence.waterNetwork){
            			ctrl.currentListWaterNetwork = account.agence.waterNetwork.map(function(item) {
            				  return "'" + item.label+ "'";
            			}).join(",");
            		}

            	}
           		ctrl.titreRequete = "Area : "+ctrl.currentAreaName +" %26 Water network : "+ctrl.currentListWaterNetwork.replaceAll("'","");
           		
            	/** Ancienne requête ne prend pas en compte les réseaux d'eau */ 
//            	srcIFrame = srcIFrame+ "&_a=(description:'',filters:!(('$state':(store:appState)," +
//            			"meta:(alias:!n,disabled:!f,index:'2e040d90-4312-11e8-81c3-b55237bddc5a'," +//
//			      		"key:areaName,negate:!f,"  +
//			      		"params:(query:'"+ctrl.currentAreaName+"'),type:phrase)," +
//			      		"query:(match_phrase:(areaName:'"+ctrl.currentAreaName+"'))))" +
//			      		",query:(language:lucene,query:''))";

            	/** ancien format n'affiche pas la zone filtre */ 
            	//srcIFrame = srcIFrame+ "&_a=(query:(bool:(should:!((term:(areaName:'"+ctrl.currentAreaName+"')),(bool:(should:!((terms:(waterNetworkLabel.keyword:!("+ctrl.currentListWaterNetwork +"))))))))))";
		
            	
            	srcIFrame = srcIFrame+ "&_a=(description:'',filters:!(('$state':(store:appState)," +
            			"meta:(alias:'"+ctrl.titreRequete+"',disabled:!f,index:'2e040d90-4312-11e8-81c3-b55237bddc5a'," +
            			"key:query,negate:!f,type:custom),query:(bool:(should:!((term:(areaName:'"+ctrl.currentAreaName+"'))," +
            			"(bool:(should:!((terms:(waterNetworkLabel.keyword:!("+ctrl.currentListWaterNetwork +")))))))))))," +
            			"query:(language:lucene,query:''),tags:!(),timeRange:(from:now-12M,to:now))"

            	
            	$scope["embedUrl"] = $sce.trustAsResourceUrl(srcIFrame);
      
           	}else{
           		$scope["embedUrl"] = $sce.trustAsResourceUrl(srcIFrame);
           	}

    	}
        
       
         
      }
  
    angular
        .module('wamasysApp')
        .component('myIframe', myIframe);

    
})();