(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('AgenceDetailController', AgenceDetailController);

    AgenceDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Agence', 'EntiteParente'];

    function AgenceDetailController($scope, $rootScope, $stateParams, entity, Agence, EntiteParente) {
        var vm = this;
        vm.agence = entity;
        vm.load = function (id) {
            Agence.get({id: id}, function(result) {
                vm.agence = result;
            });
        };
        var unsubscribe = $rootScope.$on('wamasysApp:agenceUpdate', function(event, result) {
            vm.agence = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
