(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ServiceVenduDialogController', ServiceVenduDialogController);

    ServiceVenduDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'ServiceVendu',  'TypeService','TypeTax', 'ModeleContrat'];

    function ServiceVenduDialogController ($scope, $stateParams, $uibModalInstance, entity, ServiceVendu, TypeService,TypeTax, ModeleContrat) {
        var vm = this;
        vm.serviceVendu = entity;
        vm.typeTaxs = TypeTax.query({size:100});
//        vm.typeservices = TypeService.query({size:100});

        TypeService.query({size:100}, function(result) {
        	vm.typeservices = result;
        	entity.$promise.then(function(value) {
	        	if( vm.serviceVendu.typeService){
	        		var  fnctIdEqual = function(element) {return element.id === vm.serviceVendu.typeService.id};
	        		var index = vm.typeservices.findIndex(fnctIdEqual);
	        		if(index === -1 ){
	        			vm.typeservices.push(vm.serviceVendu.typeService);
	        		}
	        	}
        	});
        });
        
        vm.modelecontrats = ModeleContrat.query();
        vm.load = function(id) {
            ServiceVendu.get({id : id}, function(result) {
                vm.serviceVendu = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:serviceVenduUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.serviceVendu.id !== null) {
                ServiceVendu.update(vm.serviceVendu, onSaveSuccess, onSaveError);
            } else {
                ServiceVendu.save(vm.serviceVendu, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
