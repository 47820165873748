(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ReportingTaxeController', ReportingTaxeController);

    ReportingTaxeController.$inject = ['$scope', '$state', '$filter', 'ParseLinks', 'AlertService', 'ReportingTaxe'];

    function ReportingTaxeController ($scope, $state, $filter,  ParseLinks, AlertService, ReportingTaxe) {
        var vm = this;
        vm.search=search;
        vm.date = {
                startDate: $state.params.startDate?new Date($state.params.startDate):null,
                endDate: $state.params.endDate?new Date($state.params.endDate):null

        };


        function onSuccess(data, headers) {
            vm.reporting = data;
        }
        function onError(error) {
            AlertService.error(error.data.message);
        }
        
        function search () {
            if ( vm.date.startDate && vm.date.endDate) {
	        	ReportingTaxe.query({
	        		fromDate:$filter('date')(new Date(vm.date.startDate), "yyyy-MM-dd"),
	        		toDate:$filter('date')(new Date(vm.date.endDate), "yyyy-MM-dd")
	            }, onSuccess, onError);
            }

        }

	      
        $scope.openRangeDateTimePicker = function() {
				$('#daterange1').data('daterangepicker').toggle();
		}

        
		$scope.getTotalFacture = function() {
			var total = 0;
			if (vm.reporting != undefined ) {
				for (var i = 0; i < vm.reporting.length; i++) {
					var service = vm.reporting[i];
					total += service.totalFacture ;
				}

			}
			return total;
		}
		

		$scope.getTotalEncaisseTtc= function() {
			var total = 0;
			if (vm.reporting != undefined ) {
				for (var i = 0; i < vm.reporting.length; i++) {
					var service = vm.reporting[i];
					total += service.totalEncaisseTtc ;
				}

			}
			return total;
		}

		$scope.getTotalTaxEncaisse= function() {
			var total = 0;
			if (vm.reporting != undefined ) {
				for (var i = 0; i < vm.reporting.length; i++) {
					var service = vm.reporting[i];
					total += (service.totalEncaisseTtc-service.totalEncaisseHt) ;
				}

			}
			return total;
		}
		

		$scope.getTotalTaxFacture = function() {
			var total = 0;
			if (vm.reporting != undefined ) {
				for (var i = 0; i < vm.reporting.length; i++) {
					var service = vm.reporting[i];
					total += service.totalTaxFacture ;
				}

			}
			return total;
		}

		$scope.getTotalWindFallGainTtc = function() {
			var total = 0;
			if (vm.reporting != undefined ) {
				for (var i = 0; i < vm.reporting.length; i++) {
					var service = vm.reporting[i];
					total += service.totalWindFallGainTtc ;
				}

			}
			return total;
		}

		$scope.getTotalWindFallGainTax = function() {
			var total = 0;
			if (vm.reporting != undefined ) {
				for (var i = 0; i < vm.reporting.length; i++) {
					var service = vm.reporting[i];
					total += service.totalWindFallGainTax ;
				}

			}
			return total;
		}

//    	vm.download = function(){
//    		ReportingTaxe.downloadcsv( {
//        		fromDate:$filter('date')(new Date(vm.date.startDate), "yyyy-MM-dd"),
//        		toDate:$filter('date')(new Date(vm.date.endDate), "yyyy-MM-dd")}
//    			,function (content){
//    		      var url = URL.createObjectURL(content.response);
//    		      var a = document.createElement('a');
//    		      a.href = url;
//    		      a.download = content.fileName;
//    		      a.target = '_blank';
//    		      a.click();
//    		}
//    				
//    		);
//    	}

	}
})();
