(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ContratCreateDialogController', ContratDialogController);

    ContratDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Contrat',  'ContractType','ContractProfil','PortefeuilleContrat', 'ModeleContrat','IndividualMeter'];

    function ContratDialogController ($scope, $stateParams, $uibModalInstance, entity, Contrat, ContractType,ContractProfil, PortefeuilleContrat,ModeleContrat,IndividualMeter) {
        var vm = this;
        vm.contrat = entity;
        vm.portefeuillecontrats = PortefeuilleContrat.query({ offset: 0,size: 1000});
        vm.modeleContrats = ModeleContrat.query();
        vm.contractTypes = ContractType.query();
        vm.contractProfils = ContractProfil.query();
        vm.individualmeters = IndividualMeter.queryNonSupprimeNonUtilise({ offset: 0,size: 1000});

        vm.load = function(id) {
            Contrat.get({id : id}, function(result) {
                vm.contrat = result;
            	vm.updateCoord ();
                
            });
        };
        


        vm.updateCoord = function(){
                 	
	        angular.extend($scope, {
	            defaults: {
	                scrollWheelZoom: false
	            },
	            markers: {
	            	contrat: {
	                    lat: vm.contrat.lat,
	                    lng: vm.contrat.lon,
//	                    message: "This is Madrid. But you can drag me to another position",
	                    focus: true,
	                    draggable: true
	                }
	            }
	        })
        }
 
        
        if(entity && entity.$promise){
            entity.$promise.then(function(value) {
            	vm.updateCoord ();
            });	
        }else{
        	vm.updateCoord();
        }

        $scope.$on("leafletDirectiveMarker.dragend", function(event, args){
        	 vm.contrat.lat = args.model.lat;
        	 vm.contrat.lon = args.model.lng;
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:contratUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.contrat.id !== null) {
                Contrat.update(vm.contrat, onSaveSuccess, onSaveError);
            } else {
                Contrat.save(vm.contrat, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateCloture = false;
        vm.datePickerOpenStatus.dateActivation = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
