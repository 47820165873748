(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ReportingPaymentArrearsController', ReportingPaymentArrearsController);

    ReportingPaymentArrearsController.$inject = ['$q','$scope', '$state', '$filter', 'Contrat','Principal', 'ParseLinks','$timeout', 'AlertService', 'pagingParams', 'paginationConstants','PortefeuilleContrat','ContratSearch','ContractType','ContractProfil','ServiceVendu'];

    function ReportingPaymentArrearsController ($q,$scope, $state, $filter, Contrat,Principal, ParseLinks,$timeout, AlertService, pagingParams, paginationConstants,PortefeuilleContrat,ContratSearch,ContractType,ContractProfil,ServiceVendu) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.etat = $state.params.etat;
        
        vm.portefeuilleContrats = PortefeuilleContrat.query({ offset: 0,size: 1000,sort: 'libelle,asc'});
        vm.contractTypes = ContractType.query({ offset: 0,size: 1000,sort: 'label,asc'});
        vm.contractProfils = ContractProfil.query({ offset: 0,size: 1000,sort: 'libelle,asc'});
        vm.serviceVendus = ServiceVendu.queryInclDeleted({ offset: 0,size: 1000,sort: 'libelle,asc'});


        if($state.params.portefeuilleContrat){
//        	vm.portefeuilleContrat = {
//        			id:$state.params.portefeuilleContrat
//        	}
        	vm.portefeuilleContrat = [];
        	$state.params.portefeuilleContrat.split(",").map(
    			function(elem){ 
    				vm.portefeuilleContrat.push({	id:elem});
    		});
        			
	    	if(vm.portefeuilleContrats && vm.portefeuilleContrats.$promise){
	    		vm.portefeuilleContrats.$promise.then(function(value) {
        	    	vm.portefeuilleContratNotPromise = [];
		        	$state.params.portefeuilleContrat.split(",").map(
		        			function (item) { 
		        				vm.portefeuilleContratNotPromise.push(
			    						value.find(function(obj) {
			    							return obj.id == item
			    						})
			    				);
			    			}
		        		);
			    	});	
		          }
        }
        if($state.params.contractType){
        	vm.contractType = [];
        	$state.params.contractType.split(",").map(
    			function(elem){ 
    				vm.contractType.push({	id:elem});
    		});
        			
	    	if(vm.contractTypes && vm.contractTypes.$promise){
	    		vm.contractTypes.$promise.then(function(value) {
        	    	vm.contractTypeNotPromise = [];
		        	$state.params.contractType.split(",").map(
		        			function (item) { 
		        				vm.contractTypeNotPromise.push(
			    						value.find(function(obj) {
			    							return obj.id == item
			    						})
			    				);
			    			}
		        		);
			    	});	
		          }
        }
        if($state.params.contractProfil){
        	vm.contractProfil = [];
        	$state.params.contractProfil.split(",").map(
    			function(elem){ 
    				vm.contractProfil.push({	id:elem});
    		});
        			
	    	if(vm.contractProfils && vm.contractProfils.$promise){
	    		vm.contractProfils.$promise.then(function(value) {
        	    	vm.contractProfilNotPromise = [];
		        	$state.params.contractProfil.split(",").map(
		        			function (item) { 
		        				vm.contractProfilNotPromise.push(
			    						value.find(function(obj) {
			    							return obj.id == item
			    						})
			    				);
			    			}
		        		);
			    	});	
		          }        	
        }
      
        
        if($state.params.serviceVendu){
        	vm.serviceVendu = [];
        	$state.params.serviceVendu.split(",").map(
    			function(elem){ 
    				vm.serviceVendu.push({	id:elem});
    		});
        			
	    	if(vm.serviceVendus && vm.serviceVendus.$promise){
	    		vm.serviceVendus.$promise.then(function(value) {
        	    	vm.serviceVenduNotPromise = [];
		        	$state.params.serviceVendu.split(",").map(
		        			function (item) { 
		        				vm.serviceVenduNotPromise.push(
			    						value.find(function(obj) {
			    							return obj.id == item
			    						})
			    				);
			    			}
		        		);
			    	});	
		          }
        }
        
        

        if($state.params.agenceId){
        	vm.agenceId = [];
        	$state.params.agenceId.split(",").map(
        		function(elem){ 
        			vm.agenceId.push({	id:elem});
        	});
        }
        
        Principal.identity().then(function(account) {
        	if(account.agencesRattachement){
        		vm.agencesRattachement = account.agencesRattachement;

        		vm.agenceIdNotPromise = [];
        		if(vm.agenceId && vm.agenceId.length >0){
        			
		        	$state.params.agenceId.split(",").map(
	        			function (item) { 
	        				vm.agenceIdNotPromise.push(
	        						account.agencesRattachement.find(function(obj) {
		    							return obj.id == item
		    						})
		    				);
		    			}
	        		);
        		}
        	}
        	
        	

			  
        });

        vm.onlinePaymentType = 	$state.params.onlinePaymentType;
        vm.onlinePaymentQuery = $state.params.onlinePaymentQuery;
        vm.onlinePaymentActive =$state.params.onlinePaymentActive;
        vm.currentBalanceValue=$state.params.currentBalanceValue;
        vm.currentBalanceSigne=$state.params.currentBalanceSigne;

        vm.minAmountPaid=$state.params.minAmountPaid;
        vm.minAmountBilled=$state.params.minAmountBilled;
        vm.maxWaterConsumption=$state.params.maxWaterConsumption;
    	       
        vm.searchQuery = $state.params.searchQuery;
        vm.compteurFerme = $state.params.compteurFerme;
    	vm.date = {
                 startDate: $state.params.startDate?new Date($state.params.startDate):null,
                 endDate: $state.params.endDate?new Date($state.params.endDate):null

         }
    	
    	vm.anyBillSince = $state.params.anyBillSince?new Date($state.params.anyBillSince):null;
    	vm.dontPaidSince = $state.params.dontPaidSince?new Date($state.params.dontPaidSince):null;
        vm.toDatePayment = $state.params.toDatePayment?new Date($state.params.toDatePayment):null;
        vm.toDateBilling = $state.params.toDateBilling?new Date($state.params.toDateBilling):null;
        vm.toDateWaterConsumption = $state.params.toDateWaterConsumption?new Date($state.params.toDateWaterConsumption):null;
    	vm.tooMuchPaid = $state.params.tooMuchPaid=="true"?true:false; 
    	vm.filterBalanceWithService = $state.params.filterBalanceWithService=="true"?true:false; 
    	
    	
    	vm.contrats = [];
        vm.totalItems = 0;
        vm.queryCount = 0;
        vm.page = 1;
        
        vm.loadAll();
        
    	vm.download = function(){
    		Contrat.downloadcsv( {
    			agenceId:vm.agenceId?vm.agenceId.map(function(elem){ return elem.id;}).join(","):null,	 
        		nom: vm.searchQuery,
        		compteurFerme: vm.compteurFerme,
        		portefeuilleContrat:vm.portefeuilleContrat?vm.portefeuilleContrat.map(function(elem){ return elem.id;}).join(","):null,	
                //contractType:vm.contractType?vm.contractType.id:null,
        		contractType:vm.contractType?vm.contractType.map(function(elem){ return elem.id;}).join(","):null,		
                contractProfil:vm.contractProfil?vm.contractProfil.map(function(elem){ return elem.id;}).join(","):null,	
                serviceVendu:vm.serviceVendu?vm.serviceVendu.map(function(elem){ return elem.id;}).join(","):null,
        		etat:vm.etat,
        		fromDate:$filter('date')(vm.date.startDate, "yyyy-MM-dd"),
        		toDate:$filter('date')(vm.date.endDate, "yyyy-MM-dd"),
        		onlinePaymentType:vm.onlinePaymentType,
        		onlinePaymentQuery:vm.onlinePaymentQuery,
        		onlinePaymentActive:vm.onlinePaymentActive,
    			anyBillSince:$filter('date')(vm.anyBillSince, "yyyy-MM-dd"),
        		dontPaidSince:$filter('date')(vm.dontPaidSince, "yyyy-MM-dd"),
        		currentBalanceSigne:vm.currentBalanceSigne,
        		currentBalanceValue:vm.currentBalanceValue,
        		tooMuchPaid:vm.tooMuchPaid,
        		filterBalanceWithService:vm.filterBalanceWithService,
        		minAmountPaid:vm.minAmountPaid,
                toDatePayment:$filter('date')(vm.toDatePayment, "yyyy-MM-dd"),
                minAmountBilled:vm.minAmountBilled,
        		toDateBilling:$filter('date')(vm.toDateBilling, "yyyy-MM-dd"),
        		maxWaterConsumption:vm.maxWaterConsumption,
        		toDateWaterConsumption:$filter('date')(vm.toDateWaterConsumption, "yyyy-MM-dd"),

        		}
    			,function (content){
    		      var url = URL.createObjectURL(content.response);
    		      var a = document.createElement('a');
    		      a.href = url;
    		      a.download = content.fileName;
    		      a.target = '_blank';
    		      a.click();
    		}
    				
    		);
    	}


//
//       	vm.downloadService = function(){
//    		Contrat.downloadServicecsv( {}
//    			,function (content){
//    		      var url = URL.createObjectURL(content.response);
//    		      var a = document.createElement('a');
//    		      a.href = url;
//    		      a.download = content.fileName;
//    		      a.target = '_blank';
//    		      a.click();
//    		}
//    				
//    		);
//    	}
//
//       	vm.downloadDetailServiceNotClosedcsv = function(){
//    		Contrat.downloadDetailServiceNotClosedcsv( {}
//    			,function (content){
//    		      var url = URL.createObjectURL(content.response);
//    		      var a = document.createElement('a');
//    		      a.href = url;
//    		      a.download = content.fileName;
//    		      a.target = '_blank';
//    		      a.click();
//    		}
//    				
//    		);
//    	}
//    
    


    
        
        function loadAll () {
            if (vm.searchQuery || vm.agenceId || vm.portefeuilleContrat  || vm.contractType    || vm.contractProfil  ||  vm.serviceVendu || vm.etat || vm.date.startDate || vm.date.endDate 
            		|| vm.compteurFerme	|| vm.onlinePaymentType	|| vm.onlinePaymentQuery	|| vm.onlinePaymentActive || vm.anyBillSince ||   vm.dontPaidSince ||vm.currentBalanceValue
            		|| vm.currentBalanceSigne ||vm.tooMuchPaid || vm.filterBalanceWithService|| vm.minAmountPaid || vm.toDatePayment || vm.minAmountBilled || vm.toDateBilling||vm.maxWaterConsumption||vm.toDateWaterConsumption) {


                vm.wait=true;
                vm.currentSearch=true;
            	ContratSearch.query({
            		agenceId: vm.agenceId?vm.agenceId.map(function(elem){ return elem.id;}).join(","):null, 
            		nom: vm.searchQuery,
            		compteurFerme: vm.compteurFerme,
            		portefeuilleContrat:vm.portefeuilleContrat?vm.portefeuilleContrat.map(function(elem){ return elem.id;}).join(","):null,
            //		contractType:vm.contractType?vm.contractType.id:null,
            		contractType:vm.contractType?vm.contractType.map(function(elem){ return elem.id;}).join(","):null,
            		contractProfil:vm.contractProfil?vm.contractProfil.map(function(elem){ return elem.id;}).join(","):null,
                    serviceVendu:vm.serviceVendu?vm.serviceVendu.map(function(elem){ return elem.id;}).join(","):null,
            		etat:vm.etat,
            		fromDate:$filter('date')(vm.date.startDate, "yyyy-MM-dd"),
            		toDate:$filter('date')(vm.date.endDate, "yyyy-MM-dd"),
            		onlinePaymentType:vm.onlinePaymentType,
            		onlinePaymentQuery:vm.onlinePaymentQuery,
            		onlinePaymentActive:vm.onlinePaymentActive,
            		anyBillSince:$filter('date')(vm.anyBillSince, "yyyy-MM-dd"),
            		dontPaidSince:$filter('date')(vm.dontPaidSince, "yyyy-MM-dd"),
            		currentBalanceSigne:vm.currentBalanceSigne,
            		currentBalanceValue:vm.currentBalanceValue,
            		tooMuchPaid:vm.tooMuchPaid,
            		filterBalanceWithService:vm.filterBalanceWithService,
            		minAmountPaid:vm.minAmountPaid,
            		toDatePayment:$filter('date')(vm.toDatePayment, "yyyy-MM-dd"),
            		minAmountBilled:vm.minAmountBilled,
            		toDateBilling:$filter('date')(vm.toDateBilling, "yyyy-MM-dd"),
            		maxWaterConsumption:vm.maxWaterConsumption,
            		toDateWaterConsumption:$filter('date')(vm.toDateWaterConsumption, "yyyy-MM-dd"),
                    page: pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
//            } else {
//                vm.currentSearch=false;
//	            Contrat.query({
//	                page: pagingParams.page - 1,
//	                size: paginationConstants.itemsPerPage,
//	                sort: sort()
//	            }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
              
//                if (vm.predicate === 'id') {
//                    result.push('id,desc');
//                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.wait=false;
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.contrats = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                vm.wait=false;
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                searchQuery: vm.searchQuery,
        		compteurFerme: vm.compteurFerme,
                startDate: vm.date.startDate,
                endDate: vm.date.endDate,
        		agenceId:vm.agenceIdNotPromise?vm.agenceIdNotPromise.map(function(elem){ return elem.id;}).join(","):null,
                contractType:vm.contractTypeNotPromise?vm.contractTypeNotPromise.map(function(elem){ return elem.id;}).join(","):null,
        		contractProfil:vm.contractProfilNotPromise?vm.contractProfilNotPromise.map(function(elem){ return elem.id;}).join(","):null,
        		serviceVendu:vm.serviceVenduNotPromise?vm.serviceVenduNotPromise.map(function(elem){ return elem.id;}).join(","):null,
                portefeuilleContrat: vm.portefeuilleContratNotPromise?vm.portefeuilleContratNotPromise.map(function(elem){ return elem.id;}).join(","):null,
                etat: vm.etat,
                currentSearch : vm.currentSearch,
        		onlinePaymentType:vm.onlinePaymentType,
        		onlinePaymentQuery:vm.onlinePaymentQuery,
        		onlinePaymentActive:vm.onlinePaymentActive,
        		currentBalanceSigne:vm.currentBalanceSigne,
        		currentBalanceValue:vm.currentBalanceValue,
        		anyBillSince:vm.anyBillSince,
        		dontPaidSince:vm.dontPaidSince,
        		tooMuchPaid:vm.tooMuchPaid,
        		filterBalanceWithService:vm.filterBalanceWithService,
        		minAmountPaid:vm.minAmountPaid,
                toDatePayment:vm.toDatePayment,
                minAmountBilled:vm.minAmountBilled,
        		toDateBilling:vm.toDateBilling,
        		maxWaterConsumption:vm.maxWaterConsumption,
        		toDateWaterConsumption:vm.toDateWaterConsumption,
            });
        }
        
        

        function search () {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '';
            vm.reverse = false;
            vm.currentSearch=true;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true; 
            vm.agenceId = null;
            vm.agenceIdNotPromise = null;
            vm.searchQuery= null;
    		 vm.compteurFerme = null;
             vm.date.startDate= null;
             vm.date.endDate= null;
             vm.portefeuilleContrat= null;
             vm.portefeuilleContratNotPromise= null;
             vm.contractType= null;
             vm.contractTypeNotPromise= null;
             vm.contractProfil= null;
             vm.contractProfilNotPromise= null;
             vm.serviceVendu= null;
             vm.serviceVenduNotPromise =null;
     		vm.onlinePaymentType =null;
    		vm.onlinePaymentQuery= null;
    		vm.onlinePaymentActive= null;
    		vm.anyBillSince =null;
    		vm.dontPaidSince =null;
    		vm.currentBalanceSigne =null;
    		vm.currentBalanceValue =null;
    		vm.tooMuchPaid =null;
    		vm.filterBalanceWithService =null;
    		vm.minAmountPaid =null;
            vm.toDatePayment =null;
            vm.minAmountBilled =null;
    		vm.toDateBilling =null;
            vm.etat= null;
    		vm.maxWaterConsumption =null;
            vm.toDateWaterConsumption= null;
            currentSearch : vm.currentSearch= null;
            vm.transition();
        }
        

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.anyBillSince = false;
        vm.datePickerOpenStatus.dontPaidSince = false;
        vm.datePickerOpenStatus.toDatePayment = false;
        vm.datePickerOpenStatus.toDateBilling = false;
        vm.datePickerOpenStatus.toDateWaterConsumption = false;


        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
        
        
        

        
        
        
    }
})();
