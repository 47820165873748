(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('ReportingAgence', ReportingAgence);

    ReportingAgence.$inject = ['$resource'];

    function ReportingAgence ($resource) {
        var resourceUrl =  'api/reporting-agence/search';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false},
            'summary': { 
              	url :'/api/reporting-agence/summary',
              	method: 'GET',
              	isArray: false
             },
            'download': {
                method: 'GET',
                headers: {
                    accept: 'text/csv'
                },
                responseType: 'arraybuffer',
              	url :'/api/reporting-agence/downloadcsv',
                transformResponse: function (data,headers) {
                	  var filename = headers('FileName');
                	  var contentType = headers('ContentType');
                    var csv;
                    if (data) {
                        csv = new Blob([data], {
                            type: contentType
                        });
                    }
                    return {
                        response: csv,
                        fileName : filename,
                        contentType : contentType
                    };
                }
            },
            'downloadCsvBilling': {
                method: 'GET',
                headers: {
                    accept: 'text/csv'
                },
                responseType: 'arraybuffer',
              	url :'/api/reporting-agence/download-billing',
                transformResponse: function (data,headers) {
                	  var filename = headers('FileName');
                	  var contentType = headers('ContentType');
                    var csv;
                    if (data) {
                        csv = new Blob([data], {
                            type: contentType
                        });
                    }
                    return {
                        response: csv,
                        fileName : filename,
                        contentType : contentType
                    };
                }
            },
            'downloadCsvFinancialAdjustement': {
                method: 'GET',
                headers: {
                    accept: 'text/csv'
                },
                responseType: 'arraybuffer',
              	url :'/api/reporting-agence/download-financial-adjustement',
                transformResponse: function (data,headers) {
                	  var filename = headers('FileName');
                	  var contentType = headers('ContentType');
                    var csv;
                    if (data) {
                        csv = new Blob([data], {
                            type: contentType
                        });
                    }
                    return {
                        response: csv,
                        fileName : filename,
                        contentType : contentType
                    };
                }
            },
            'downloadCsvPayments': {
                method: 'GET',
                headers: {
                    accept: 'text/csv'
                },
                responseType: 'arraybuffer',
              	url :'/api/reporting-agence/download-payment',
                transformResponse: function (data,headers) {
                	  var filename = headers('FileName');
                	  var contentType = headers('ContentType');
                    var csv;
                    if (data) {
                        csv = new Blob([data], {
                            type: contentType
                        });
                    }
                    return {
                        response: csv,
                        fileName : filename,
                        contentType : contentType
                    };
                }
            }
            
        });
        
    }
})();
