(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ContractProfilDetailController', ContractProfilDetailController);

    ContractProfilDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'ContractProfil'];

    function ContractProfilDetailController($scope, $rootScope, $stateParams, entity, ContractProfil) {
        var vm = this;
        vm.contractProfil = entity;
        vm.load = function (id) {
            ContractProfil.get({id: id}, function(result) {
                vm.contractProfil = result;
            });
        };
        var unsubscribe = $rootScope.$on('wamasysApp:contractProfilUpdate', function(event, result) {
            vm.contractProfil = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
