(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('Importation', Importation);

    Importation.$inject = ['$resource'];

    function Importation ($resource) {
        var resourceUrl =  'api/importation/';

        return $resource(resourceUrl, {}, {
            'uploadFile': { 
            	method: 'POST',
            	transformRequest: angular.identity,
                headers: {
                	'Content-Type': undefined
                }
            },
            'uploadFileUpdateContract': { 
            	method: 'POST',
            	url:'api/import_csv/update_contracts',
            	transformRequest: angular.identity,
                headers: {
                	'Content-Type': undefined
                }
            },
            'importBalanceTransfer': { 
            	method: 'POST',
            	url:'api/import_csv/import_balance_transfer',
            	transformRequest: angular.identity,
                headers: {
                	'Content-Type': undefined
                }
            },
            'importCreditNote': { 
            	method: 'POST',
            	url:'api/import_csv/import_credit_note',
            	transformRequest: angular.identity,
                headers: {
                	'Content-Type': undefined
                }
            },
            'importPaiementMobileMoney': { 
            	method: 'POST',
            	url:'api/import_csv/import_paiement_mobile_money',
            	transformRequest: angular.identity,
                headers: {
                	'Content-Type': undefined
                }
            }
            
            

        });
        
    }
})();
