(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('OrganisationDetailController', OrganisationDetailController);

    OrganisationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Organisation',  'User'];

    function OrganisationDetailController($scope, $rootScope, $stateParams, entity, Organisation,User) {
        var vm = this;
        vm.organisation = entity;
        vm.load = function (id) {
            Organisation.get({id: id}, function(result) {
                vm.organisation = result;
            });
        };
        var unsubscribe = $rootScope.$on('wamasysApp:organisationUpdate', function(event, result) {
            vm.organisation = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
