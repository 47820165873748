(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('CasNonReleveDetailController', CasNonReleveDetailController);

    CasNonReleveDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'CasNonReleve'];

    function CasNonReleveDetailController($scope, $rootScope, $stateParams, entity, CasNonReleve) {
        var vm = this;
        vm.casNonReleve = entity;
        vm.load = function (id) {
            CasNonReleve.get({id: id}, function(result) {
                vm.casNonReleve = result;
            });
        };
        var unsubscribe = $rootScope.$on('wamasysApp:casNonReleveUpdate', function(event, result) {
            vm.casNonReleve = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
