(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('CloturationContratDialogController', CloturationContratDialogController);

    CloturationContratDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Contrat','ClosingContractReason', 'TypeAvoir', 'TypeRemisecommerciale', 'LigneContrat'];

    function CloturationContratDialogController ($scope, $stateParams, $uibModalInstance, entity, Contrat,ClosingContractReason, TypeAvoir, TypeRemisecommerciale, LigneContrat) {
        var vm = this;
        vm.idContrat = entity.id;

        vm.closingContractReasons = ClosingContractReason.query({ offset: 0,size: 1000});
        
        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:impactPenalitRemiseRegulUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            Contrat.cloturer({id:vm.idContrat,closingContractReason:{id:vm.contrat.closingContractReason.id}}, onSaveSuccess, onSaveError);
            
        }; 

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };


    }
})();
