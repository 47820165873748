(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ActivationContratDialogController', ActivationContratDialogController);

    ActivationContratDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Contrat', 'TypeAvoir', 'TypeRemisecommerciale', 'LigneContrat'];

    function ActivationContratDialogController ($scope, $stateParams, $uibModalInstance, entity, Contrat, TypeAvoir, TypeRemisecommerciale, LigneContrat) {
        var vm = this;
        vm.idContrat = entity.id;
        vm.dateCloturation = new Date();

        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:impactPenalitRemiseRegulUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            Contrat.activer({id:vm.idContrat,dateActivation: vm.dateActivation}, onSaveSuccess, onSaveError);
            
        }; 

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateFinPeriode = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
        
    }
})();
