(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('OrganisationDialogController', OrganisationDialogController);

    OrganisationDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Organisation', 'User'];

    function OrganisationDialogController ($scope, $stateParams, $uibModalInstance, entity, Organisation, User) {
        var vm = this;
        vm.organisation = entity;
        vm.users = User.query({ offset: 0,size: 1000});
        vm.load = function(id) {
            Organisation.get({id : id}, function(result) {
                vm.organisation = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:organisationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.organisation.id !== null) {
                Organisation.update(vm.organisation, onSaveSuccess, onSaveError);
            } else {
                Organisation.save(vm.organisation, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
