(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$rootScope','$timeout','$location', '$state', 'Auth', 'Principal', 'ENV', 'LoginService'];

    function NavbarController ($rootScope,$timeout, $location, $state, Auth, Principal, ENV, LoginService) {
        var vm = this;

        vm.navCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.inProduction = ENV === 'prod';
        vm.login = login;
        vm.logout = logout;
        vm.$state = $state;

        function login () {
            LoginService.open();
        }

        function logout () {
            Auth.logout();
            $state.go('home');
        }
        
        vm.user = {} ;
        Principal.identity().then(function(account) {
        	  vm.user = account;

 			 if(vm.user.listDashboardKibana && typeof vm.user.listDashboardKibana === 'string'){
        		 try {
        			 vm.user.listDashboardKibana = JSON.parse(vm.user.listDashboardKibana);
        			 $timeout(function() {
        				$rootScope.$broadcast('refreshSmartMenu','refresh');
        			 });
        			 
         	    } catch (e) {
         	    	console.log(e);
         	    } 
			 }
 			 
        });
    }
})();
