(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('PortefeuilleContratDeleteController',PortefeuilleContratDeleteController);

    PortefeuilleContratDeleteController.$inject = ['$uibModalInstance', 'entity', 'PortefeuilleContrat'];

    function PortefeuilleContratDeleteController($uibModalInstance, entity, PortefeuilleContrat) {
        var vm = this;
        vm.portefeuilleContrat = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            PortefeuilleContrat.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
