(function() {
	'use strict';

	angular.module('wamasysApp').controller('AdminToolsController', AdminToolsController);

	AdminToolsController.$inject = [ '$scope', '$http', '$filter','$state', 'AdminTools', 'Organisation', 'AlertService', 'UserAdmin' ];

	function AdminToolsController($scope, $http, $filter,$state, AdminTools, Organisation,	AlertService, UserAdmin) {
		var vm = this;
		vm.numberOfContractByCluster = 50;
		vm.coefMultiplicateur = 1;
		vm.coefNbrYear = 1;
		vm.organisations = Organisation.query();
		vm.agences = UserAdmin.getAllAgency();

		vm.anonymiserArea = function() {
			if (vm.pasEnProd) {
				AdminTools.anonymiserArea({
					orgaId : vm.anomymisationOrgaId,
					areaId : vm.anomymisationAreaId,
					password : vm.password
				}, function (data){
					AlertService.success("success");
				}, function (error){
					AlertService.error(error.data.message);
				});
			}
		};


		vm.createAreaTest = function() {
				AdminTools.createAreaTest({
					orgaName : vm.orgaName,
					orgaId : vm.orgaId,
					password : vm.password,
					codeArea : vm.codeArea,
					codeLangue :vm.codeLangue,
					areaName : vm.areaName, 
					agenceName : vm.agenceName, 
					numberOfContractByCluster : vm.numberOfContractByCluster,
					coefMultiplicateur : vm.coefMultiplicateur,
					coefNbrYear : vm.coefNbrYear,
			}, function (data){
				AlertService.success("success");
			}, function (error){
				AlertService.error(error.data.message);
			});
		};
		
		
		vm.injecterJeuTesta = function() {
			if (vm.pasEnProd) {
				AdminTools.completerDonneesTest({
						idOrga : vm.anomymisationOrgaId,
						idAgence : vm.anomymisationAreaId
				}, function (data){
					AlertService.success("success");
				}, function (error){
					AlertService.error(error.data.message);
				});
			}
		};
		

		vm.initialiserroleareaelastic = function() {
			
			AdminTools.initialiserroleareaelastic({}, function (data){
				AlertService.success("success");
			}, function (error){
				AlertService.error(error.data.message);
			});
			
		};
		
		vm.exportEventsInElastic= function() {
			
			AdminTools.exportAllEventInElastic({}, function (data){
				AlertService.success("success");
			}, function (error){
				AlertService.error(error.data.message);
			});
			
		};

		vm.majTeamwork = function() {
			
			AdminTools.majTeamwork({idOrga : vm.teamworkOrgaId}, function (data){
				AlertService.success("success");
			}, function (error){
				AlertService.error(error.data.message);
			});
			
		};
		
		
        $scope.openRangeDateTimePicker = function() {
				$('#daterange1').data('daterangepicker').toggle();
		}

        
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.toDatecheckExport = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
        vm.datePeriod = {
                startDate: null,
                endDate: null
        };

		vm.exportElkCheckexport = function(){

		    var url = 'api/export/checkexport?toDate=' + $filter('date')(new Date(vm.toDatecheckExport), "yyyy-MM-dd");
		    $http.post(url).then(function(msg){
				AlertService.success("success, response:"+msg);
		    	alert("response:"+JSON.stringify(msg.data));
		    }); 
			
//			AdminTools.exportElkCheckexport( {
//				toDate:$filter('date')(new Date(vm.toDatecheckExport), "yyyy-MM-dd")
//    			}, function (data){
//				AlertService.success("success, response:"+data);
//				}, function (error){
//					AlertService.error(error.data.message);
//				});
    	}
		
		
		vm.exportElkFacturation = function(){
			
		    
            var dateFormat = 'yyyy-MM-dd';
            var fromDate = $filter('date')(new Date(vm.datePeriod.startDate), dateFormat);
            var toDate = $filter('date')(new Date(vm.datePeriod.endDate), dateFormat);
			
            
			 var url = 'api/export/facturation?fromDate='+fromDate+'&toDate=' + toDate;
		    $http.post(url).then(function(msg){
				AlertService.success("success, response:"+msg);
		    	alert("response:"+JSON.stringify(msg.data));
		    }); 
		    
//			AdminTools.exportElkFacturation( {
//        		fromDate:fromDate,
//        		toDate:toDate
//    			}, function (data){
//				AlertService.success("success, response:"+data);
//				}, function (error){
//					AlertService.error(error.data.message);
//				});
    	}
		
		
		vm.exportElkWaterConsumption = function(){
			
		    
            var dateFormat = 'yyyy-MM-dd';
            var fromDate = $filter('date')(new Date(vm.datePeriod.startDate), dateFormat);
            var toDate = $filter('date')(new Date(vm.datePeriod.endDate), dateFormat);
			
            
			 var url = 'api/export/consommationeau?fromDate='+fromDate+'&toDate=' + toDate;
		    $http.post(url).then(function(msg){
				AlertService.success("success, response:"+msg);
		    	alert("response:"+JSON.stringify(msg.data));
		    }); 

    	}
		
		
		
		vm.exportElkStatContrat = function(){
			
		    
            var dateFormat = 'yyyy-MM-dd';
            var fromDate = $filter('date')(new Date(vm.datePeriod.startDate), dateFormat);
            var toDate = $filter('date')(new Date(vm.datePeriod.endDate), dateFormat);
			
            
			 var url = 'api/export/stat_contrat?fromDate='+fromDate+'&toDate=' + toDate;
		    $http.post(url).then(function(msg){
				AlertService.success("success, response:"+msg);
		    	alert("response:"+JSON.stringify(msg.data));
		    }); 

    	}
		
		
		
		
		vm.exportElkContrat = function(){
			
		    
            var dateFormat = 'yyyy-MM-dd';
            var fromDate = $filter('date')(new Date(vm.datePeriod.startDate), dateFormat);
            var toDate = $filter('date')(new Date(vm.datePeriod.endDate), dateFormat);
			
            
			 var url = 'api/export/contracts?fromDate='+fromDate+'&toDate=' + toDate;
		    $http.post(url).then(function(msg){
				AlertService.success("success, response:"+msg);
		    	alert("response:"+JSON.stringify(msg.data));
		    }); 

    	}
		
		
		vm.majComblerStatContrat = function(){
		    
            var dateFormat = 'yyyy-MM-dd';
     //       var fromDate = $filter('date')(new Date(vm.datePeriod.startDate), dateFormat);
            var toDate = $filter('date')(new Date('2018-04-20'), dateFormat);
			            
			 var url = 'api/export/comblerstatcontrat?toDate=' + toDate;
		    $http.post(url).then(function(msg){
				AlertService.success("success, response:"+msg);
		    	alert("response:"+JSON.stringify(msg.data));
		    }); 

    	}
		
		vm.exportElkDiscountCreditNoteRefund = function(){
			
		    
            var dateFormat = 'yyyy-MM-dd';
            var fromDate = $filter('date')(new Date(vm.datePeriod.startDate), dateFormat);
            var toDate = $filter('date')(new Date(vm.datePeriod.endDate), dateFormat);
			
            
			 var url = 'api/export/credit_note_refund?fromDate='+fromDate+'&toDate=' + toDate;
		    $http.post(url).then(function(msg){
				AlertService.success("success, response:"+msg);
		    	alert("response:"+JSON.stringify(msg.data));
		    }); 

    	}
	}
})();