(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('RedmineCreateTicketController', RedmineCreateTicketController);

    RedmineCreateTicketController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'Principal', 'RedmineService','UserSearch'];

    function RedmineCreateTicketController ($scope, $stateParams, $uibModalInstance, Principal, RedmineService,UserSearch) {
        var vm = this;
        vm.RedmineService = {bulkMeter: vm.bulkMeter,isCorrection: true,lastModifiedDate:Date.now() };
        vm.users = UserSearch.searchUserArea({ offset: 0,size: 1000});
        vm.currentAgence ='';
        
        Principal.identity().then(function(account) {
        	if(account.agence){
        		vm.currentAgence = account.agence.nom;
        	}
        });
        
        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:redmineUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            
            RedmineService.createIssue(vm.redmineIssue, onSaveSuccess, onSaveError);
          
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateSuppression = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
