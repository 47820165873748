(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ReleveCompteurDialogController', ReleveCompteurDialogController);

    ReleveCompteurDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'ReleveCompteur', 'UserSearch','Cluster','BulkMeter'];

    function ReleveCompteurDialogController ($scope, $stateParams, $uibModalInstance, entity, ReleveCompteur, UserSearch,Cluster,BulkMeter) {
        var vm = this;
        vm.releveCompteur = entity;
        vm.users = UserSearch.searchCollecteur();
        vm.listCluster =[];
        vm.listBulkMeter =[];
        

        vm.updateCollecteur = function() {
            vm.listCluster =[];
            vm.listBulkMeter =[];
            vm.releveCompteur.listeCluster = null;
            vm.releveCompteur.listeBulkMeter=null;
            
        	Cluster.clusterActifByCollecteur(
    				{idCollector : vm.releveCompteur.collecteur.id}, 
    				function(result) {
    						vm.listCluster =  result;
    		});
        	
        	BulkMeter.bulkMeterByCollecteur(
    				{idCollector : vm.releveCompteur.collecteur.id}, 
    				function(result) {
    						vm.listBulkMeter =  result;
    		});
    		
        };
        
        vm.load = function(id) {
            ReleveCompteur.get({id : id}, function(result) {
                vm.releveCompteur = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:releveCompteurUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            
            ReleveCompteur.save(vm.releveCompteur, onSaveSuccess, onSaveError);
            
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateReleve = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
