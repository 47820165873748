(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('TypeRemisecommercialeDetailController', TypeRemisecommercialeDetailController);

    TypeRemisecommercialeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'TypeRemisecommerciale'];

    function TypeRemisecommercialeDetailController($scope, $rootScope, $stateParams, entity, TypeRemisecommerciale) {
        var vm = this;
        vm.typeRemisecommerciale = entity;
        vm.load = function (id) {
            TypeRemisecommerciale.get({id: id}, function(result) {
                vm.typeRemisecommerciale = result;
            });
        };
        var unsubscribe = $rootScope.$on('wamasysApp:typeRemisecommercialeUpdate', function(event, result) {
            vm.typeRemisecommerciale = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
