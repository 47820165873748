(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('PortefeuilleContratDialogController', PortefeuilleContratDialogController);

    PortefeuilleContratDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'PortefeuilleContrat',  'UserSearch','Principal','AlertService'];

    function PortefeuilleContratDialogController ($scope, $stateParams, $uibModalInstance, entity, PortefeuilleContrat,  UserSearch,Principal,AlertService) {
        var vm = this;
        vm.portefeuilleContrat = entity;
        vm.users = UserSearch.searchCollecteur({ offset: 0,size: 1000});
        vm.load = function(id) {
            PortefeuilleContrat.get({id : id}, function(result) {
                vm.portefeuilleContrat = result;
            });
        };

        vm.hasAreaManagementRight = false;
        Principal.hasAuthority('ROLE_AREA_MANAGEMENT').then(function (result) {
            if (result) {
            	vm.hasAreaManagementRight =true;
            }
        });
        
        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:portefeuilleContratUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
        	if(	vm.autoBillingEnabled ==false && (vm.portefeuilleContrat.autoBillingNextDate || vm.portefeuilleContrat.autoInvoiceNextDate) ){

				AlertService.error("Please delete next billing and paper invoice date if you want to disable automation");
        		return;
        	}
            vm.isSaving = true;
            if (vm.portefeuilleContrat.id !== null) {
                PortefeuilleContrat.update(vm.portefeuilleContrat, onSaveSuccess, onSaveError);
            } else {
                PortefeuilleContrat.save(vm.portefeuilleContrat, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

		/*
	    lundi 		1	0b00000001
	    mardi 		2	0b00000010
	    mercredi 	4 	0b00000100
	    jeudi		8	0b00001000
	    vendredi	16	0b00010000
	    samedi		32	0b00100000
	    dimanche	64	0b01000000
	*/
	    var lundi = 1;	
	    var mardi = 2;	
	    var mercredi = 4;	
	    var  jeudi	=	8	;
	    var  vendredi=	16	;
	    var samedi	=	32	;
	    var dimanche=	64	;
//	    
//	    vm.listDay = [];
//	    vm.listDay.push({id: 1 , label: 'monday' });
//	    vm.listDay.push({id: 2 , label: 'tuesday' });
//	    vm.listDay.push({id: mercredi , label: 'wednesday' });
//	    vm.listDay.push({id: jeudi , label: 'thursday' });
//	    vm.listDay.push({id: vendredi , label: 'friday' });
//	    vm.listDay.push({id: samedi , label: 'saturday' });
//	    vm.listDay.push({id: dimanche , label: 'sunday' });
//	    
	    
	    if( vm.portefeuilleContrat &&  vm.portefeuilleContrat.$promise){
	    	
		    vm.portefeuilleContrat.$promise.then(function(value) {
		    	
		    	if(vm.portefeuilleContrat.autoBillingNextDate || vm.portefeuilleContrat.autoInvoiceNextDate){
		    		vm.autoBillingEnabled = true;
		    	}
		    
		
		        if(vm.portefeuilleContrat.autoBillingPeriodType == 'WEEKDAY'){
		            var billingValeurInitiale = vm.portefeuilleContrat.autoBillingInterval ;
		    	    
		    	    //On intialise le billing
		    		var billingLeLundi = (billingValeurInitiale | lundi) == billingValeurInitiale;
		    		var billingLeMardi= (billingValeurInitiale | mardi) == billingValeurInitiale;
		    		var billingLeMercredi= (billingValeurInitiale | mercredi) == billingValeurInitiale;
		    		var billingLeJeudi= (billingValeurInitiale | jeudi) == billingValeurInitiale;
		    		var billingLevendredi= (billingValeurInitiale | vendredi) == billingValeurInitiale;
		    		var billingLesamedi= (billingValeurInitiale | samedi) == billingValeurInitiale;
		    		var billingLedimanche= (billingValeurInitiale | dimanche) == billingValeurInitiale;
		    		
		    		vm.billingWeekday = [];
		    		if(billingLeLundi){
		    			vm.billingWeekday.push(""+lundi);
		    		}
		    		if(billingLeMardi){
		    			vm.billingWeekday.push(""+mardi);
		    		}
		    		if(billingLeMercredi){
		    			vm.billingWeekday.push(""+mercredi);
		    		}
		    		if(billingLeJeudi){
		    			vm.billingWeekday.push(""+jeudi);
		    		}
		    		if(billingLevendredi){
		    			vm.billingWeekday.push(""+vendredi);
		    		}
		    		if(billingLesamedi){
		    			vm.billingWeekday.push(""+samedi);
		    		}
		    		if(billingLedimanche){
		    			vm.billingWeekday.push(""+dimanche);
		    		}
		        }
	
		        if(vm.portefeuilleContrat.autoInvoicePeriodType == 'WEEKDAY'){
		            var invoiceValeurInitiale = vm.portefeuilleContrat.autoInvoiceInterval ;
		    	    
		    	    //On intialise le invoice
		    		var invoiceLeLundi = (invoiceValeurInitiale | lundi) == invoiceValeurInitiale;
		    		var invoiceLeMardi= (invoiceValeurInitiale | mardi) == invoiceValeurInitiale;
		    		var invoiceLeMercredi= (invoiceValeurInitiale | mercredi) == invoiceValeurInitiale;
		    		var invoiceLeJeudi= (invoiceValeurInitiale | jeudi) == invoiceValeurInitiale;
		    		var invoiceLevendredi= (invoiceValeurInitiale | vendredi) == invoiceValeurInitiale;
		    		var invoiceLesamedi= (invoiceValeurInitiale | samedi) == invoiceValeurInitiale;
		    		var invoiceLedimanche= (invoiceValeurInitiale | dimanche) == invoiceValeurInitiale;
		    		
		    		vm.invoiceWeekday = [];
		    		if(invoiceLeLundi){
		    			vm.invoiceWeekday.push(""+lundi);
		    		}
		    		if(invoiceLeMardi){
		    			vm.invoiceWeekday.push(""+mardi);
		    		}
		    		if(invoiceLeMercredi){
		    			vm.invoiceWeekday.push(""+mercredi);
		    		}
		    		if(invoiceLeJeudi){
		    			vm.invoiceWeekday.push(""+jeudi);
		    		}
		    		if(invoiceLevendredi){
		    			vm.invoiceWeekday.push(""+vendredi);
		    		}
		    		if(invoiceLesamedi){
		    			vm.invoiceWeekday.push(""+samedi);
		    		}
		    		if(invoiceLedimanche){
		    			vm.invoiceWeekday.push(""+dimanche);
		    		}
		        }
	        });
	    }
		
	    vm.onUpdateInvoiceWeekday= function() {
			var result = 0;
			for ( var i = 0; i < vm.invoiceWeekday.length; i++) {
				result = result + parseInt( vm.invoiceWeekday[i]);
			}

			vm.portefeuilleContrat.autoInvoiceInterval = result;
        };
	    
		vm.onUpdateBillingWeekday= function() {
			var result = 0;
			for ( var i = 0; i < vm.billingWeekday.length; i++) {
				result = result + parseInt( vm.billingWeekday[i]);
			}
			vm.portefeuilleContrat.autoBillingInterval = result;
        };
		
		
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateCloture = false;
        vm.datePickerOpenStatus.dateActivation = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
