(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('ServiceVenduDetailController', ServiceVenduDetailController);

    ServiceVenduDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'ServiceVendu','Agence', 'TypeService', 'ModeleContrat','DTOptionsBuilder', 'DTColumnDefBuilder'];

    function ServiceVenduDetailController($scope, $rootScope, $stateParams, entity, ServiceVendu, Agence, TypeService, ModeleContrat,DTOptionsBuilder, DTColumnDefBuilder) {
        var vm = this;
        vm.serviceVendu = entity;
        
        vm.load = function (id) {
            ServiceVendu.get({id: id}, function(result) {
                vm.serviceVendu = result;
            });
        };
        var unsubscribe = $rootScope.$on('wamasysApp:serviceVenduUpdate', function(event, result) {
            vm.serviceVendu = result;
        });
        $scope.$on('$destroy', unsubscribe);

        
        vm.standardOptions = DTOptionsBuilder
        .newOptions()
         //Add Bootstrap compatibility
        .withDOM("<'dt-toolbar'<'col-xs-12 col-sm-6'f><'col-sm-6 col-xs-12 hidden-xs'l>r>" +
            "t" +
            "<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>")
        .withBootstrap();
        
        vm.standardColumns = [
	        DTColumnDefBuilder.newColumnDef(0),
	        DTColumnDefBuilder.newColumnDef(1),
	        DTColumnDefBuilder.newColumnDef(2)
	    ];
    
    }
})();
