(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
		.state('modele-contrat.service-autre',{
			url : '/service-autre/',
			views : {
				'panneau@content' : {
					templateUrl : 'app/business/service-autre/service-autres.html',

					controller : 'ServiceVenduController',
					controllerAs : 'vm'
					}
				}
			})
        .state('service-autre-detail', {
            parent: 'entity',
            url: '/service-autre/{id}',
            data: {
                authenticate: true,
                pageTitle: 'wamasysApp.serviceVendu.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/business/service-autre/service-autre-detail.html',
                    controller: 'ServiceVenduDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('serviceVendu');
                    $translatePartialLoader.addPart('typePaiementService');
                    
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'ServiceVendu', function($stateParams, ServiceVendu) {
                    return ServiceVendu.get({id : $stateParams.id});
                }]
            }
        })
        .state('modele-contrat.service-autre.new', {
            url: '/new',
            data: {
                authorities: ['ROLE_AREA_CONFIGURATION']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/service-autre/service-autre-dialog.html',
                    controller: 'ServiceVenduDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                libelle: null,
                                unitPrice: null,
                                totalCredit: null,
                                amountPerInstallment: null,
                                tauxTaxe:null,
                                libelleTaxe:null,
                                deleted: false,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('modele-contrat.service-autre', null, { reload: true });
                }, function() {
                    $state.go('modele-contrat.service-autre');
                });
            }]
        })
        .state('modele-contrat.service-autre.edit', {
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_AREA_CONFIGURATION']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/service-autre/service-autre-dialog.html',
                    controller: 'ServiceVenduDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ServiceVendu', function(ServiceVendu) {
                            return ServiceVendu.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('modele-contrat.service-autre', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('modele-contrat.service-autre.delete', {
           
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_AREA_CONFIGURATION']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/business/service-autre/service-autre-delete-dialog.html',
                    controller: 'ServiceVenduDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ServiceVendu', function(ServiceVendu) {
                            return ServiceVendu.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('modele-contrat.service-autre', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
