(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('ReportingConsommationEau', ReportingConsommationEau);

    ReportingConsommationEau.$inject = ['$resource'];

    function ReportingConsommationEau ($resource) {
        var resourceUrl =  'api/reporting-agence-conso-eau/search';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false}
        });
        
    }
})();