(function () {
    'use strict';

    angular
        .module('wamasysApp')
        .factory('Collecteur', Collecteur);

    Collecteur.$inject = ['$resource'];

    function Collecteur ($resource) {
        var service = $resource('api/collecteurs/:login', {}, {
        	'query': { method: 'GET', isArray: true, url:'api/users/searchCollecteur'},
            'save': { method:'POST' },
            'update': { method:'PUT' },
            'delete':{ method:'DELETE'}
        });

        return service;
    }
})();
