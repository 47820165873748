(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('PaymentReceivedDeleteController',PaymentReceivedDeleteController);

    PaymentReceivedDeleteController.$inject = ['$uibModalInstance','entity', 'Billing'];

    function PaymentReceivedDeleteController($uibModalInstance, entity, Billing) {
        var vm = this;
        vm.paymentReceived = entity;

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
        	Billing.deletePayment({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
