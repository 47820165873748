(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('Factures', Factures);

    Factures.$inject = ['$resource', 'DateUtils'];

    function Factures ($resource, DateUtils) {
        var resourceUrl =  'api/factures/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            
            'queryReceipts': { method: 'GET', url :'/api/receipts/:id' , isArray: true},

            'getReceipts': { method: 'GET', url :'/api/receipts/download/:id' },
            
            
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                	var clonedData = Object.assign({}, data);
                	clonedData.dateCloture = DateUtils.convertLocalDateToServer(data.dateCloture);
                	clonedData.dateActivation = DateUtils.convertLocalDateToServer(data.dateActivation);
                    return angular.toJson(clonedData);
                }
            },
            'download' :{
            	 method:'GET', 
            	 //isArray: true,
            	 responseType: 'arraybuffer',
                headers: {
                	'Content-Type': undefined
                },
                cache: false,
                transformResponse: function (data) {
                    return {
                      response: new Blob([data], {type: 'application/pdf'})
                    };
                },
            	 url :'api/factures/download/:idAgence/:idContrat/:idFacture'
            },
            'downloadzip' :{
           	 method:'GET', 
           	 //isArray: true,
           	 responseType: 'arraybuffer',
               headers: {
               	'Content-Type': undefined
               },
               cache: false,
               transformResponse: function (data) {
                   return {
                     response: new Blob([data], {type: 'application/zip'})
                   };
               },
           	 url :'api/factures/downloadzip'
           },
            'generer': {
                method: 'POST',
                headers: {
                    accept: 'application/pdf'
                },
                responseType: 'arraybuffer',
              	url :'/api/factures/generer',
                transformResponse: function (data,headers) {

                	var filename = headers('FileName');
                	var contentType = headers('ContentType');
                    var blob;
                    if (data) {
                    	blob = new Blob([data], {
                            type: contentType
                        });
                    }
                    return {
                        response: blob,
                        fileName : filename,
                        contentType : contentType
                    };
                }
            },
            'generatePaymentRecoveryNotice': {
                method: 'POST',
                headers: {
                    accept: 'application/pdf'
                },
                responseType: 'arraybuffer',
              	url :'/api/factures/generatePaymentRecoveryNotice',
                transformResponse: function (data,headers) {

                	var filename = headers('FileName');
                	var contentType = headers('ContentType');
                    var blob;
                    if (data) {
                    	blob = new Blob([data], {
                            type: contentType
                        });
                    }
                    return {
                        response: blob,
                        fileName : filename,
                        contentType : contentType
                    };
                }
            }
            
        });
    }
})();
