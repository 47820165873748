(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('IndividualMeter', IndividualMeter);

    IndividualMeter.$inject = ['$resource', 'DateUtils'];

    function IndividualMeter ($resource, DateUtils) {
        var resourceUrl =  'api/individual-meters/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.dateMiseEnService = DateUtils.convertLocalDateFromServer(data.dateMiseEnService);
                    data.datePrecedenteLecture= DateUtils.convertDateTimeFromServer(data.datePrecedenteLecture);
                    data.dateSuppression = DateUtils.convertDateTimeFromServer(data.dateSuppression);
                    return data;
                }
            },
            'update': { 
            	method:'PUT',
                transformRequest: function (data) {
                	var clonedData = Object.assign({}, data);
                	clonedData.dateMiseEnService = DateUtils.convertLocalDateToServer(data.dateMiseEnService);
                	clonedData.datePrecedenteLecture = DateUtils.convertDateTimeFromServer(data.datePrecedenteLecture);
                    return angular.toJson(clonedData);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                	var clonedData = Object.assign({}, data);
                	clonedData.dateMiseEnService = DateUtils.convertLocalDateToServer(data.dateMiseEnService);
                	clonedData.datePrecedenteLecture = DateUtils.convertDateTimeFromServer(data.datePrecedenteLecture);
                    return angular.toJson(clonedData);
                }
            },

            'queryActif': { method: 'GET', url:'api/individual-meters-actif', isArray: true},
            
            
            'queryNonSupprimeNonUtilise': { method: 'GET',url:'api/individual-meters-libre', isArray: true},
            
            'downloadcsv': {
                method: 'GET',
                headers: {
                    accept: 'text/csv'
                },
                responseType: 'arraybuffer',
              	url :'/api/individual-meters/downloadcsv',
                transformResponse: function (data,headers) {
                	  var filename = headers('FileName');
                	  var contentType = headers('ContentType');
                    var csv;
                    if (data) {
                        csv = new Blob([data], {
                            type: contentType
                        });
                    }
                    return {
                        response: csv,
                        fileName : filename,
                        contentType : contentType
                    };
                }
            }
            
        });
    }
})();
