(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('EventFormDialogController', EventFormDialogController);

    EventFormDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'AlertService','EventForm','EventFormCategory'];

    function EventFormDialogController ($scope, $stateParams, $uibModalInstance, entity, AlertService,EventForm,EventFormCategory) {
        var vm = this;
        vm.eventForm = entity;
        vm.eventForm.lat = null;
        vm.eventForm.lon = null;
        vm.listSubCategory = [];
        
        if(vm.eventForm && vm.eventForm.$promise){
        	vm.eventForm.$promise.then(function (result) {
            	var category = {id: result.categoryId, label:result.category} ;
            	var subCategory = {id: result.subCategoryId, label:result.subCategory} ;
                vm.eventForm.category = category ;
                vm.eventForm.subCategory = subCategory;
                
                vm.updateSubCategory();
                
            });
        }
        
        
        

        
        EventFormCategory.query({
            page: 0,
            size: 100
        }, onSuccess, onError);
        
        function onSuccess(data, headers) {
        	vm.listCategory = data;
        	if(vm.eventForm.category){
        		var  fnctIdEqual = function(element) { return element.id === vm.eventForm.category.id};
        		var index = vm.listCategory.findIndex(fnctIdEqual);
        		if(index === -1 ){
        			vm.listCategory.push(vm.eventForm.category);
        		}
        		
        	}
        }
        
        function onError(error) {
            AlertService.error(error.data.message);
        }
        vm.updateSubCategory = function (){
        	vm.listSubCategory = [];
        	vm.inputsCustomFields = [];
            EventFormCategory.getListSubCategory({id: vm.eventForm.category.id}, function(result) {
            	vm.listSubCategory =   result;
            	if(vm.eventForm.subCategory){
	        		var  fnctIdEqual = function(element) {return element.id === vm.eventForm.subCategory.id};
            		var index = vm.listSubCategory.findIndex(fnctIdEqual);
            		if(index === -1 ){
            			vm.listSubCategory.push(vm.eventForm.subCategory);
            		}
            		
            	}
            	
            	if(vm.listSubCategory && vm.eventForm.subCategory && vm.eventForm.subCategory.id){
            		vm.listSubCategory.forEach(function (item){
            			if(item.id == vm.eventForm.subCategory.id){
            				vm.eventForm.subCategory.customFields= item.customFields;
                            vm.onchangeSubCategory();
            			}
            		});
            	}
           });
        }
        
        vm.inputsCustomFields = [];
        vm.onchangeSubCategory = function(){
            
    		if(! vm.eventForm.subCategory.customFields){
    			vm.inputsCustomFields = [];
    			return;
    		}
    		
        	try {
        
    	        var customfields = JSON.parse(vm.eventForm.subCategory.customFields);

    	        vm.inputsCustomFields = [];
    	        customfields.fields.forEach(function(value) {
    	        	var listValuesSelect = null ;
    	        	if(value.values!==undefined){
    	        		listValuesSelect = value.values.split(";");
    	        	}
    	        	var name = value.code.replace('.', '_');
    	        	vm.inputsCustomFields.push({'required':value.required,'label':value.label,'name':name,'code':value.code,'type':value.type,'tooltip':value.tooltip,'values':listValuesSelect });
    	        	
    	        });
    	      

    	    } catch (e) {
    	    	console.log(e);
                return;
    	    }	

    	    if(vm.eventForm.metadata){
    	    	  var metadata = JSON.parse(vm.eventForm.metadata);
    		        for(var param in metadata){
    		        	 for(var i = 0; i < vm.inputsCustomFields.length; i++){
    		        		 if (param == vm.inputsCustomFields[i].code){
    		        			 vm.inputsCustomFields[i].value =  metadata[param];
    		        		 }
    		        	 }
    		        }
    	    }
	      
    	    
        	    
        }
        
        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:eventFormUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {

        	var customFieldValues = {};
        	if(vm.inputsCustomFields.length>0){
//            	var customFieldValues = "[";
                for (var i in vm.inputsCustomFields) {
//                	customFieldValues.push({'code':vm.inputsCustomFields[i].code,'value':vm.inputsCustomFields[i].value});
                	
					 customFieldValues[vm.inputsCustomFields[i].code] =vm.inputsCustomFields[i].value ;
					 
//					 if(i>0){
//					 customFieldValues =  customFieldValues + ',';            		 
//            	 }
//                	 customFieldValues =  customFieldValues + '{"'+vm.inputsCustomFields[i].code +'":'+ vm.inputsCustomFields[i].value+'}';
                	
                }
//			 	customFieldValues =  customFieldValues + ']'; 
                
//                vm.eventForm.metadata = {"test":"rest"};
        	}
            vm.eventForm.metadata = customFieldValues;

            vm.eventForm.categoryId = vm.eventForm.category.id;
            vm.eventForm.subCategoryId = vm.eventForm.subCategory.id;
            vm.eventForm.category = vm.eventForm.category.label;
            vm.eventForm.subCategory = vm.eventForm.subCategory.label;
            
            vm.isSaving = true;
            if (vm.eventForm.id !== null) {
                EventForm.update(vm.eventForm, onSaveSuccess, onSaveError);
            } else {
                EventForm.save(vm.eventForm, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateRecord = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
